/**
 * Component drawer for Configuring widget panel.
 */

import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Drawer,
    Form,
    Input,
    Modal,
    Row,
    Select,
    TimePicker,
} from 'antd';

import { clone, get, isEmpty, map, set } from 'lodash';
import moment from 'moment-timezone/moment-timezone';
import React, {useLayoutEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    scheduleMonthlyTimingOptions,
    scheduleTimingOptions,
    scheduleTypeOptions,
} from '../../constants/dashboards';
import {
    getTranslatedText,
    populatePopoverContainer,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import SelectReadonly from '../FormComponents/SelectReadonly';
import { dateFormatDDMMMYYYYSpace, dateFormatYYYYMMDDDash } from '../../constants/dateFormats';
import { ScheduledReportDateFilter } from '../../constants/reportSortAndFilters';
import { createScheduledReportRequest } from '../../store/scheduledReports/actions';
import ModalWithSpinner from '../common/ModalWithSpinner';

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    visible: boolean;
    onClose: (resetChanges?: boolean) => void;
    widgetDetails: DynamicObject;
    widgetLayout?: DynamicObject;
    readonly form?: any;
    onWidgetConfigureUpdate?: Function;
    widgetQuery: string[];
    isOrgView?: boolean;
    readonly organisationCurrenciesAll?: [];
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        md: { span: 9 },
    },
    wrapperCol: {
        xs: { span: 24 },
        md: { span: 15 },
    },
};

const DashboardScheduleReportPanel: React.FC<IProps> = ({
    visible,
    onClose,
    widgetDetails,
    widgetLayout,
    form,
    widgetQuery,
    isOrgView,
    organisationCurrenciesAll,
}: IProps) => {
    const drawerRef: any = useRef(null);
    const [hideModalBody, setHideModalBody] = useState<boolean>(false);
    const dispatch = useDispatch();
    const {
        getFieldDecorator,
        validateFields,
        resetFields,
        getFieldValue,
    } = form;

    const [scheduleType, setScheduleType] = useState<number>(0);
    const [applyScheduleDateRange, setApplyScheduleDateRange] = useState<boolean>(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    /**
     * Function called when the `Save` button is clicked.
     */
    const onSaveButtonClick = () => {
        validateFields((err: any, values: any) => {
            if (!err) {
            setSubmitLoading(true);
            const ScheduleStartDate = get(values, 'ReportDateStart');
            const ScheduleStartTime = get(values, 'ReportTimeStart')
                if (ScheduleStartDate) {
                    set(values, 'ReportDateStart', moment(ScheduleStartDate).format(
                        dateFormatYYYYMMDDDash
                    ).concat(" ").concat(moment(ScheduleStartTime).minutes(5).format("hh:mm A")));
                }
            const getIsApplySchedule = get(values ,'applyScheduleRange') ? get(values ,'applyScheduleRange') : false;

            const payload = {
                ...widgetQuery,
                ScheduleType: get(values ,'scheduleType'),
                ScheduleTiming1: get(values ,'scheduleTiming1'),
                ScheduleTiming2: get(values ,'scheduleTiming2'), 
                ApplyScheduleDate: getIsApplySchedule, 
                DateFilterField: get(values ,'DateFilter'), 
                ScheduleStartDate: (new Date(get(values ,'ReportDateStart'))).toISOString(),
                PageName: get(widgetQuery, 'WidgetName'),
                Type: get(widgetQuery, 'Type') 
            }
            
            dispatch(
                createScheduledReportRequest({
                    ...payload,
                    callback: actionResponseModal
                })
            );
            }
        }); 
    };

    /**
     * Function for populating the response modal when completing action.
     * Either success/error.
     */
    const actionResponseModal = ({
        IsSuccess,
        Messages,
    }: {
        IsSuccess: boolean;
        Messages: string[] | undefined;
    }) => {
        setSubmitLoading(false);
        if (IsSuccess) {
            Modal.success({
                title: 'Success',
                content: getTranslatedText('Scheduled report created successfully!'),
                zIndex:2500
            });
            resetFields();
            onClose(true);
            setApplyScheduleDateRange(false);
        } else {
            let errorMessageContent: any = getTranslatedText(`Failed to create scheduled report!`);
            if (!isEmpty(Messages)) {
                errorMessageContent = map(
                    Messages,
                    (error: string, index: number) => (
                        <div key={index}>{error}</div>
                    )
                );
            }
            Modal.error({
                title: 'Error',
                content: errorMessageContent,
                zIndex: 2500
            });
        }
    };

    /**
     * Function called when the `Close` button is clicked.
     */
    const onCancelButtonClick = () => {
        resetFields();
        onClose(true);
        setApplyScheduleDateRange(false);
    };

    /**
     * Function called for focusnig on the active configured widget.
     */
    const listenForConfiguredWidget = () => {
        if (visible) {
            const configuredElement = document.getElementById(
                get(widgetDetails, 'i')
            );
            const usedWidgetDetails = clone(widgetDetails);
            if (configuredElement) {
                const scrollableDashboardContainer = document.getElementById(
                    'dashboard-container-scrolling'
                );
                if (scrollableDashboardContainer) {
                    const activeWidget = document.getElementById(
                        usedWidgetDetails.i
                    );
                    let xPosition = 0;
                    let yPosition = 0;
                    if (activeWidget) {
                        const transformValue = activeWidget.style.transform;
                        if (transformValue) {
                            const splitTransform = transformValue.split(', ');

                            if (splitTransform) {
                                const transformX = get(splitTransform, 0, '')
                                    .replace('translate(', '')
                                    .replace('px', '');

                                xPosition = Number(transformX);

                                const transformY = get(splitTransform, 1, '')
                                    .replace(')', '')
                                    .replace('px', '');

                                yPosition = Number(transformY);
                            }
                        }
                    }
                    scrollableDashboardContainer.scrollTo(xPosition, yPosition);
                }
            }
            const applySchedule = getFieldValue('applyScheduleRange'); 
            setApplyScheduleDateRange(applySchedule);
        } 
    };

    const toggleModalBodyVisibility = () => {
        setHideModalBody((prevState) => !prevState);
    };

    const disablePastDates = (current:any) => {
        return current && current < moment().startOf('day');
      };

    const filterOptions = ScheduledReportDateFilter(get(widgetDetails, 'type'));

    const dateFilterRequired = filterOptions.length === 0 ? false : filterOptions[0].required;

    useLayoutEffect(listenForConfiguredWidget, [visible, widgetLayout]);

    const handleOpenChange = (open: any) => {
        if (open) {
            setTimeout(() => {
                const dropdown = document.querySelector('.ant-time-picker-panel-select');
                if (dropdown) {
                    dropdown.scrollTop = 0;
                }
            }, 0);
        }
    };

    return (
        <Drawer
            className={`schedule-report-widget-drawer ${
                hideModalBody && 'modal-body-hidden'
            }`}
            visible={visible}
            width={500}
            zIndex={1300}
            title={
                <div className="center-flex-sb">
                    <div>{getTranslatedText("Create Schedule Report")}</div>
                    <div>
                        <Button onClick={toggleModalBodyVisibility} type="link">
                            {hideModalBody
                                ? 'Show modal body'
                                : 'Hide modal body'}
                        </Button>
                    </div>
                </div>
            }
            maskClosable={false}
            closable={false}
        >
            {visible && (
                <>
                    <div
                        className="widget-drawer-container"
                        ref={drawerRef}
                        style={{
                            height: window.innerHeight - 165,
                        }}
                    >
                        <Form
                            labelAlign='left'
                            className="form-inline-mb-0"
                            {...formItemLayout}
                        >
                            <Row gutter={[16, 24]}>
                                <Col span={24}>
                                    {getTranslatedText("The report will be genreated on the schedule you choose, based on the current filters applied on this search. Please check your filters are correct before creating the schedule.")}
                                </Col>
                                <Col span={24}>
                                    <FormItem label={getTranslatedText("Type")}>
                                        {getFieldDecorator('type', {
                                            initialValue:getTranslatedText(get(
                                                widgetDetails,
                                                'title'
                                            )).concat(" widget"),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Type required!',
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="Type"
                                                disabled={true}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col span={24}>
                                    <FormItem label={getTranslatedText("Schedule Type")}>
                                            {getFieldDecorator('scheduleType', {
                                                initialValue: scheduleTypeOptions[0].value,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Schedule Type required!',
                                                    },
                                                ],
                                            })(
                                                <SelectReadonly
                                                style={{ width: '100%' }}
                                                getPopupContainer={populatePopoverContainer(
                                                    drawerRef
                                                )}
                                                onChange={(value:any)=> {
                                                    setScheduleType(value);
                                                }}
                                            >
                                                {map(
                                                    scheduleTypeOptions,
                                                    ({ label, value }: { label: string; value: string }) => (
                                                        <Option key={value} value={value}>
                                                             {getTranslatedText(label)}
                                                        </Option>
                                                    )
                                                )}
                                            </SelectReadonly>
                                            )}
                                    </FormItem>
                                </Col>
                                <Col span={14}>
                                    <FormItem label={getTranslatedText("Timing")}>
                                            {getFieldDecorator('scheduleTiming1', {
                                                initialValue: scheduleTimingOptions(getFieldValue('scheduleType'))[0].value,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Timing required!',
                                                    },
                                                ],
                                            })(
                                                <SelectReadonly
                                                style={{ width: '100%' }}
                                                getPopupContainer={populatePopoverContainer(
                                                    drawerRef
                                                )}
                                            >
                                                {map(
                                                    scheduleTimingOptions(getFieldValue('scheduleType')),
                                                    ({ label, value }: { label: string; value: string }) => (
                                                        <Option key={value} value={value}>
                                                            {label}
                                                        </Option>
                                                    )
                                                )}
                                            </SelectReadonly>
                                            )}
                                    </FormItem>
                                </Col>
                                <Col span={10}>
                                    {scheduleType === 3 &&
                                        <FormItem label="On the">
                                            {getFieldDecorator('scheduleTiming2', {
                                                initialValue: scheduleMonthlyTimingOptions()[0].value,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Timing required!',
                                                    },
                                                ],
                                            })(
                                                <SelectReadonly
                                                style={{ width: '100%' }}
                                                getPopupContainer={populatePopoverContainer(
                                                    drawerRef
                                                )}
                                            >
                                                {map(
                                                    scheduleMonthlyTimingOptions(),
                                                    ({ label, value }: { label: string; value: string }) => (
                                                        <Option key={value} value={value}>
                                                            {label}
                                                        </Option>
                                                    )
                                                )}
                                            </SelectReadonly>
                                            )}
                                    </FormItem>
                                    }
                                </Col>
                                <Col span={18}>
                                    <FormItem wrapperCol={{ span: 24 }}>
                                        {getFieldDecorator("applyScheduleRange", {
                                            initialValue: dateFilterRequired,
                                            valuePropName: 'checked',
                                            rules: [{
                                                validator: async (_rule: any, value: any) => {
                                                    if (dateFilterRequired && !value) {
                                                        throw new Error('This is required for this report.');
                                                    }
                                                }
                                            }],
                                            onChange: (event: any) => {
                                                const checked = get(event, 'target.checked');
                                                    setApplyScheduleDateRange(checked);
                                            },
                                          
                                        })(
                                            <Checkbox
                                            disabled={dateFilterRequired || filterOptions.length === 0}
                                            >{getTranslatedText("Apply schedule range as a date filter")}</Checkbox>
                                        )}
                                    </FormItem>
                                    {applyScheduleDateRange &&
                                        <FormItem label={getTranslatedText("Date Filter")} >
                                            {getFieldDecorator('DateFilter', {
                                                initialValue: filterOptions.length > 0 ? filterOptions[0].value : '',
                                            })(
                                                <Select
                                                style={{ width: '100%' }}
                                                getPopupContainer={populatePopoverContainer(
                                                    drawerRef
                                                )}
                                                disabled={filterOptions.length === 0 || dateFilterRequired}
                                            >
                                                {filterOptions.map(({ label, value }) => (
                                                    <Option key={value} value={value}>
                                                        {label}
                                                    </Option>
                                                ))}
                                            </Select>
                                            )}
                                    </FormItem>
                                    }
                                </Col>
                                <Col span={14}>
                                    <FormItem label={getTranslatedText("Report Start")} >
                                            {getFieldDecorator('ReportDateStart', {
                                                initialValue: moment(),
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Date required!',
                                                    },
                                                ],
                                            })(
                                                <DatePicker
                                                className="dashboard-datepicker"
                                                format={dateFormatDDMMMYYYYSpace}
                                                placeholder="Date"
                                                style={{ width: '100%' }}
                                                disabledDate={disablePastDates}
                                                getCalendarContainer={populatePopoverContainer(
                                                    drawerRef
                                                )}
                                            />
                                            )}
                                    </FormItem>
                                </Col>
                                <Col span={10}>
                                    <FormItem>
                                            {getFieldDecorator('ReportTimeStart', {
                                                initialValue: moment(),
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Time required!',
                                                    },
                                                ],
                                            })(
                                                <TimePicker
                                                className="dashboard-datepicker"
                                                    use12Hours 
                                                    format="hh a"
                                                    placeholder='Time'
                                                    style={{ width: '100%' }}
                                                    getPopupContainer={populatePopoverContainer(
                                                        drawerRef
                                                    )}
                                                    onOpenChange={handleOpenChange}
                                                />
                                            )}
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className="plr-24 pt-10">
                        <Row>
                            <Col className="ta-right">
                                <Button
                                    className="mr-10 w-100px"
                                    type="primary"
                                    onClick={onSaveButtonClick}
                                >
                                    Create
                                </Button>
                                <Button
                                    className="buttonGrey w-100px"
                                    onClick={onCancelButtonClick}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        {submitLoading && (
                            <ModalWithSpinner
                                modalTitle={getTranslatedText("Creating scheduled report")}
                                modalVisible={submitLoading}
                                displayMessage={getTranslatedText("Please wait while creating scheduled report . . .")}
                                zIndex={2500}
                            />
                        )}
                    </div>
                </>
            )}
        </Drawer>
    );
};

const DashboardScheduleReportPanelForm = Form.create()(DashboardScheduleReportPanel);

export default withRouter(DashboardScheduleReportPanelForm);
