import API, { graphqlOperation } from '@aws-amplify/api';
import {
    find,
    filter as lFilter,
    get,
    includes,
    isEmpty,
    isUndefined,
    map,
    orderBy,
} from 'lodash';
import moment from 'moment-timezone';
import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    appliedFilterIndicator,
    customFieldIndicator,
} from '../../components/common/FilterBar';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import {
    DETAILS_TAB,
    TASKS_PAGE,
    TASK_HISTORY_PAGE} from '../../config/tableAndPageConstants';
import {
    FamilyNameAttribute,
    GivenNameAttribute,
} from '../../constants/authUserAttributes';
import { compactViewPages } from '../../constants/common';
import { fetchPaymentPlanScheduleHandler } from '../../constants/paymentPlansSortAndFilters';
import { taskHistoryBatchTypes } from '../../constants/tasksSortAndFilters';
import queries from '../../graphql/queries.graphql';
import {
    checkShouldRequestRefetch,
    customPostData,
    getRegionallyGraphqlResponse,
    formatDateToDateObjectUTC,
    getGraphqlQueryString,
    getSortFieldsWithCustomFields,
    removeAppliedFiltersForApiRequest,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { PageData } from '../common/types';
import { getUsingCustomerWorflow } from '../companies/sagas';
import { getUserPreferencesRequestAction } from '../users/actions';
import { getCurrentUser } from '../users/sagas';
import { UserPreference } from '../users/types';
import {
    checkIfEmailIsValid,
    getIsCompactViewForPage,
} from './../../utils/commonFunctions';
import {
    bulkSaveTaskDetailsUpdateResponseAction,
    getTableCompactViewRequestAction,
    getTableCompactViewSuccessAction,
    getTaskDataErrorAction,
    getTaskDataSuccessAction,
    getTaskHistoryChangedTasksDataErrorAction,
    getTaskHistoryChangedTasksDataSuccessAction,
    getTaskHistoryChangedTasksErrorAction,
    getTaskHistoryChangedTasksSuccessAction,
    getTaskHistoryCollectionsDataErrorAction,
    getTaskHistoryCollectionsDataSuccessAction,
    getTaskHistoryCollectionsTaskDataErrorAction,
    getTaskHistoryCollectionsTaskDataSuccessAction,
    getTaskHistoryCollectionTasksErrorAction,
    getTaskHistoryCollectionTasksSuccessAction,
    getTaskHistoryDataErrorAction,
    getTaskHistoryDataSuccessAction,
    getTaskHistoryErrorAction,
    getTaskHistoryNotificationDataErrorAction,
    getTaskHistoryNotificationDataRequestAction,
    getTaskHistoryNotificationDataSuccessAction,
    getTaskHistoryNotificationInvoicesErrorAction,
    getTaskHistoryNotificationInvoicesSuccessAction,
    getTaskHistoryNotificationsErrorAction,
    getTaskHistoryNotificationsSuccessAction,
    getTaskHistoryNotificationTasksErrorAction,
    getTaskHistoryNotificationTasksSuccessAction,
    getTaskHistoryRequestAction,
    getTaskHistorySuccessAction,
    getTaskHistoryTasksFailedErrorAction,
    getTaskHistoryTasksFailedSuccessAction,
    getTaskPaymentPlanScheduleErrorAction,
    getTaskPaymentPlanScheduleSuccessAction,
    getTasksActionFilterOptionsRequestAction,
    getTasksActionFilterOptionsResponseAction,
    getTasksErrorAction,
    getTasksRequestAction,
    getTasksSuccessAction,
    getTaskTicketInvoicesSuccessAction,
    notifyCustomersResponseAction,
    processTaskCompactViewReduxSettingAction,
    setSelectedTaskHistoryCollectionsTaskIdSuccessAction,
    setSelectedTaskHistoryIdSuccessAction,
    setSelectedTaskHistoryNotificationIdSuccessAction,
    setSelectedTaskIdSuccessAction,
    setTableCompactViewSuccessAction,
    setTaskExpandStatusAction,
    getTaskHistoryDeliveryReportErrorAction,
    getTaskHistoryDeliveryReportSuccessAction,
    getTaskViewerDetailForUserSuccessAction,
    getTaskViewerDetailForUserRequestAction,
    getTaskViewerDetailForUserErrorAction,
    getTaskViewerDetailForUserOrganisationRequestAction,
    getTasksForOrganisationRequestAction,
    getTaskWorkflowsSuccessAction,
    getTaskWorkflowsRequestAction,
    getTaskWorkflowsErrorAction,
    tasksFetchedForOrganisationAction,
    getOrganisationTaskHistoryNotificationDataSuccessAction,
    getOrganisationTaskHistoryNotificationDataErrorAction,
} from './actions';
import { initialState } from './reducer';
import { SetSelectedTaskHistoryPayload, Task, TasksActionTypes, TasksFetchedData } from './types';
import { Auth } from 'aws-amplify';
import { store } from '../..';
import { getOrganisationActionUrl } from '../organisations/sagas';

const preparePayloadForTaskFilter = ({ payload, canExcludeTasks, parseCustomFields }: { 
    payload: any, 
    canExcludeTasks: boolean,
    parseCustomFields: boolean
 }) => {
    const {
        filter,
        filters,
        sortBy,
        sortAscending,
        usingMultipleWorkflow,
        isPaymentPlanEnabled,
        taskIds,
        excludeTasks,
        recordLimit
    } = payload;

    const finalPayload: any = {
        Ascending: sortAscending,
        ...(
            canExcludeTasks ? {
                TaskIds: taskIds,
                ExcludeTasks: excludeTasks,
                RecordLimit: recordLimit
            } : {}
        )
    };
    const finalFilter = filter || filters; // Handle different filter property names in models

    if (finalFilter) {
        const typeValue = isPaymentPlanEnabled
            ? finalFilter.Type
            : lFilter(finalFilter.Type, (ft: any) => ft !== 8);
        const usedFilters = {
            ...finalFilter,
            Type: typeValue,
            [`Type${appliedFilterIndicator}`]: typeValue,
        };
        const cleanFilters = removeAppliedFiltersForApiRequest(
            usedFilters,
            true,
            'task',
            true
        );
        const assignedUserValue = cleanFilters.AssignedUserId;
        if (assignedUserValue) {
            if (checkIfEmailIsValid(assignedUserValue)) {
                delete cleanFilters.AssignedUserId;
                cleanFilters.AssignedEmailAddress = assignedUserValue;
            }
        }
        if (parseCustomFields) {
            if (cleanFilters.CustomFieldFilters) {
                cleanFilters.CustomFieldFilters = JSON.parse(
                    cleanFilters.CustomFieldFilters
                );
            }

            if (cleanFilters.CustomFieldMultipleValuesFilters) {
                cleanFilters.CustomFieldMultipleValuesFilters = JSON.parse(
                    cleanFilters.CustomFieldMultipleValuesFilters
                );
            }
        }
        if (!usingMultipleWorkflow) delete cleanFilters.WorkflowIds;
        Object.assign(finalPayload, cleanFilters);
    }

    if (sortBy) {
        const sortFields = populateSortFieldsParamSortParams(sortBy);
        if (parseCustomFields) {
            if (sortFields.CustomFieldSort) {
                sortFields.CustomFieldSort = JSON.parse(
                    sortFields.CustomFieldSort
                );
            }
        }
        Object.assign(finalPayload, sortFields);
    }

    return finalPayload;
}

/**
 * Selector for getting the activeTasks selected data.
 * @param state
 */
export const getTaskData = (state: ApplicationState) =>
    state.tasks.activeTasks.activeData;

/**
 * Selector for getting the lists data for active tasks.
 * @param state
 */
export const getTasksData = (state: ApplicationState) =>
    state.tasks.activeTasks.data;

export const getTasksPageData = (state: ApplicationState) =>
    state.tasks.activeTasks.pageData;

/**
 * Selector for getting the collections task data.
 * @param state
 */
export const getCollectionsTaskData = (state: ApplicationState) =>
    state.tasks.taskHistory.activeData.collections.actionedTasks.activeData;

/**
 * Selector for getting the selectedId for active tasks.
 * @param state
 */
export const getSelectedTaskId = (state: ApplicationState) =>
    state.tasks.activeTasks.activeData.selectedId;

/**
 * Selector for getting the filter options used for active tasks.
 * @param state
 */
export const getTasksActionFilterOptions = (state: ApplicationState) =>
    state.tasks.activeTasks.actionFilterOptions;

/**
 * Selector for getting the task history notification data.
 * @param state
 */
export const getTaskHistoryNotificationData = (state: ApplicationState) =>
    state.tasks.taskHistory.activeData.notifications.activeData;

/**
 * Selector for getting the task history collections -> task data.
 * @param state
 */
export const getTaskHistoryCollectionsTaskData = (state: ApplicationState) =>
    state.tasks.taskHistory.activeData.collections.actionedTasks.activeData;

export const getViewingUser = (state: ApplicationState) =>
    state.tasks.activeTasks.viewingUser;

export const getTaskWorkflows = (state: ApplicationState) =>
    state.tasks.taskWorkflows;

/**
 * Common function for preparing the sort values inside the payload needed for connecting to API.
 * @param sortBy
 */
export const populateSortFieldsParamSortParams = (sortBy: string) => {
    let sortFields: DynamicObject = {};
    if (includes(sortBy, customFieldIndicator)) {
        sortFields = getSortFieldsWithCustomFields(sortBy);
    } else {
        sortFields = { SortField: sortBy, ThenSortField: '' };

        if (sortBy === 'Type') {
            sortFields.SortField = 'Automation';
            sortFields.ThenSortField = 'Type';
        }
    }

    return sortFields;
};

export const getCompanyIdByTaskSelectedId = (state: ApplicationState) =>
{
    const taskSelectedId = state.tasks.activeTasks.activeData.selectedId;
    const taskSelected = state.tasks.activeTasks.data.find(c => c.Id === taskSelectedId) as any;

    return taskSelected ? taskSelected.CompanyId : undefined;
}

let refetchCount = 0;
/**
 * Function for getting the tasks list to be used for Active tasks page.
 * @param param0
 */
function* handleGetTasksRequest({ payload: sagaPayload }: any) {
    let { refetchCount, ...payload } = sagaPayload;
    refetchCount = refetchCount || 0;
    const errorMessage = 'Error fetching task list. Please try again later.';
    try {
        const {
            pageSize,
            currentPage,
            lockedDeliveryMethod,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            isPaymentPlanEnabled,
        } = payload;

        // To call async functions, use redux-saga's `call()`.
        const commonTaskFilter = preparePayloadForTaskFilter({ payload, canExcludeTasks: false, parseCustomFields: false });
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TASKS_FOR_COMPANY, {
                ...commonTaskFilter,
                PageSize: pageSize,
                Skip: currentPage * TASKS_PAGE.pageSize,
                LockedDeliveryMethod: lockedDeliveryMethod,
                IsCloudImportType: isUsingCloudImportType,
                IsMultipleWorkflow: usingMultipleWorkflow,
                PaymentPlanEnabled: isPaymentPlanEnabled,
            })
        );
        
        const { Tasks } = get(res.data, 'GetTasksForCompany');
        if (Tasks) {
            const responsePayload = {
                data: Tasks,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Tasks.length < pageSize) &&
                        !(pageSize < TASKS_PAGE.pageSize),
                },
            };

            refetchCount = 0;
            yield put(getTasksSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getTasksRequestAction({ ...payload, refetchCount }));
        } else {
            yield put(getTasksErrorAction([errorMessage]));
        }
    }
}

function* handleGetTasksForOrganisationRequest({ payload: sagaPayload }: any) {
    let { refetchCount, ...payload } = sagaPayload;
    refetchCount = refetchCount || 0;
    const errorMessage = 'Error fetching task list. Please try again later.';
    try {
        const {
            pageSize,
            currentPage,
            lockedDeliveryMethod,
            isUsingCloudImportType,
            usingMultipleWorkflow,
            isPaymentPlanEnabled,
            region
        } = payload;

        const currentSession: DynamicObject = yield Auth.currentSession();
        const accessToken = get(currentSession, 'accessToken.jwtToken');

        let apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        let res: DynamicObject;
        const usedQuery = queries.GET_TASKS_FOR_ORGANISATION;
        const commonTaskFilter = preparePayloadForTaskFilter({ payload, canExcludeTasks: false, parseCustomFields: false });
        const variables = {
            ...commonTaskFilter,
            PageSize: pageSize,
            Skip: currentPage * TASKS_PAGE.pageSize,
            LockedDeliveryMethod: lockedDeliveryMethod,
            IsCloudImportType: isUsingCloudImportType,
            IsMultipleWorkflow: usingMultipleWorkflow,
            PaymentPlanEnabled: isPaymentPlanEnabled,
        };

        if (apiUrl) {
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(usedQuery),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(usedQuery, variables)
            );
        }

        const { Tasks } = get(res.data, 'GetTasksForOrganisation');
        if (Tasks) {
            const responsePayload = {
                data: Tasks,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Tasks.length < pageSize) &&
                        !(pageSize < TASKS_PAGE.pageSize),
                },
            };

            refetchCount = 0;
            yield put(getTasksSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getTasksForOrganisationRequestAction({ ...payload, refetchCount }));
        } else {
            yield put(getTasksErrorAction([errorMessage]));
        }
    }
}

function* handleGetOrganisationTasksHistoryRequest({ payload }: any) {
    const errorMessage =
        'Error fetching task history list. Please try again later.';
    try {
        const { 
            filters, 
            sortBy, 
            sortAscending, 
            pageSize, 
            currentPage 
        } = payload;
        // To call async functions, use redux-saga's `call()`.
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'task',
            true
        );

        const actionedByUserValue = cleanFilters.ActionedByUserId;
        if (actionedByUserValue) {
            if (checkIfEmailIsValid(actionedByUserValue)) {
                delete cleanFilters.ActionedByUserId;
                cleanFilters.ActionedByEmailAddress = actionedByUserValue;
            }
        }

        let apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        const variables = {
            ...cleanFilters,
            SortField: sortBy,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage * TASK_HISTORY_PAGE.pageSize,
        };

        const response: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_TASK_BATCH_HISTORY_FOR_ORGANISATION,
            variables
        );

        const { TaskBatches } = get(response.data, 'GetTaskBatchHistoryForOrganisation');

        if (TaskBatches) {
            const responsePayload = {
                data: TaskBatches,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(TaskBatches.length < pageSize) &&
                        !(pageSize < TASK_HISTORY_PAGE.pageSize),
                },
            };

            refetchCount = 0;
            yield put(getTaskHistorySuccessAction(responsePayload));
        } else {
            yield put(getTaskHistoryErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount 
                && checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getTaskHistoryRequestAction(payload));
        } else {
            yield put(getTaskHistoryErrorAction([errorMessage]));
        }
    }
}

/**
 * Function for checking the ready state of tasks selected to enable/disable the `Action` button in
 * Active tasks page.
 * @param param0
 */
function* handleCheckTasksReadyStateRequest({ payload }: any) {
    const {
        pageSize,
        currentPage,
        callback,
    } = payload;
    // To call async functions, use redux-saga's `call()`.
    const commonTaskFilter = preparePayloadForTaskFilter({ payload, canExcludeTasks: true, parseCustomFields: false });

    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.CHECK_TASKS_ACTION_STATE, {
                ...commonTaskFilter,
                PageSize: pageSize,
                Skip: currentPage * TASKS_PAGE.pageSize,
            })
        );

        const tasksActionState = get(res.data, 'TasksActionState');

        if (callback) callback(tasksActionState);
    } catch (err) {
        yield put(notifyCustomersResponseAction());
        if (callback) {
            callback(false);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called when proceeding with notify customers for communication tasks.
 * @param param0
 */
function* handleNotifyCustomersRequest({ payload: sagaPayload }: any) {
    const { callback, isCommunicationSelectedByUser, selectedContact } = sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });

    const payload = {
        TaskManagementFilter: { ...commonTaskFilter },
        IsCommunicationSelectedByUser: isCommunicationSelectedByUser,
        SelectedContact: selectedContact
    };

    try {
        // To call async functions, use redux-saga's `call()`.
        yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/automated-communication/send',
            {
                body: payload,
            }
        );

        yield put(notifyCustomersResponseAction());
        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
    } catch (err) {
        yield put(notifyCustomersResponseAction());

        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}


function* handleNotifyOrganisationCustomersRequest({ payload: sagaPayload }: any) {
    const { callback } = sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });

    const payload = {
        TaskManagementFilter: { ...commonTaskFilter },
    };

    let apiUrl: string | undefined = yield select(
        getOrganisationActionUrl
    );
    
    try {
        // To call async functions, use redux-saga's `call()`.
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            yield call(
                customPostData,
                `${apiUrl}/task/action/organisation/automated-communication/send`,
                payload, { Authorization: accessToken }
            );
        } else {
            yield call(
                [API, 'post'],
                API_NAME,
                '/task/action/organisation/automated-communication/send',
                {
                    body: payload,
                }
            );
        }

        yield put(notifyCustomersResponseAction());
        if (callback) {
            const response = {
                IsSuccess: true,
            };

            callback(response);
        }
    } catch (err) {
        yield put(notifyCustomersResponseAction());

        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function for getting the tasks action filter options.
 */
function* handleGetTasksActionFilterOptionsRequest({ payload: sagaPayload }: any) {
    try {
        const { companyId } = sagaPayload;
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_TASKS_ACTION_FILTER_OPTIONS, 
            { CompanyId: companyId || null }
        );

        if (res.error) {
            console.log('res.error', res.error);
        } else {
            refetchCount = 0;

            yield put(
                getTasksActionFilterOptionsResponseAction(
                    res.data.GetTasksActionFilterOptions
                )
            );
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getTasksActionFilterOptionsRequestAction());
        } else {
            getTasksActionFilterOptionsResponseAction({});
        }
    }
}

/**
 * Function for getting the task details for a selected task.
 * @param param0
 */
function* handleGetTaskDataRequest({ payload: sagaPayload }: any) {
    const {
        taskId,
        lockedDeliveryMethod,
        isUsingCloudImportType,
        usingMultipleWorkflow,
        isPaymentPlanEnabled,
        isCalendarView
    } = sagaPayload;
    const errorMessage = 'Error fetching task details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TASK_DETAILS_FOR_COMPANY, {
                TaskId: taskId,
                LockedDeliveryMethod: lockedDeliveryMethod,
                IsCloudImportType: isUsingCloudImportType,
                IsMultipleWorkflow: usingMultipleWorkflow,
                PaymentPlanEnabled: isPaymentPlanEnabled,
                IsCalendarView: isCalendarView
            })
        );

        const Task = get(res.data, 'GetTaskDetailsForCompany');
        if (Task) {
            const responsePayload = {
                record: Task,
            };
            
            yield put(getTaskDataSuccessAction(responsePayload));
        } else {
            yield put(getTaskDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTaskDataErrorAction([errorMessage]));
    }
}

function* handleGetTaskDataForOrganisationRequest({ payload: sagaPayload }: any) {
    const {
        taskId,
        companyId,
        lockedDeliveryMethod,
        isUsingCloudImportType,
        usingMultipleWorkflow,
        isPaymentPlanEnabled,
    } = sagaPayload;
    const errorMessage = 'Error fetching task details. Please try again later.';
    try {
        let apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        let res: DynamicObject;
        const usedQuery = queries.GET_TASK_DETAILS_FOR_ORGANISATION;
        const variables = {
            TaskId: taskId,
            CompanyId: companyId,
            LockedDeliveryMethod: lockedDeliveryMethod,
            IsCloudImportType: isUsingCloudImportType,
            IsMultipleWorkflow: usingMultipleWorkflow,
            PaymentPlanEnabled: isPaymentPlanEnabled,
        };
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(usedQuery),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(usedQuery, variables)
            );
        }

        const Task = get(res.data, 'GetTaskDetailsForOrganisation');
        if (Task) {
            const responsePayload = {
                record: Task,
            };

            yield put(getTaskDataSuccessAction(responsePayload));
        } else {
            yield put(getTaskDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTaskDataErrorAction([errorMessage]));
    }
}

/**
 * Function that sets the selected task id in redux.
 * @param param0
 */
function* handleSetSelectedTaskIdRequest({ payload }: any) {
    const { taskId, callback } = payload;
    yield put(setSelectedTaskIdSuccessAction(taskId));
    callback();
}

/**
 * Function for getting the ticket invoices list.
 * @param param0
 */
function* handleGetTaskTicketInvoicesRequest({ payload }: any) {
    const errorMessage = `Error fetching notification's invoice list. Please try again later.`;
    try {
        const {
            filters,
            sortBy,
            sortAscending,
            invoiceState,
            pageSize,
            currentPage,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters);
        // To call async functions, use redux-saga's `call()`.
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_INVOICES_FOR_COMPANY,
            {
                ...cleanFilters,
                InvoiceState: invoiceState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.INVOICE_LIST.pageSize,
            }
        );

        const { Invoices } = get(res.data, 'GetInvoicesForCompany');
        if (Invoices) {
            const responsePayload = {
                data: Invoices,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Invoices.length < pageSize) &&
                        !(pageSize < DETAILS_TAB.INVOICE_LIST.pageSize),
                },
            };

            yield put(getTaskTicketInvoicesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(
            getTaskHistoryNotificationInvoicesErrorAction([errorMessage])
        );
    }
}

/**
 * Payment Plan Data Schedule
 */
/**
 * Function that fetches the schedule for the selected payment plan task.
 * @param param0
 */
function* handleGetTaskPaymentPlanScheduleRequest({ payload }: any) {
    const errorMessage = `Error fetching payment plan's schedule list. Please try again later.`;
    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters);

        const taskActiveData: DynamicObject = yield select(
            (state: ApplicationState) =>
                state.tasks.activeTasks.activeData.record
        );

        const paymentPlan = get(taskActiveData, 'PaymentPlan', []);

        const paymentPlanSchedules = get(
            paymentPlan,
            'PaymentPlanSchedules',
            []
        );
        const planSchedulesFiltered: any[] = fetchPaymentPlanScheduleHandler(
            paymentPlanSchedules,
            cleanFilters
        );

        if (paymentPlanSchedules) {
            const sortOrder = sortAscending ? 'asc' : 'desc';
            const paymentPlanSorted = orderBy(
                planSchedulesFiltered,
                [sortBy],
                [sortOrder]
            );

            const responsePayload = {
                data: paymentPlanSorted,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(paymentPlanSchedules.length < pageSize) &&
                        !(pageSize < DETAILS_TAB.INVOICE_LIST.pageSize),
                },
            };

            yield put(getTaskPaymentPlanScheduleSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTaskPaymentPlanScheduleErrorAction([errorMessage]));
    }
}

/**
 * Task History section
 */
/**
 * Function for getting the task history list.
 * @param param0
 */
function* handleGetTaskHistoryRequest({ payload }: any) {
    const errorMessage =
        'Error fetching task history list. Please try again later.';
    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;
        // To call async functions, use redux-saga's `call()`.
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'task',
            true
        );

        const actionedByUserValue = cleanFilters.ActionedByUserId;
        if (actionedByUserValue) {
            if (checkIfEmailIsValid(actionedByUserValue)) {
                delete cleanFilters.ActionedByUserId;
                cleanFilters.ActionedByEmailAddress = actionedByUserValue;
            }
        }

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TASK_BATCH_HISTORY_FOR_COMPANY, {
                ...cleanFilters,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * TASK_HISTORY_PAGE.pageSize,
            })
        );

        const { TaskBatches } = get(res.data, 'GetTaskBatchHistoryForCompany');

        if (TaskBatches) {
            const responsePayload = {
                data: TaskBatches,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(TaskBatches.length < pageSize) &&
                        !(pageSize < TASK_HISTORY_PAGE.pageSize),
                },
            };

            refetchCount = 0;
            yield put(getTaskHistorySuccessAction(responsePayload));
        } else {
            yield put(getTaskHistoryErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getTaskHistoryRequestAction(payload));
        } else {
            yield put(getTaskHistoryErrorAction([errorMessage]));
        }
    }
}

/**
 * Function for getting the details for a selected task history record.
 * @param param0
 */
function* handleGetTaskHistoryDataRequest({ payload: taskId }: any) {
    const errorMessage = 'Error fetching task details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TASK_DETAILS_FOR_COMPANY, {
                TaskId: taskId,
            })
        );

        const Task = get(res.data, 'GetTaskDetailsForCompany');
        if (Task) {
            const responsePayload = {
                record: Task,
            };

            yield put(getTaskHistoryDataSuccessAction(responsePayload));
        } else {
            yield put(getTaskHistoryDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTaskHistoryDataErrorAction([errorMessage]));
    }
}

/**
 * Function for setting the id for the selected item in task history.
 * @param param0
 */
function* handleSetSelectedTaskHistoryIdRequest({ payload }: any) {
    const { BatchId, CompanyId, callback } = payload;
    const setSelectedTaskHistoryPayload: SetSelectedTaskHistoryPayload = {
        BatchId, CompanyId
    };
    yield put(setSelectedTaskHistoryIdSuccessAction(setSelectedTaskHistoryPayload));
    callback();
}

// Task History notifications
/**
 * Function for setting the selected id for the notifications under task history.
 * @param param0
 */
function* handleSetSelectedTaskHistoryNotificationIdRequest({ payload }: any) {
    const { taskId, callback } = payload;
    yield put(setSelectedTaskHistoryNotificationIdSuccessAction(taskId));
    callback();
}

/**
 * Function for getting the task history notifications list.
 * @param param0
 */
function* handleGetTaskHistoryNotificationsRequest({ payload }: any) {
    const errorMessage = `Error fetching task history's notification list. Please try again later.`;
    try {
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
        } = payload;
        // To call async functions, use redux-saga's `call()`.
        const sortFields = populateSortFieldsParamSortParams(sortBy);
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'task'
        );

        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_TASK_BATCH_GROUP_HISTORY_FOR_COMPANY,
            {
                ...cleanFilters,
                ...sortFields,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * TASK_HISTORY_PAGE.notifications.pageSize,
                IsCloudImportType: isUsingCloudImportType,
            }
        );

        const { TaskBatchGroups } = get(
            res.data,
            'GetTaskBatchGroupHistoryForCompany'
        );
        if (TaskBatchGroups) {
            const responsePayload = {
                data: TaskBatchGroups,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(TaskBatchGroups.length < pageSize) &&
                        !(pageSize < TASK_HISTORY_PAGE.notifications.pageSize),
                },
            };

            yield put(
                getTaskHistoryNotificationsSuccessAction(responsePayload)
            );
        } else {
            yield put(getTaskHistoryNotificationsErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
        // yield put(
        //     getCustomerInvoicesSuccessAction({
        //         data: initialState.activeData.invoices.data,
        //         pageData: initialState.activeData.invoices.pageData,
        //     })
        // );

        yield put(getTaskHistoryNotificationsErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the task history delivery report list.
 * @param param0
 */
 function* handleGetTaskHistoryDeliveryReportRequest({ payload }: any) {
    const errorMessage = `Error fetching task history's deliver report list. Please try again later.`;
    try {
        const {
            filters,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType
        } = payload;
        // To call async functions, use redux-saga's `call()`.
        const sortFields = populateSortFieldsParamSortParams(sortBy);
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'task'
        );
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_TASK_BATCH_GROUP_DELIVERY_HISTORY_FOR_COMPANY, 
            {
                ...cleanFilters,
                ...sortFields,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * TASK_HISTORY_PAGE.notifications.pageSize,
                IsCloudImportType: isUsingCloudImportType,
            }
        );

        const { TaskBatchGroups } = get(
            res.data,
            'GetTaskBatchGroupDeliveryHistoryForCompany'
        );
        if (TaskBatchGroups) {
            const responsePayload = {
                data: TaskBatchGroups,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(TaskBatchGroups.length < pageSize) &&
                        !(pageSize < TASK_HISTORY_PAGE.notifications.pageSize),
                },
            };

            yield put(
                getTaskHistoryDeliveryReportSuccessAction(responsePayload)
            );
        } else {
            yield put(getTaskHistoryDeliveryReportErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
        yield put(getTaskHistoryDeliveryReportErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the task history notification details.
 * @param param0
 */
function* handleGetTaskHistoryNotificationDataRequest({
    payload: { companyId, notificationId, 
        isUsingCloudImportType },
}: any) {
    const errorMessage =
        'Error fetching notification details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_COMMUNICATION_DETAIL, {
                CompanyId: companyId,
                CommunicationId: notificationId,
                AllInvoices: true,
            })
        );

        const CommunicationData = get(res.data, 'GetCommunicationDetail');
        if (CommunicationData) {
            const customerId = get(CommunicationData, 'Customer.Id');

            const resCustomer: DynamicObject = yield call(
                [API, 'graphql'],
                graphqlOperation(queries.GET_CUSTOMER_DETAILS_FOR_COMPANY, {
                    CustomerId: customerId,
                    IsCloudImportType: isUsingCloudImportType,
                })
            );

            const Customer = get(
                resCustomer.data,
                'GetCustomerDetailsForCompany'
            );
            if (Customer) {
                CommunicationData.Customer = Customer;
            }

            const responsePayload = {
                record: CommunicationData,
            };

            refetchCount = 0;

            yield put(
                getTaskHistoryNotificationDataSuccessAction(responsePayload)
            );
        } else {
            yield put(
                getTaskHistoryNotificationDataErrorAction([errorMessage])
            );
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getTaskHistoryNotificationDataRequestAction(
                    companyId, 
                    notificationId,
                    isUsingCloudImportType
                )
            );
        } else {
            yield put(
                getTaskHistoryNotificationDataErrorAction([errorMessage])
            );
        }
    }
}

/**
 * Function for getting the organisation's task history notification details.
 * @param param0
 */
function* handleGetOrganisationTaskHistoryNotificationDataRequest({
    payload: { 
        companyId,
        companyIds,
        notificationId, 
        conversionCurrency,
        isUsingCloudImportType 
    },
}: any) {
    const errorMessage =
        'Error fetching notification details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_COMMUNICATION_DETAIL,
            {
                CommunicationId: notificationId,
                AllInvoices: true,
            }
        ); 

        const CommunicationData = get(res.data, 'GetCommunicationDetail');
        if (CommunicationData) {
            const customerId = get(CommunicationData, 'Customer.Id');

            const resCustomer: DynamicObject = yield call(
                getRegionallyGraphqlResponse,
                apiUrl,
                queries.GET_CUSTOMER_DETAILS_FOR_ORGANISATION,
                {
                    CompanyId: companyId,
                    CompanyIds: companyIds,
                    CustomerId: customerId,
                    ConversionCurrency: conversionCurrency,
                    IsCloudImportType: isUsingCloudImportType,
                }
            );
            const Customer = get(
                resCustomer.data,
                'GetCustomerDetailsForOrganisation'
            );
            if (Customer) {
                CommunicationData.Customer = Customer;
            }

            const responsePayload = {
                record: CommunicationData,
            };

            refetchCount = 0;

            yield put(
                getOrganisationTaskHistoryNotificationDataSuccessAction(responsePayload)
            );
        } else {
            yield put(
                getOrganisationTaskHistoryNotificationDataErrorAction([errorMessage])
            );
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(
                getTaskHistoryNotificationDataRequestAction(
                    companyId,
                    notificationId,
                    isUsingCloudImportType
                )
            );
        } else {
            yield put(
                getTaskHistoryNotificationDataErrorAction([errorMessage])
            );
        }
    }
}

/**
 * Function for getting the task history notification invoices tab data.
 * @param param0
 */
function* handleGetTaskHistoryNotificationInvoicesRequest({ payload }: any) {
    const errorMessage = `Error fetching notification's invoice list. Please try again later.`;
    try {
        const {
            filters,
            sortBy,
            sortAscending,
            invoiceState,
            pageSize,
            currentPage,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters);
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_INVOICES_FOR_COMPANY, {
                ...cleanFilters,
                InvoiceState: invoiceState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.INVOICE_LIST.pageSize,
            })
        );

        const { Invoices } = get(res.data, 'GetInvoicesForCompany');
        if (Invoices) {
            const responsePayload = {
                data: Invoices,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Invoices.length < pageSize) &&
                        !(pageSize < DETAILS_TAB.INVOICE_LIST.pageSize),
                },
            };

            yield put(
                getTaskHistoryNotificationInvoicesSuccessAction(responsePayload)
            );
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(
            getTaskHistoryNotificationInvoicesErrorAction([errorMessage])
        );
    }
}

/**
 * Function for getting the task history notification tasks data.
 * @param param0
 */
function* handleGetTaskHistoryNotificationTasksRequest({ payload }: any) {
    const errorMessage = `Error fetching notification's tasks list. Please try again later.`;
    try {
        const {
            filters,
            pageSize,
            currentPage,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'task'
        );
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_TASK_HISTORY_FOR_COMPANY, {
                ...cleanFilters,
                SortField: get(payload, 'sortBy'),
                Ascending: get(payload, 'sortAscending'),
                PageSize: pageSize,
                Skip:
                    currentPage *
                    TASK_HISTORY_PAGE.notifications.tasks.pageSize,
            }
        );

        const { TaskHistories } = get(res.data, 'GetTaskHistoryForCompany');
        if (TaskHistories) {
            const responsePayload = {
                data: TaskHistories,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(TaskHistories.length < pageSize) &&
                        !(
                            pageSize <
                            TASK_HISTORY_PAGE.notifications.tasks.pageSize
                        ),
                },
            };

            yield put(
                getTaskHistoryNotificationTasksSuccessAction(responsePayload)
            );
        } else {
            yield put(
                getTaskHistoryNotificationTasksErrorAction([errorMessage])
            );
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTaskHistoryNotificationTasksErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the task history queued tasks count.
 * @param param0
 */
function* handleGetTaskHistoryQueuedTasksCountRequest({ payload }: any) {
    const { batchId, companyId, callback } = payload;
    // To call async functions, use redux-saga's `call()`.
    try {
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_TASK_HISTORY_QUEUED_TASKS_COUNT, 
            {
                BatchId: batchId,
                CompanyId: companyId || null
            }
        );

        const QueuedTasksCount = get(
            res.data,
            'GetTaskHistoryQueuedTasksCount'
        );

        if (callback)
            callback({
                IsSuccess: true,
                QueuedTasksCount,
            });
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function called for getting the task history batch status.
 * @param param0
 */
function* handleGetTaskHistoryBatchStatusRequest({ payload }: any) {
    const { batchId, companyId, callback } = payload;
    // To call async functions, use redux-saga's `call()`.
    try {
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_TASK_BATCH_STATUS, 
            {
                BatchId: batchId,
                CompanyId: companyId || null
            }
        );

        const TaskBatchStatus = get(res.data, 'GetTaskBatchStatus');

        if (callback)
            callback({
                IsSuccess: TaskBatchStatus ? true : false,
                ...TaskBatchStatus,
            });
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function called for getting the link for downloading merged pdf file in Task history notifications section.
 * @param param0
 */
function* handleDownloadMergedPdfRequest({ payload }: any) {
    const { batchId, callback } = payload;
    // To call async functions, use redux-saga's `call()`.
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.DOWNLOAD_MERGED_PDF, {
                BatchId: batchId || null,
            })
        );

        res.IsSuccess = true;
        if (res.data.DownloadMergePdf) {
            res.data.DownloadMergePdf = get(res, 'data.DownloadMergePdf');
        }
        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function called for getting the button status for the download merged pdf button.
 * @param param0
 */
function* handleDownloadMergedPdfStatusRequest({ payload }: any) {
    const { batchId, companyId, callback } = payload;
    // To call async functions, use redux-saga's `call()`.
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.ENABLE_DOWNLOAD_MERGE_PDF_LINK, {
                BatchId: batchId || null,
                CompanyId: companyId || null,
            })
        );

        res.IsSuccess = true;
        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

// Task history actioned tasks
/**
 * Function for getting the actioned tasks details.
 * @param param0
 */
function* handleGetTaskHistoryActionedTasksDataRequest({ payload }: any) {
    const errorMessage = 'Error fetching actioned tasks details. Please try again later.';
    const { batchId, companyId } = payload;
    try {
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_TASK_BATCH_HISTORY_DETAILS, 
            {
                BatchId: batchId,
                CompanyId: companyId
            }
        );

        const ActionedTasksData = get(res.data, 'GetTaskBatchHistoryDetails');
        if (ActionedTasksData) {
            const responsePayload = {
                record: ActionedTasksData,
            };

            yield put(
                getTaskHistoryChangedTasksDataSuccessAction(responsePayload)
            );
        } else {
            yield put(
                getTaskHistoryChangedTasksDataErrorAction([errorMessage])
            );
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTaskHistoryChangedTasksDataErrorAction([errorMessage]));
    }
}

// Task history changed tasks
/**
 * Function for getting the changed tasks details.
 * @param param0
 */
function* handleGetTaskHistoryChangedTasksDataRequest({
    payload: sagaPayload,
}: any) {
    const { batchId, companyId } = sagaPayload;

    const errorMessage =
        'Error fetching changed tasks details. Please try again later.';
    try {
        const params: any = {
            BatchId: batchId
        };

        if (!isUndefined(companyId)) {
            params.CompanyId = companyId;
        }

        console.log(params);
        
        // To call async functions, use redux-saga's `call()`.

        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_BATCH_EDIT_DETAILS,
            params
        );

        const ChangedTasksData = get(res.data, 'GetBatchEditDetails');
        if (ChangedTasksData) {
            const responsePayload = {
                record: ChangedTasksData,
            };

            yield put(
                getTaskHistoryChangedTasksDataSuccessAction(responsePayload)
            );
        } else {
            yield put(
                getTaskHistoryChangedTasksDataErrorAction([errorMessage])
            );
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTaskHistoryChangedTasksDataErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the task history changed tasks list.
 * @param param0
 */
function* handleGetTaskHistoryChangedTasksRequest({ payload }: any) {
    const errorMessage = `Error fetching task history's changed tasks list. Please try again later.`;
    try {
        const { filters, pageSize, currentPage } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters);
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_TASK_HISTORY_FOR_COMPANY, 
            {
                ...cleanFilters,
                Ascending: get(payload, 'sortAscending'),
                PageSize: pageSize,
                Skip: currentPage * TASK_HISTORY_PAGE.changedTasks.pageSize,
            }
        );

        const { TaskHistories } = get(res.data, 'GetTaskHistoryForCompany');
        const ChangedTasks = TaskHistories;
        if (ChangedTasks) {
            const responsePayload = {
                data: ChangedTasks,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ChangedTasks.length < pageSize) &&
                        !(pageSize < TASK_HISTORY_PAGE.changedTasks.pageSize),
                },
            };

            yield put(getTaskHistoryChangedTasksSuccessAction(responsePayload));
        } else {
            yield put(getTaskHistoryChangedTasksErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTaskHistoryChangedTasksErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the failed task list under task history changed tasks.
 * @param param0
 */
function* handleGetTaskHistoryTasksFailedRequest({ payload }: any) {
    const errorMessage = `Error fetching tasks failed list. Please try again later.`;
    try {
        const { filters, pageSize, currentPage } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters);
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_EDIT_TASKS_FAILED_FOR_COMPANY, 
            {
                ...cleanFilters,
                PageSize: pageSize,
                Skip: currentPage * TASK_HISTORY_PAGE.failedTasks.pageSize,
            }
        );

        const { Tasks } = get(res.data, 'GetEditTasksFailedForCompany');
        const TaskHistoryTasksFailed = Tasks;
        if (TaskHistoryTasksFailed) {
            const responsePayload = {
                data: TaskHistoryTasksFailed,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(TaskHistoryTasksFailed.length < pageSize) &&
                        !(pageSize < TASK_HISTORY_PAGE.failedTasks.pageSize),
                },
            };

            yield put(getTaskHistoryTasksFailedSuccessAction(responsePayload));
        } else {
            yield put(getTaskHistoryTasksFailedErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTaskHistoryTasksFailedErrorAction([errorMessage]));
    }
}

// Edit tasks
/**
 * Function called for updating task details.
 * @param param0
 */
function* handleBulkSaveTaskDetailsUpdateRequest({
    payload: sagaPayload,
}: any) {
    const { callback, taskObject } = sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });

    const payload = {
        TaskManagementFilter: { ...commonTaskFilter },
        ...taskObject,
    };

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/batch-tasks/edit',
            {
                body: payload,
            }
        );
        const { TaskEditAction } = res;
        let successMessages: any = [];
        if (TaskEditAction) {
            successMessages.push(
                `${TaskEditAction} task${TaskEditAction > 1 ? 's have' : ' has' } been udpated successfully!`
            );
        }
        yield put(bulkSaveTaskDetailsUpdateResponseAction());
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        yield put(bulkSaveTaskDetailsUpdateResponseAction());

        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

function* handleOrganisationBulkSaveTaskDetailsUpdateRequest({ payload: sagaPayload }: any) {
    const { callback, taskObject } = sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });

    const payload = {
        TaskManagementFilter: { ...commonTaskFilter },
        ...taskObject,
    };

    let apiUrl: string | undefined = yield select(
        getOrganisationActionUrl
    );

    try {
        let res: DynamicObject = {};
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            res = yield call(
                customPostData,
                `${apiUrl}/task/action/organisation/batch-tasks/edit`,
                payload, { Authorization: accessToken }
            );
        } else {
            res = yield call(
                [API, 'post'],
                API_NAME,
                '/task/action/organisation/batch-tasks/edit',
                {
                    body: payload,
                }
            );
        }

        const { TaskEditAction } = res;
        let successMessages: any = [];
        if (TaskEditAction) {
            successMessages.push(
                `${TaskEditAction} task${TaskEditAction > 1 ? 's have' : ' has'} been udpated successfully!`
            );
        }
        yield put(bulkSaveTaskDetailsUpdateResponseAction());
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        yield put(bulkSaveTaskDetailsUpdateResponseAction());

        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function called for getting the editable properties for task editing.
 * @param param0
 */
function* handleGetTaskEditPropertiesRequest({ payload: sagaPayload }: any) {
    const { callback } = sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });

    const payload = {
        TaskManagementFilter: { ...commonTaskFilter },
    };
    console.log("payload: ", payload);
    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/batch-tasks/editproperties',
            {
                body: payload,
            }
        );

        if (callback) callback(res);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for getting the workflow step options for task editing.
 * @param param0
 */
function* handleGetWorkflowStepOptionsRequest({ payload: sagaPayload }: any) {
    try {
        const { workflowId, callback } = sagaPayload;
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_WORKFLOW_STEPS_FOR_COMPANY, {
                WorkflowId: workflowId,
            })
        );

        const workflowSteps = get(res.data, 'GetWorkflowStepsForCompany');

        if (callback) callback(workflowSteps);
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for getting the actioned ticket details.
 * @param param0
 */
function* handleGetActionedTicketDetailsRequest({ payload: sagaPayload }: any) {
    const { batchId, companyId } = sagaPayload;

    const errorMessage = `Error fetching actioned ticket details. Please try again later.`;

    try {
        const params: any = {
            BatchId: batchId
        };
        if (!isUndefined(companyId))
            params.CompanyId = companyId;

        const apiUrl: string | undefined = yield select(getOrganisationActionUrl);

        const response: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_TASK_HISTORY_ACTIONED_TICKET_DETAILS,
            params
        );

        const ActionedTicketData = get(
            response.data,
            'GetTaskHistoryActionedTicketDetails'
        );

        if (ActionedTicketData) {
            const responsePayload = {
                record: ActionedTicketData,
            };

            yield put(getTaskHistoryDataSuccessAction(responsePayload));
        } else {
            yield put(getTaskHistoryDataErrorAction([errorMessage]));
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            // console.error('An unknown error occured.');
        }
        yield put(getTaskHistoryDataErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the actioned paymentplan details.
 * @param param0
 */
function* handleGetActionedPaymentPlanDetailsRequest({
    payload: sagaPayload,
}: any) {
    const { batchId } = sagaPayload;

    const errorMessage = `Error fetching actioned payment plan details. Please try again later.`;

    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(
                queries.GET_TASK_HISTORY_ACTIONED_PAYMENT_PLAN_DETAILS,
                {
                    BatchId: batchId                
                }
            )
        );

        const ActionedPaymentPlanData = get(
            res.data,
            'GetTaskHistoryActionedPaymentPlanDetails'
        );

        if (ActionedPaymentPlanData) {
            const responsePayload = {
                record: ActionedPaymentPlanData,
            };

            yield put(getTaskHistoryDataSuccessAction(responsePayload));
        } else {
            yield put(getTaskHistoryDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
        yield put(getTaskHistoryDataErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the actioned payment allocation/verification details.
 * @param param0
 */
function* handleGetActionedPaymentDetailsRequest({
    payload: sagaPayload,
}: any) {
    const { batchId, companyId } = sagaPayload;

    const errorMessage = `Error fetching actioned payment details. Please try again later.`;

    try {
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_TASK_HISTORY_ACTIONED_PAYMENT_DETAILS,
            {
                BatchId: batchId,
                CompanyId: companyId
            }
        );

        const ActionedPaymentData = get(
            res.data,
            'GetTaskHistoryActionedPaymentDetails'
        );
        
        if (ActionedPaymentData) {
            const responsePayload = {
                record: ActionedPaymentData,
            };

            yield put(getTaskHistoryDataSuccessAction(responsePayload));
        } else {
            yield put(getTaskHistoryDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
        yield put(getTaskHistoryDataErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the actioned PaymentBehaviourInsight details.
 * @param param0
 */
function* handleGetActionedPaymentBehaviourInsightDetailsRequest({
    payload: sagaPayload,
}: any) {
    const { batchId, companyId } = sagaPayload;

    const errorMessage = `Error fetching actioned payment behaviour insight details. Please try again later.`;

    try {
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_ACTIONED_PAYMENT_BEHAVIOUR_INSIGHT_DETAILS,
            {
                BatchId: batchId,
                CompanyId: companyId
            }
        );
                
        const ActionedPaymentBehaviourInsightsData = get(
            res.data,
            'GetTaskHistoryActionedPaymentBehaviourInsights'
        );
        
        if (ActionedPaymentBehaviourInsightsData) {
            const responsePayload = {
                record: ActionedPaymentBehaviourInsightsData,
            };

            yield put(getTaskHistoryDataSuccessAction(responsePayload));
        } else {
            yield put(getTaskHistoryDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
        yield put(getTaskHistoryDataErrorAction([errorMessage]));
    }
}


// Collections
/**
 * Function for getting the collection escalation details.
 * @param param0
 */
function* handleGetTaskHistoryCollectionsDataRequest({ payload }: any) {
    const errorMessage =
        'Error fetching collection escalation details. Please try again later.';
    try {
        const { batchId, companyId } = payload;
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_BATCH_COLLECTION_DETAILS, 
            {
                BatchId: batchId,
                CompanyId: companyId
            }
        );

        const CollectionItemsData = get(res.data, 'GetBatchCollectionDetails');
        if (CollectionItemsData) {
            const responsePayload = {
                record: CollectionItemsData,
            };

            yield put(
                getTaskHistoryCollectionsDataSuccessAction(responsePayload)
            );
        } else {
            yield put(getTaskHistoryCollectionsDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTaskHistoryCollectionsDataErrorAction([errorMessage]));
    }
}

/**
 * Function for getting the collection tasks actioned tasks items.
 * @param param0
 */
function* handleGetTaskHistoryCollectionTasksRequest({ payload }: any) {
    const errorMessage = `Error fetching task history's collection tasks actioned list. Please try again later.`;
    try {
        const { filters, pageSize, currentPage } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(filters);
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_TASK_HISTORY_FOR_COMPANY, 
            {
                ...cleanFilters,
                Ascending: get(payload, 'sortAscending'),
                PageSize: pageSize,
                Skip: currentPage * TASK_HISTORY_PAGE.collections.pageSize,
            }
        );

        const { TaskHistories } = get(res.data, 'GetTaskHistoryForCompany');
        const ChangedTasks = TaskHistories;
        if (ChangedTasks) {
            const responsePayload = {
                data: ChangedTasks,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ChangedTasks.length < pageSize) &&
                        !(pageSize < TASK_HISTORY_PAGE.collections.pageSize),
                },
            };

            yield put(
                getTaskHistoryCollectionTasksSuccessAction(responsePayload)
            );
        } else {
            yield put(getTaskHistoryCollectionTasksErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTaskHistoryCollectionTasksErrorAction([errorMessage]));
    }
}

/**
 * Function for setting the selectedId for task history collections task.
 * @param param0
 */
function* handleSetSelectedTaskHistoryCollectionsTaskIdRequest({
    payload,
}: any) {
    const { taskId, callback } = payload;
    yield put(setSelectedTaskHistoryCollectionsTaskIdSuccessAction(taskId));
    callback();
}

/**
 * Function for getting the task history collections task details.
 * @param param0
 */
function* handleGetTaskHistoryCollectionsTaskDataRequest({ payload }: any) {
    const { taskId, isUsingCloudImportType, companyId } = payload;
    const errorMessage =
        'Error fetching collection actioned task details. Please try again later.';
    try {
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_TASK_DETAILS_FOR_COMPANY, 
            {
                CompanyId: companyId,
                TaskId: taskId,
                IsCloudImportType: isUsingCloudImportType,
            }
        );

        const Task = get(res.data, 'GetTaskDetailsForCompany');
        if (Task) {
            const responsePayload = {
                record: Task,
            };

            yield put(
                getTaskHistoryCollectionsTaskDataSuccessAction(responsePayload)
            );
        } else {
            yield put(
                getTaskHistoryCollectionsTaskDataErrorAction([errorMessage])
            );
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getTaskHistoryCollectionsTaskDataErrorAction([errorMessage]));
    }
}

/**
 * Function for escalating the selected task items (in Active tasks page) to collections.
 * @param param0
 */
function* handleEscalateToCollections({ payload: sagaPayload }: any) {
    const { usingCustomerWorkflow, callback } =
        sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });

    const payload = {
        TaskManagementFilter: {
            ...commonTaskFilter
        },
    };

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/collection/escalate',
            {
                body: payload,
            }
        );
        const { TaskEditAction } = res;
        let successMessages: any = [];
        if (TaskEditAction) {
            const itemText = usingCustomerWorkflow ? 'Customer' : 'Invoice';
            successMessages.push(
                `${itemText}(s) escalated to collections successfully!`
            );
        }
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

function* handleOrganisationEscalateToCollections({ payload: sagaPayload }: any) {
    const { usingCustomerWorkflow, callback } =
        sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });

    const payload = {
        TaskManagementFilter: {
            ...commonTaskFilter
        },
    };

    let apiUrl: string | undefined = yield select(
        getOrganisationActionUrl
    );
    
    try {
        let res : DynamicObject = {};
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            res = yield call(
                customPostData,
                `${apiUrl}/task/action/organisation/collection/escalate`,
                payload, { Authorization: accessToken }
            );
        } else {
            res = yield call(
                [API, 'post'],
                API_NAME,
                '/task/action/organisation/collection/escalate',
                {
                    body: payload,
                }
            );
        }
        
        const { TaskEditAction } = res;
        let successMessages: any = [];
        if (TaskEditAction) {
            const itemText = usingCustomerWorkflow ? 'Customer' : 'Invoice';
            successMessages.push(
                `${itemText}(s) escalated to collections successfully!`
            );
        }
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

function* handleProcessTaskCompactViewReduxSetting({
    payload: userPreferences,
}: any) {
    const isCompactView = getIsCompactViewForPage(
        userPreferences,
        compactViewPages.ACTIVE_TASKS
    );
    const compactViewBool = !isUndefined(isCompactView)
        ? isCompactView
        : initialState.activeTasks.compactView;

    yield put(getTableCompactViewSuccessAction(compactViewBool));
}

/**
 * Function for getting the task table compact view setting.
 * @param param0
 */
function* handleGetTableCompactViewRequest({ payload: refetchUserPrefs }: any) {
    try {
        if (refetchUserPrefs) {
            yield put(
                getUserPreferencesRequestAction((res: DynamicObject) => {
                    const preferences = get(res, 'data', []);
                    put(processTaskCompactViewReduxSettingAction(preferences));
                })
            );
        } else {
            const userPreference: UserPreference | {} = yield select(
                (state: ApplicationState) => state.users.userPreference
            );

            yield put(
                processTaskCompactViewReduxSettingAction(
                    get(userPreference, 'Preferences', [])
                )
            );
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
    }
}

/**
 * Function that clears the task status Ids if current compact view and new compact view statuses are not equal.
 * @param param0
 */
function* handleGetTableCompactViewSuccess() {
    yield put(setTaskExpandStatusAction());
}

/**
 * Function for setting the task table compact view status.
 * @param param0
 */
function* handleSetTableCompactViewRequest({ payload: sagaPayload }: any) {
    const { isCompact, callback } = sagaPayload;
    try {
        const payload = {
            PageName: compactViewPages.ACTIVE_TASKS,
            IsCompactView: isCompact,
        };
        yield call([API, 'post'], API_NAME, '/pageView/userpreference', {
            body: payload,
        });
        yield put(setTableCompactViewSuccessAction(true)); // boolean for indicator if compact view status should be refetched or not
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        yield put(setTableCompactViewSuccessAction());

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for adding comment to principal element of tasks (from task view)
 * @param param0
 */
function* handleTaskAddCommentRequest({ payload: sagaPayload }: any) {
    const {
        taskIds,
        excludeTasks,
        comment,
        callback,
    } = sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });
    const payload = {
        TaskManagementFilter: { ...commonTaskFilter },
        Comment: comment,
    };

    try {
        yield call([API, 'post'], API_NAME, '/conversation/save/taskcomment', {
            body: payload,
        });
        if (callback) {
            let RefetchList = true;
            if (taskIds.length === 1 && excludeTasks === false) {
                let cusTasksToUpdate: DynamicObject[] = [];
                RefetchList = false;
                const currentUser: DynamicObject = yield select(getCurrentUser);
                const taskList: DynamicObject[] = yield select(getTasksData);
                const usingCustomerWorkflow: boolean = yield select(
                    getUsingCustomerWorflow
                );
                const tasksUpdated: DynamicObject[] = map(
                    taskIds,
                    (uId: string) => {
                        const taskRecord = find(taskList, ['Id', uId]);
                        let returnData: any = undefined;
                        const convLine = {
                            Content: comment,
                            CreatedDateTime: formatDateToDateObjectUTC(
                                moment(),
                                undefined,
                                true
                            ),
                            User: {
                                GivenName: get(currentUser, GivenNameAttribute),
                                FamilyName: get(
                                    currentUser,
                                    FamilyNameAttribute
                                ),
                            },
                        };
                        if (taskRecord && !isEmpty(taskRecord)) {
                            returnData = { ...taskRecord };
                            if (
                                taskRecord.Type === taskHistoryBatchTypes.Ticket
                            ) {
                                returnData.Ticket = {
                                    ...returnData.Ticket,
                                    ConversationLine: convLine,
                                };
                            } else if (
                                taskRecord.Type ===
                                taskHistoryBatchTypes.PaymentSchedule
                            ) {
                                const tasksWithPaymentPlan = lFilter(taskList, [
                                    'PaymentPlan.Id',
                                    returnData.PaymentPlan.Id,
                                ]);
                                if (!isEmpty(tasksWithPaymentPlan)) {
                                    cusTasksToUpdate = map(
                                        tasksWithPaymentPlan,
                                        (tr: DynamicObject) => {
                                            return {
                                                ...tr,
                                                PaymentPlan: {
                                                    ...tr.PaymentPlan,
                                                    ConversationLine: convLine,
                                                },
                                            };
                                        }
                                    );
                                }
                            } else if (
                                taskRecord.Type ===
                                taskHistoryBatchTypes.RemittanceAdvice
                            ) {
                                returnData.RemittanceAdvice = {
                                    ...returnData.RemittanceAdvice,
                                    ConversationLine: convLine
                                };
                            } else if (
                                taskRecord.Type === taskHistoryBatchTypes.NewInvoiceEmail
                            ) {
                                returnData.Invoice = {
                                    ...returnData.Invoice,
                                    ConversationLine: convLine,
                                };
                            } else if (usingCustomerWorkflow) {
                                const tasksWithCustomer = lFilter(taskList, [
                                    'Customer.CustomerCode',
                                    returnData.Customer.CustomerCode,
                                ]);
                                if (!isEmpty(tasksWithCustomer)) {
                                    cusTasksToUpdate = map(
                                        tasksWithCustomer,
                                        (tr: DynamicObject) => {
                                            return {
                                                ...tr,
                                                Customer: {
                                                    ...tr.Customer,
                                                    ConversationLine: convLine,
                                                },
                                            };
                                        }
                                    );
                                }
                            } else {
                                returnData.Invoice = {
                                    ...returnData.Invoice,
                                    ConversationLine: convLine,
                                };
                            }
                        }
                        return returnData;
                    }
                );

                const allTasksUpdated = [...tasksUpdated, ...cusTasksToUpdate];

                const pageData: PageData = yield select(getTasksPageData);
                yield put(
                    getTasksSuccessAction({
                        data: allTasksUpdated,
                        pageData,
                        mergeData: true,
                    })
                );
            }
            const response = {
                IsSuccess: true,
                RefetchList,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function that ccalls the action for getting the new compact view status to sync our redux value.
 * @param param0
 */
function* handleSetTableCompactViewSuccess({
    payload: refetchCompactViewState,
}: any) {
    if (refetchCompactViewState) {
        yield put(getTableCompactViewRequestAction(refetchCompactViewState));
    }

    return;
}

/**
 * Function called for getting the editable properties for task editing.
 * @param param0
 */
function* handleActionPaymentVerificationTaskRequest({ payload: sagaPayload }: any) {
    const { callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/paymentverification',
            {
                body: sagaPayload,
            }
        );
        const { TaskPaymentAction } = res;
        let successMessages: any = [];

        if (TaskPaymentAction) {
            successMessages.push(
                'Payment verified successfully!'
            );
        }

        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

function* handleActionOrganisationPaymentVerificationTaskRequest({ payload: sagaPayload }: any) {
    const { callback } = sagaPayload;

    let apiUrl: string | undefined = yield select(
        getOrganisationActionUrl
    );

    try {
        let res : DynamicObject = {};
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            res = yield call(
                customPostData,
                `${apiUrl}/task/action/organisation/paymentverification`,
                sagaPayload, { Authorization: accessToken }
            );
        } else {
            res = yield call(
                [API, 'post'],
                API_NAME,
                '/task/action/organisation/paymentverification',
                {
                    body: sagaPayload,
                }
            );
        }

        const { TaskPaymentAction } = res;
        let successMessages: any = [];

        if (TaskPaymentAction) {
            successMessages.push(
                'Payment verified successfully!'
            );
        }

        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

function* handleActionPaymentRALinkTaskRequest({ payload: sagaPayload }: any) {
    const { callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/paymentallocation/remittanceadvice',
            {
                body: sagaPayload,
            }
        );
        const { TaskPaymentAction } = res;
        let successMessages: any = [];

        if (TaskPaymentAction) {
            successMessages.push(
                'Payment linked successfully!'
            );
        }

        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }
    }
}

function* handleActionOrganisationPaymentRALinkTaskRequest({ payload: sagaPayload }: any) {
    const { callback } = sagaPayload;

    let apiUrl: string | undefined = yield select(
        getOrganisationActionUrl
    );

    try {
        let res : DynamicObject = {};
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            res = yield call(
                customPostData,
                `${apiUrl}/task/action/organisation/paymentallocation/remittanceadvice`,
                sagaPayload, { Authorization: accessToken }
            );
        } else {
            res = yield call(
                [API, 'post'],
                API_NAME,
                '/task/action/organisation/paymentallocation/remittanceadvice',
                {
                    body: sagaPayload,
                }
            );
        }

        const { TaskPaymentAction } = res;
        let successMessages: any = [];

        if (TaskPaymentAction) {
            successMessages.push(
                'Payment linked successfully!'
            );
        }

        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }
    }
}

function* handleActionRequestRATaskRequest({ payload: sagaPayload }: any) {
    const { callback } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/paymentallocation/remittanceadvicerequest',
            {
                body: sagaPayload,
            }
        );
        const { TaskPaymentAction } = res;
        let successMessages: any = [];

        if (TaskPaymentAction) {
            successMessages.push(
                'Remittance advice request sent successfully!'
            );
        }

        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }
    }
}

function* handleActionOrganisationRequestRATaskRequest({ payload: sagaPayload }: any) {
    const { callback } = sagaPayload;

    let apiUrl: string | undefined = yield select(
        getOrganisationActionUrl
    );

    try {
        let res : DynamicObject = {};
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            res = yield call(
                customPostData,
                `${apiUrl}/task/action/organisation/paymentallocation/remittanceadvicerequest`,
                sagaPayload, { Authorization: accessToken }
            );
        } else {
            res = yield call(
                [API, 'post'],
                API_NAME,
                '/task/action/organisation/paymentallocation/remittanceadvicerequest',
                {
                    body: sagaPayload,
                }
            );
        }

        const { TaskPaymentAction } = res;
        let successMessages: any = [];

        if (TaskPaymentAction) {
            successMessages.push(
                'Remittance advice request sent successfully!'
            );
        }

        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }
    }
}
/**
 * Function called when actioning manually allocate payment task.
 * @param param0
 */
function* handleActionManualAllocatePaymentRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;

    const payload = {
        ...fields,
    };

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/manual-allocate-payment',
            {
                body: payload,
            }
        );

        if (callback) {
            const response = {
                IsSuccess: true
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

function* handleActionOrganisationManualAllocatePaymentRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;

    const payload = {
        ...fields,
    };

    let apiUrl: string | undefined = yield select(
        getOrganisationActionUrl
    );

    try {
        let res : DynamicObject = {};
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            res = yield call(
                customPostData,
                `${apiUrl}/task/action/organisation/manual-allocate-payment`,
                payload, { Authorization: accessToken }
            );
        } else {
            res = yield call(
                [API, 'post'],
                API_NAME,
                '/task/action/organisation/manual-allocate-payment',
                {
                    body: payload,
                }
            );
        }

        if (callback) {
            const response = {
                IsSuccess: true
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function called when actioning manually allocate payment with overpayment task.
 * @param param0
 */
function* handleActionManualAllocatePaymentWithOverpaymentRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;

    const payload = {
        ...fields,
    };

    try {
        const response: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/over-payment-credit', // for update
            {
                body: payload,
            }
        );

        if (callback) {
            const response = {
                IsSuccess: true
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for getting the `Action` options on resolving ticket panel.
 * @param param0
 */
function* handleGetTriggersForWorkflowRequest({ payload: sagaPayload }: any) {
    const { workflowId, stateName, companyId, callback } = sagaPayload;
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TRIGGERS_FOR_WORKFLOW_STATE, {
                WorkflowId: workflowId,
                StateName: stateName,
                CompanyId: companyId
            })
        );

        const { WorkflowTransitions: triggerOptions } = get(
            res,
            'data.GetTriggersForWorkflowState'
        );

        if (callback) callback(triggerOptions);
    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for getting the `RemittanceAdvices` Lists on TaskActionPaymentLinkRemittanceContent panel.
 * @param param
 */
function* handleGetRemittanceAdvicesRequest({ payload: sagaPayload }: any) {
    const { 
        CompanyId, 
        CustomerId,
        RemittanceAdviceState, 
        PaidDateMin, 
        PaidDateMax, 
        PaidAmountMin, 
        PaidAmountMax, 
        SortField,
        Ascending,
        AmountType,
        Skip,
        PageSize,
        callback
    } = sagaPayload;

    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_REMITTANCE_ADVICES_FOR_COMPANY, {
                CompanyId: CompanyId,
                CustomerId: CustomerId,
                RemittanceAdviceState: RemittanceAdviceState, 
                AmountType: AmountType, 
                PaidDateMin: PaidDateMin,
                PaidDateMax: PaidDateMax,
                PaidAmountMin: PaidAmountMin,
                PaidAmountMax: PaidAmountMax,
                SortField: SortField,
                Ascending: Ascending,
                Skip: Skip,
                PageSize: PageSize,
                callback
            })
        );

        const { RemittanceAdvices } = get(res.data, 'GetRemittanceAdvicesForCompany');

        if (callback) callback(RemittanceAdvices);
    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for saving payment behavior insights
 * @param param0
 */
/**
 * Function called when actioning payment behaviour insight tasks.
 * @param param0
 */
function* handleTaskPaymentBehaviorInsightRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;

    const payload = {
        ...fields,
    };

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/payment-behaviour-insight',
            {
                body: payload,
            }
        );
        const { TaskPaymentBehaviorInsightAction } = res;
        let successMessages: any = [];
        if (TaskPaymentBehaviorInsightAction) {
            successMessages.push(`Payment behavior insight task has been saved successfully!`);
        }
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

function* handleTaskOrganisationPaymentBehaviorInsightRequest({ payload: sagaPayload }: any) {
    const { callback, ...fields } = sagaPayload;

    const payload = {
        ...fields,
    };

    let apiUrl: string | undefined = yield select(
        getOrganisationActionUrl
    );

    try {
        let res : DynamicObject = {};
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            res = yield call(
                customPostData,
                `${apiUrl}/task/action/organisation/payment-behaviour-insight`,
                payload, { Authorization: accessToken }
            );
        } else {
            res = yield call(
                [API, 'post'],
                API_NAME,
                '/task/action/organisation/payment-behaviour-insight',
                {
                    body: payload,
                }
            );
        }

        const { TaskPaymentBehaviorInsightAction } = res;
        let successMessages: any = [];
        if (TaskPaymentBehaviorInsightAction) {
            successMessages.push(`Payment behavior insight task has been saved successfully!`);
        }
        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}
/** 
 * Function called for adding task viewings.
 * @param param0
 */
function* handleAddTaskViewingsRequest({
    payload: sagaPayload,
}: any) {
    const { callback } = sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });

    const payload = {
        TaskManagementFilter: { ...commonTaskFilter }
    };

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/task/viewing',
            {
                body: payload,
            }
        );

        if (callback) {
            const response = {
                IsSuccess: true
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function called for deleting task viewings.
 * @param param0
 */
function* handleDeleteTaskViewingsRequest({
    payload: sagaPayload,
}: any) {
    const { callback } = sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload: sagaPayload, canExcludeTasks: true, parseCustomFields: true });

    const payload = {
        TaskManagementFilter: { ...commonTaskFilter }
    };

    try {
        yield call(
            [API, 'del'],
            API_NAME,
            '/task/viewing',
            {
                body: payload,
            }
        );

        if (callback) {
            const response = {
                IsSuccess: true
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function for getting the tasks viewer detail for user.
 * @param param0
 */
function* handleGetTaskViewerDetailForUserRequest({ payload: sagaPayload }: any) {
    let { refetchCount, ...payload } = sagaPayload;
    refetchCount = refetchCount || 0;
    const errorMessage = 'Error fetching task viewer detail. Please try again later.';

    try {
        // To call async functions, use redux-saga's `call()`.
        const commonTaskFilter = preparePayloadForTaskFilter({ payload, canExcludeTasks: true, parseCustomFields: false });
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TASK_VIEWER_DETAIL_FOR_USER, {
                ...commonTaskFilter
            })
        );

        const response = get(res.data, 'GetTaskViewerDetailForUser');
        const User = get(response, 'User');
        refetchCount = 0;
        yield put(getTaskViewerDetailForUserSuccessAction(User));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getTaskViewerDetailForUserRequestAction({ ...payload, refetchCount }));
        } else {
            yield put(getTaskViewerDetailForUserErrorAction([errorMessage]));
        }
    }
}

function* handleGetTaskViewerDetailForUserOrganisationRequest({ payload: sagaPayload }: any) {
    let { refetchCount, region, companyId, ...payload } = sagaPayload;
    refetchCount = refetchCount || 0;
    const errorMessage = 'Error fetching task viewer detail. Please try again later.';

    try {
        // To call async functions, use redux-saga's `call()`.
        let apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        const commonTaskFilter = preparePayloadForTaskFilter({ payload, canExcludeTasks: true, parseCustomFields: false });
        const variables = {
            CompanyId: companyId,
            ...commonTaskFilter
            
        };

        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_TASK_VIEWER_DETAIL_FOR_USER_ORGANISATION,
            variables
        );

        const response = get(res.data, 'GetTaskViewerDetailForUserOrganisation');
        const User = get(response, 'User');
        refetchCount = 0;
        yield put(getTaskViewerDetailForUserSuccessAction(User));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getTaskViewerDetailForUserOrganisationRequestAction({ ...payload, refetchCount }));
        } else {
            yield put(getTaskViewerDetailForUserErrorAction([errorMessage]));
        }
    }
}


/**
 * Function for getting the tasks custom fields for a company.
 * @param param0
 */
function* handleGetTaskCustomerCustomFieldsForCompanyRequest({ payload }: any) {
    /*const errorMessage = '';*/
    const { 
            CompanyId, 
            callback
        } = payload;
    try {
        //const errorMessage =
        //'Error fetching custom field values. Please try again later.';
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TASK_CUSTOMER_CUSTOM_FIELDS_FOR_COMPANY, {
                CompanyId: CompanyId,
            })
        );

        const CustomFieldValues  = get(res.data, 'GetTaskCustomerCustomFieldsForCompany');

        if (callback) {
            CustomFieldValues.IsSuccess = true;
            callback(CustomFieldValues);
        }

    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for getting the tasks custom fields for a company.
 * @param param0
 */
function* handleGetTaskInvoiceCustomFieldsForCompanyRequest({ payload }: any) {
    const errorMessage = '';
    const { 
            CompanyId, 
            callback
        } = payload;
    try {
        const errorMessage =
        'Error fetching custom field values. Please try again later.';
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TASK_INVOICE_CUSTOM_FIELDS_FOR_COMPANY, {
                CompanyId: CompanyId,
            })
        );

        const CustomFieldValues  = get(res.data, 'GetTaskInvoiceCustomFieldsForCompany');

        if (callback) {
            CustomFieldValues.IsSuccess = true;
            callback(CustomFieldValues);
        }

    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

function* handleTaskActionPhoneCallTaskRequest({ payload: sagaPayload }: any) {
    const { callback, Comment, PromiseToPayDate, ...payload } = sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload, canExcludeTasks: true, parseCustomFields: true });

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/phone-call',
            {
                body: {
                    TaskManagementFilter: { ...commonTaskFilter },
                    Comment,
                    PromiseToPayDate,
                    Trigger: 'Action Phone Call'
                },
            }
        );

        if (callback) {
            const response = { IsSuccess: true };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}
function* handleTaskActionOrganisationPhoneCallTaskRequest({ payload: sagaPayload }: any) {
    const { callback, Comment, PromiseToPayDate, ...payload } = sagaPayload;
    const commonTaskFilter = preparePayloadForTaskFilter({ payload, canExcludeTasks: true, parseCustomFields: true });

    let apiUrl: string | undefined = yield select(
        getOrganisationActionUrl
    );

    try {
        let res : DynamicObject = {};
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            res = yield call(
                customPostData,
                `${apiUrl}/task/action/organisation/phone-call`,
                {
                    TaskManagementFilter: { ...commonTaskFilter },
                    Comment,
                    PromiseToPayDate,
                    Trigger: 'Action Phone Call'
                }, 
                { Authorization: accessToken }
            );
        } else {
            res = yield call(
                [API, 'post'],
                API_NAME,
                '/task/action/organisation/phone-call',
                {
                    body: {
                        TaskManagementFilter: { ...commonTaskFilter },
                        Comment,
                        PromiseToPayDate,
                        Trigger: 'Action Phone Call'
                    },
                }
            );
        }

        if (callback) {
            const response = { IsSuccess: true };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

function* handleGetActionedPhoneCallDataRequest({ payload }: any) {
    const errorMessage = `Error fetching actioned phone call task details. Please try again later.`;
    const { BatchId, CompanyId, IsUsingCloudImportType, UsingCustomerWorkflow } = payload;

    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(
                queries.GET_ACTIONED_PHONE_CALL_TASK_DETAILS,
                {
                    BatchId,
                    CompanyId,
                    IsUsingCloudImportType,
                    UsingInvoiceWorkflow: !UsingCustomerWorkflow
                }
            )
        );

        const record = get(res.data, 'GetTaskHistoryActionedPhoneCallDetails');
        if (record) {
            yield put(getTaskHistoryDataSuccessAction({ record }));
        } else {
            yield put(getTaskHistoryDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
        yield put(getTaskHistoryDataErrorAction([errorMessage]));
    }
}

function* handleGetTaskWorkflowsRequest({ payload }: any) {
    const errorMessage = 'Error fetching task workflows. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_TASK_WORKFLOWS_FOR_COMPANY, {
                WorkflowFilters: payload
            })
        );

        const Workflows = get(res.data, 'GetTaskWorkflowsForCompany');
        if (Workflows) {
            refetchCount = 0;
            yield put(getTaskWorkflowsSuccessAction(Workflows));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getTaskWorkflowsRequestAction(payload));
        } else {
            yield put(getTaskWorkflowsErrorAction([errorMessage]));
        }
    }
}

function TasksFetchedForOrganisationReceived(params: { pageSize: number, currentPage: number }) {
    return function (data: TasksFetchedData) {
        store.dispatch(tasksFetchedForOrganisationAction({ data, ...params }));
    };
}

function* watchTasksFetchedForOrganisationReceived() {
    yield takeLatest(TasksActionTypes.TASKS_FETCHED_FOR_ORGANISATION_RECEIVED, function* (action: any) {
        const { data, pageSize, currentPage } = action.payload;
        const tasks: Task[] = data.OnTasksFetchedForOrganisation.Tasks;

        const responsePayload = {
            data: tasks,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage:
                    !(tasks.length < pageSize) &&
                    !(pageSize < TASKS_PAGE.pageSize),
            },
        };

        refetchCount = 0;
        yield put(getTasksSuccessAction(responsePayload));
    });
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetTasksRequest() {
    yield takeLatest(TasksActionTypes.GET_TASKS_REQUEST, handleGetTasksRequest);
}

function* watchGetTasksForOrganisationRequest() {
    yield takeLatest(TasksActionTypes.GET_TASKS_FOR_ORGANISATION_REQUEST, handleGetTasksForOrganisationRequest);
}

function* watchGetTaskWorkflowsRequest() {
    yield takeLatest(TasksActionTypes.GET_TASK_WORKFLOWS_REQUEST, handleGetTaskWorkflowsRequest);
}

function* watchCheckTasksReadyStateRequest() {
    yield takeLatest(
        TasksActionTypes.CHECK_TASKS_READY_STATE,
        handleCheckTasksReadyStateRequest
    );
}

function* watchNotifyCustomersRequest() {
    yield takeLatest(
        TasksActionTypes.NOTIFY_CUSTOMERS_REQUEST,
        handleNotifyCustomersRequest
    );
}

function* watchNotifyOrganisationCustomersRequest() {
    yield takeLatest(
        TasksActionTypes.NOTIFY_ORGANISATION_CUSTOMERS_REQUEST,
        handleNotifyOrganisationCustomersRequest
    );
}

function* watchGetTasksActionFilterOptionsRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASKS_ACTION_FILTER_OPTIONS_REQUEST,
        handleGetTasksActionFilterOptionsRequest
    );
}

function* watchGetTaskDataRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_DATA_REQUEST,
        handleGetTaskDataRequest
    );
}

function* watchGetTaskDataForOrganisationRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_DATA_FOR_ORGANISATION_REQUEST,
        handleGetTaskDataForOrganisationRequest
    );
}

function* watchSetSelectedTaskIdRequest() {
    yield takeLatest(
        TasksActionTypes.SET_TASK_SELECTED_ID_REQUEST,
        handleSetSelectedTaskIdRequest
    );
}

function* watchGetTaskTicketInvoicesRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_TICKET_INVOICES_REQUEST,
        handleGetTaskTicketInvoicesRequest
    );
}

function* watchGetTaskPaymentPlanScheduleRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_PAYMENT_PLAN_SCHEDULE_REQUEST,
        handleGetTaskPaymentPlanScheduleRequest
    );
}

/**
 * Task History watchers
 */
function* watchGetTaskHistoryRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_REQUEST,
        handleGetTaskHistoryRequest
    );
}

function* watchGetTaskHistoryDataRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_DATA_REQUEST,
        handleGetTaskHistoryDataRequest
    );
}

function* watchSetSelectedTaskHistoryIdRequest() {
    yield takeLatest(
        TasksActionTypes.SET_TASK_HISTORY_SELECTED_ID_REQUEST,
        handleSetSelectedTaskHistoryIdRequest
    );
}

// Task History notifications
function* watchSetSelectedTaskHistoryNotificationIdRequest() {
    yield takeLatest(
        TasksActionTypes.SET_TASK_HISTORY_NOTIFICATIONS_SELECTED_ID_REQUEST,
        handleSetSelectedTaskHistoryNotificationIdRequest
    );
}

function* watchGetTaskHistoryNotificationsRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_NOTIFICATIONS_REQUEST,
        handleGetTaskHistoryNotificationsRequest
    );
}

function* watchGetTaskHistoryDeliveryReportRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_DELIVERY_REPORT_REQUEST,
        handleGetTaskHistoryDeliveryReportRequest
    );
}

function* watchGetTaskHistoryNotificationDataRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_DATA_REQUEST,
        handleGetTaskHistoryNotificationDataRequest
    );
}

function* watchGetOrganisationTaskHistoryNotificationDataRequest() {
    yield takeLatest(
        TasksActionTypes.GET_ORGANISATION_TASK_HISTORY_NOTIFICATION_DATA_REQUEST,
        handleGetOrganisationTaskHistoryNotificationDataRequest
    );
}

// Notification Invoices
function* watchGetTaskHistoryNotificationInvoicesRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_INVOICES_REQUEST,
        handleGetTaskHistoryNotificationInvoicesRequest
    );
}

// Notification Tasks
function* watchGetTaskHistoryNotificationTasksRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_TASKS_REQUEST,
        handleGetTaskHistoryNotificationTasksRequest
    );
}

// Notification job status
function* watchGetTaskHistoryQueuedTasksCountRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_QUEUED_TASKS_COUNT_REQUEST,
        handleGetTaskHistoryQueuedTasksCountRequest
    );
}

function* watchGetTaskHistoryBatchStatusRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_BATCH_STATUS_REQUEST,
        handleGetTaskHistoryBatchStatusRequest
    );
}

function* watchDownloadMergedPdfRequest() {
    yield takeLatest(
        TasksActionTypes.DOWNLOAD_MERGED_PDF_REQUEST,
        handleDownloadMergedPdfRequest
    );
}

function* watchDownloadMergedPdfStatusRequest() {
    yield takeLatest(
        TasksActionTypes.DOWNLOAD_MERGED_PDF_STATUS_REQUEST,
        handleDownloadMergedPdfStatusRequest
    );
}

// Task history actioned tasks data
function* watchGetTaskHistoryActionedTasksDataRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_ACTIONED_TASKS_DATA_REQUEST,
        handleGetTaskHistoryActionedTasksDataRequest
    );
}

// Task history changed tasks
function* watchGetTaskHistoryActionedTasksRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_ACTIONED_TASKS_REQUEST,
        handleGetTaskHistoryChangedTasksRequest
    );
}


// Task history changed tasks data
function* watchGetTaskHistoryChangedTasksDataRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_CHANGED_TASKS_DATA_REQUEST,
        handleGetTaskHistoryChangedTasksDataRequest
    );
}

// Task history changed tasks
function* watchGetTaskHistoryChangedTasksRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_CHANGED_TASKS_REQUEST,
        handleGetTaskHistoryChangedTasksRequest
    );
}

// Task history tasks failed
function* watchGetTaskHistoryTasksFailedRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_TASKS_FAILED_REQUEST,
        handleGetTaskHistoryTasksFailedRequest
    );
}

// Edit tasks
function* watchBulkSaveTaskDetailsUpdateRequest() {
    yield takeLatest(
        TasksActionTypes.BULK_SAVE_TASK_DETAILS_REQUEST,
        handleBulkSaveTaskDetailsUpdateRequest
    );
}

// Edit tasks
function* watchOrganisationBulkSaveTaskDetailsUpdateRequest() {
    yield takeLatest(
        TasksActionTypes.ORGANISATION_BULK_SAVE_TASK_DETAILS_REQUEST,
        handleOrganisationBulkSaveTaskDetailsUpdateRequest
    );
}

function* watchGetTaskEditPropertiesRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_EDIT_PROPERTIES_REQUEST,
        handleGetTaskEditPropertiesRequest
    );
}

function* watchGetWorkflowStepOptionsRequest() {
    yield takeLatest(
        TasksActionTypes.GET_WORKFLOW_STEP_OPTIONS_REQUEST,
        handleGetWorkflowStepOptionsRequest
    );
}

function* watchGetActionedTicketDetailsRequest() {
    yield takeLatest(
        TasksActionTypes.GET_ACTIONED_TICKET_DETAILS_REQUEST,
        handleGetActionedTicketDetailsRequest
    );
}

function* watchGetActionedPaymentPlanDetailsRequest() {
    yield takeLatest(
        TasksActionTypes.GET_ACTIONED_PAYMENT_PLAN_DETAILS_REQUEST,
        handleGetActionedPaymentPlanDetailsRequest
    );
}

function* watchGetActionedPaymentDetailsRequest() {
    yield takeLatest(
        TasksActionTypes.GET_ACTIONED_PAYMENT_DETAILS_REQUEST,
        handleGetActionedPaymentDetailsRequest
    );
}

// Collections
function* watchGetTaskHistoryCollectionsDataRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_COLLECTIONS_DATA_REQUEST,
        handleGetTaskHistoryCollectionsDataRequest
    );
}

function* watchGetTaskHistoryCollectionTasksRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_COLLECTION_TASKS_REQUEST,
        handleGetTaskHistoryCollectionTasksRequest
    );
}

function* watchSetSelectedTaskHistoryCollectionsTaskIdRequest() {
    yield takeLatest(
        TasksActionTypes.SET_TASK_HISTORY_COLLECTIONS_TASK_SELECTED_ID_REQUEST,
        handleSetSelectedTaskHistoryCollectionsTaskIdRequest
    );
}

function* watchGetTaskHistoryCollectionsTaskDataRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_HISTORY_COLLECTIONS_TASK_DATA_REQUEST,
        handleGetTaskHistoryCollectionsTaskDataRequest
    );
}

function* watchEscalateToCollections() {
    yield takeLatest(
        TasksActionTypes.ESCALATE_TO_COLLECTIONS,
        handleEscalateToCollections
    );
}

function* watchOrganisationEscalateToCollections() {
    yield takeLatest(
        TasksActionTypes.ORGANISATION_ESCALATE_TO_COLLECTIONS,
        handleOrganisationEscalateToCollections
    );
}

function* watchGetTableCompactViewRequest() {
    yield takeLatest(
        TasksActionTypes.TASKS_GET_TABLE_COMPACT_VIEW_REQUEST,
        handleGetTableCompactViewRequest
    );
}

function* watchGetTableCompactViewSuccess() {
    yield takeLatest(
        TasksActionTypes.TASKS_GET_TABLE_COMPACT_VIEW_SUCCESS,
        handleGetTableCompactViewSuccess
    );
}

function* watchSetTableCompactViewRequest() {
    yield takeLatest(
        TasksActionTypes.TASKS_SET_TABLE_COMPACT_VIEW_REQUEST,
        handleSetTableCompactViewRequest
    );
}

function* watchSetTableCompactViewSuccess() {
    yield takeLatest(
        TasksActionTypes.TASKS_SET_TABLE_COMPACT_VIEW_SUCCESS,
        handleSetTableCompactViewSuccess
    );
}

function* watchProcessTaskCompactViewReduxSetting() {
    yield takeLatest(
        TasksActionTypes.TASKS_PROCESS_COMPACT_VIEW_REDUX_SETTING,
        handleProcessTaskCompactViewReduxSetting
    );
}

function* watchTaskAddCommentRequest() {
    yield takeLatest(
        TasksActionTypes.TASK_ADD_COMMENT_REQUEST,
        handleTaskAddCommentRequest
    );
}
function* watchActionPaymentVerificationTaskRequest() {
    yield takeLatest(
        TasksActionTypes.TASK_ACTION_PAYMENT_VERIFICATION_TASK_REQUEST,
        handleActionPaymentVerificationTaskRequest
    );
}
function* watchActionOrganisationPaymentVerificationTaskRequest() {
    yield takeLatest(
        TasksActionTypes.TASK_ACTION_ORGANISATION_PAYMENT_VERIFICATION_TASK_REQUEST,
        handleActionOrganisationPaymentVerificationTaskRequest
    );
}
function* watchActionPaymentRALinkTaskRequest() {
    yield takeLatest(
        TasksActionTypes.TASK_ACTION_PAYMENT_RA_LINK_TASK_REQUEST,
        handleActionPaymentRALinkTaskRequest
    );
}
function* watchActionOrganisationPaymentRALinkTaskRequest() {
    yield takeLatest(
        TasksActionTypes.TASK_ACTION_ORGANISATION_PAYMENT_RA_LINK_TASK_REQUEST,
        handleActionOrganisationPaymentRALinkTaskRequest
    );
}

function* watchActionRequestRATaskRequest() {
    yield takeLatest(
        TasksActionTypes.TASK_ACTION_REQUEST_RA_TASK_REQUEST,
        handleActionRequestRATaskRequest
    );
}

function* watchActionOrganisationRequestRATaskRequest() {
    yield takeLatest(
        TasksActionTypes.TASK_ACTION_ORGANISATION_REQUEST_RA_TASK_REQUEST,
        handleActionOrganisationRequestRATaskRequest
    );
}

function* watchGetTriggersForWorkflowRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TRIGGERS_FOR_WORKFLOW,
        handleGetTriggersForWorkflowRequest
    );
}

function* watchActionManualAllocatePayment() {
    yield takeLatest(
        TasksActionTypes.MANUALLY_ALLOCATE_PAYMENT_REQUEST,
        handleActionManualAllocatePaymentRequest
    );
}

function* watchOrganisationActionManualAllocatePayment() {
    yield takeLatest(
        TasksActionTypes.ORGANISATION_MANUALLY_ALLOCATE_PAYMENT_REQUEST,
        handleActionOrganisationManualAllocatePaymentRequest
    );
}

function* watchActionManualAllocatePaymentWithOverpayment() {
    yield takeLatest(
        TasksActionTypes.MANUALLY_ALLOCATE_PAYMENT_WITH_OVERPAYMENT_REQUEST,
        handleActionManualAllocatePaymentWithOverpaymentRequest
    );
}

function* watchGetRemittanceAdvicesRequest() {
    yield takeLatest(
        TasksActionTypes.GET_REMITTANCE_ADVICES_REQUEST,
        handleGetRemittanceAdvicesRequest
    );
}

function* watchGetPaymentBehaviourInsightsDetailsRequest() {
    yield takeLatest(
        TasksActionTypes.GET_ACTIONED_PAYMENT_BEHAVIOUR_INSIGHT_DETAILS_REQUEST,
        handleGetActionedPaymentBehaviourInsightDetailsRequest
    )
}

function* watchTaskPaymentBehaviorInsightRequest() {
    yield takeLatest(
        TasksActionTypes.TASK_PAYMENT_BEHAVIOR_INSIGHTS_REQUEST,
        handleTaskPaymentBehaviorInsightRequest
        );
}
function* watchTaskOrganisationPaymentBehaviorInsightRequest() {
    yield takeLatest(
        TasksActionTypes.TASK_ORGANISATION_PAYMENT_BEHAVIOR_INSIGHTS_REQUEST,
        handleTaskOrganisationPaymentBehaviorInsightRequest
        );
}
function* watchAddTaskViewingsRequest() {
    yield takeLatest(
        TasksActionTypes.ADD_TASK_VIEWINGS_REQUEST,
        handleAddTaskViewingsRequest
    );
}

function* watchDeleteTaskViewingsRequest() {
    yield takeLatest(
        TasksActionTypes.DELETE_TASK_VIEWINGS_REQUEST,
        handleDeleteTaskViewingsRequest
    );
}

function* watchGetTaskViewerDetailForUserRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_VIEWER_DETAIL_FOR_USER_REQUEST,
        handleGetTaskViewerDetailForUserRequest
    );
}

function* watchGetTaskCustomerCustomFieldsForCompanyRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_CUSTOMER_CUSTOM_FIELDS_FOR_COMPANY_REQUEST,
        handleGetTaskCustomerCustomFieldsForCompanyRequest
    );
}

function* watchGetTaskInvoiceCustomFieldsForCompanyRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_INVOICE_CUSTOM_FIELDS_FOR_COMPANY_REQUEST,
        handleGetTaskInvoiceCustomFieldsForCompanyRequest
    );
}

function* watchTaskActionPhoneCallTaskRequest() {
    yield takeLatest(
        TasksActionTypes.ACTION_PHONE_CALL_REQUEST,
        handleTaskActionPhoneCallTaskRequest
    );
}

function* watchTaskActionOrganisationPhoneCallTaskRequest() {
    yield takeLatest(
        TasksActionTypes.ACTION_ORGANISATION_PHONE_CALL_REQUEST,
        handleTaskActionOrganisationPhoneCallTaskRequest
    );
}

function* watchGetActionedPhoneCallDataRequest() {
    yield takeLatest(
        TasksActionTypes.GET_ACTIONED_PHONE_CALL_DATA_REQUEST,
        handleGetActionedPhoneCallDataRequest
    );
}

function* watchGetOrganisationTasksHistoryRequest() {
    yield takeLatest(
        TasksActionTypes.GET_ORGANISATION_TASKS_HISTORY_REQUEST,
        handleGetOrganisationTasksHistoryRequest
    )
}

function* watchGetTaskViewerDetailForUserOrganisationRequest() {
    yield takeLatest(
        TasksActionTypes.GET_TASK_VIEWER_DETAIL_FOR_USER_ORGANISATION_REQUEST,
        handleGetTaskViewerDetailForUserOrganisationRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* tasksSaga() {
    yield all([
        fork(watchGetTasksRequest),
        fork(watchGetTasksForOrganisationRequest),
        fork(watchCheckTasksReadyStateRequest),
        fork(watchNotifyCustomersRequest),
        fork(watchNotifyOrganisationCustomersRequest),
        fork(watchGetTasksActionFilterOptionsRequest),
        fork(watchGetTaskDataRequest),
        fork(watchGetTaskDataForOrganisationRequest),
        fork(watchSetSelectedTaskIdRequest),
        fork(watchGetTaskTicketInvoicesRequest),
        fork(watchGetTaskPaymentPlanScheduleRequest),
        fork(watchGetTaskHistoryRequest),
        fork(watchGetTaskHistoryDataRequest),
        fork(watchSetSelectedTaskHistoryIdRequest),
        fork(watchSetSelectedTaskHistoryNotificationIdRequest),
        fork(watchGetTaskHistoryNotificationsRequest),
        fork(watchGetTaskHistoryDeliveryReportRequest),
        fork(watchGetTaskHistoryNotificationDataRequest),
        fork(watchGetTaskHistoryNotificationInvoicesRequest),
        fork(watchGetTaskHistoryNotificationTasksRequest),
        fork(watchGetTaskHistoryQueuedTasksCountRequest),
        fork(watchGetTaskHistoryBatchStatusRequest),
        fork(watchDownloadMergedPdfRequest),
        fork(watchDownloadMergedPdfStatusRequest),
        fork(watchGetTaskHistoryChangedTasksDataRequest),
        fork(watchGetTaskHistoryActionedTasksDataRequest),
        fork(watchGetTaskHistoryActionedTasksRequest),
        fork(watchGetTaskHistoryChangedTasksRequest),
        fork(watchGetTaskHistoryTasksFailedRequest),
        fork(watchBulkSaveTaskDetailsUpdateRequest),
        fork(watchOrganisationBulkSaveTaskDetailsUpdateRequest),
        fork(watchGetTaskEditPropertiesRequest),
        fork(watchGetWorkflowStepOptionsRequest),
        fork(watchGetActionedTicketDetailsRequest),
        fork(watchGetActionedPaymentPlanDetailsRequest),
        fork(watchGetTaskHistoryCollectionsDataRequest),
        fork(watchGetTaskHistoryCollectionTasksRequest),
        fork(watchSetSelectedTaskHistoryCollectionsTaskIdRequest),
        fork(watchGetTaskHistoryCollectionsTaskDataRequest),
        fork(watchEscalateToCollections),
        fork(watchOrganisationEscalateToCollections),
        fork(watchGetTableCompactViewRequest),
        fork(watchGetTableCompactViewSuccess),
        fork(watchSetTableCompactViewRequest),
        fork(watchSetTableCompactViewSuccess),
        fork(watchProcessTaskCompactViewReduxSetting),
        fork(watchTaskAddCommentRequest),
        fork(watchActionManualAllocatePayment),
        fork(watchOrganisationActionManualAllocatePayment),
        fork(watchActionManualAllocatePaymentWithOverpayment),
        fork(watchActionPaymentVerificationTaskRequest),
        fork(watchActionOrganisationPaymentVerificationTaskRequest),
        fork(watchActionPaymentRALinkTaskRequest),
        fork(watchGetActionedPaymentDetailsRequest),
        fork(watchGetTriggersForWorkflowRequest),
        fork(watchGetRemittanceAdvicesRequest),
        fork(watchGetPaymentBehaviourInsightsDetailsRequest),
        fork(watchTaskPaymentBehaviorInsightRequest),
        fork(watchTaskOrganisationPaymentBehaviorInsightRequest),
        fork(watchActionRequestRATaskRequest),
        fork(watchAddTaskViewingsRequest),
        fork(watchDeleteTaskViewingsRequest),
        fork(watchGetTaskViewerDetailForUserRequest),
        fork(watchTaskActionPhoneCallTaskRequest),
        fork(watchGetActionedPhoneCallDataRequest),
        fork(watchGetTaskCustomerCustomFieldsForCompanyRequest),
        fork(watchGetTaskInvoiceCustomFieldsForCompanyRequest),
        fork(watchGetTaskViewerDetailForUserOrganisationRequest),
        fork(watchGetTaskWorkflowsRequest),
        fork(watchTaskActionPhoneCallTaskRequest),
        fork(watchTaskActionOrganisationPhoneCallTaskRequest),
        fork(watchGetActionedPhoneCallDataRequest),
        fork(watchTasksFetchedForOrganisationReceived),
        fork(watchActionOrganisationPaymentRALinkTaskRequest),
        fork(watchActionOrganisationRequestRATaskRequest),
        fork(watchGetOrganisationTasksHistoryRequest),
        fork(watchGetOrganisationTaskHistoryNotificationDataRequest)
    ]);
}

export default tasksSaga;
