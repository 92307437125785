/**
 * File for the `Customers average payment timeframe` widget form.
 */

import { Col, Divider, Form, Input, InputNumber, Row, Select, Switch, DatePicker, Checkbox } from 'antd';
import { filter, get, includes, isUndefined, map, toLower, isObject, values, capitalize, forEach, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import { getCustomerUILabel } from '../../store/customers/sagas';
import {
    populatePopoverContainer,
    replaceInstancesOfCustomerString,
    getTranslatedText
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAccountingSystemHandler } from '../common/AccountingSystemHandler';
import { customFieldIndicator, invoiceFieldIndicator } from '../common/FilterBar';
import InputAutoCompleteWithButtonDashboard from '../common/InputAutoCompleteWithButtonDashboard';
import { dateFormatDDMMYYYYSlash, dateFormatYYYYMMDDDash, } from '../../constants/dateFormats';
import SelectReadonly from '../FormComponents/SelectReadonly';
import OrganisationWidgetCommonFilters from './organisation/OrganisationWidgetCommonFilters';
import { customersSettlementFilters, dateSelectOptions, customersSettlementSortByOptions } from '../../constants/invoicesSortAndFilters';
import { ApplicationState } from '../../store';
import { AppCommonState } from '../../store/common/types';
import { chartWidgetDisplayNoPieTypes } from '../../constants/dashboards';

const { Item: FormItem } = Form;
const { Option } = Select;

interface IProps {
    widgetDetails: DynamicObject;
    getFieldDecorator: any;
    drawerRef: any;
    formDisabled?: boolean;
    customFieldsFilterList: DynamicObject[];
    isUsingCloudImportType: boolean;
    isOrgView?: boolean;
    setFieldsValue: any;
    getFieldValue: any;
    organisationCurrenciesAll?: DynamicObject[];
}

export const displayColumnIndicator = 'DisplayColumn---';
export const showCustomFieldsIndicator = 'ShowCustomFields--';

export const displayRowCountOptions = [5, 10, 15, 20];

const CustomersSettlementWidgetFields: React.FC<IProps> = ({
    drawerRef,
    widgetDetails,
    formDisabled,
    getFieldDecorator,
    customFieldsFilterList,
    isUsingCloudImportType,
    isOrgView,
    getFieldValue,
    setFieldsValue,
    organisationCurrenciesAll,
}: IProps) => {
    const customerLabel = useSelector(getCustomerUILabel);

    const appState: AppCommonState = useSelector(
        (state: ApplicationState) => state.app
    );

    const currencyObj = Intl.NumberFormat(appState.locale, {
        style: 'currency',
        currency: appState.currencyCode,
    }).formatToParts(1111);

    const currencySign = get(
        filter(currencyObj, ['type', 'currency']),
        `0.value`
    );

    const [searchFilters, setSearchFilters] = useState<DynamicObject>({});

    /**
    * Function that updates the search input filters.
    * @param filterName - name of filter item
    * @param value - string value entered
    */
    const changeSearchFilter = (filterName: string, value: any) => {
        updateSearchFiltersObject({
            [filterName]: value,
        });
    };

    /**
     * Common function for updating the serachFilters object from state.
     * @param searchFiltersObject
     */
    const updateSearchFiltersObject = (searchFiltersObject: DynamicObject) => {
        setSearchFilters({
            ...searchFilters,
            ...searchFiltersObject,
        });
    };


    /**
    * Function that will be triggered when selecting a date range.
    * @param rangeType  - From and To - based on UI mockup
    * @param filterName - name of filter
    */
    const selectDateRange = (rangeType: 'From' | 'To', filterName: string) => {
        return (dateValue: any) => {
            const searchFilterParent = get(searchFilters, filterName);
            const newSearchFilterParent = {
                ...searchFilterParent,
                [rangeType]: dateValue,
            };
            updateSearchFiltersObject({
                [filterName]: newSearchFilterParent,
            });
        };
    };

    /**
     * Function responsible for setting the days range filter values.
     * @param rangeType - Last, Next
     * @param filterName - name of filter
     * @param daysType  - From, To, or not defined
     */
    const selectDaysRange = (
        rangeType: 'Last' | 'Next',
        filterName: string,
        daysType: 'From' | 'To' | undefined = undefined
    ) => {
        return (daysValue: number | undefined) => {
            if (daysType) {
                const searchFilterParent = get(searchFilters, filterName);
                if (get(searchFilterParent, daysType)) {
                    if (rangeType === 'Last') {
                        delete searchFilterParent[daysType].Next;
                    } else {
                        delete searchFilterParent[daysType].Last;
                    }
                }
                const newSearchFilterParent = {
                    ...searchFilterParent,
                    [daysType]: {
                        [rangeType]: daysValue,
                    },
                };

                updateSearchFiltersObject({
                    [filterName]: newSearchFilterParent,
                });
            } else {
                const searchFilterParent = get(searchFilters, filterName);
                if (rangeType === 'Last') {
                    delete searchFilterParent.Next;
                } else {
                    delete searchFilterParent.Last;
                }
                const newSearchFilterParent = {
                    ...searchFilterParent,
                    [rangeType]: daysValue,
                };

                updateSearchFiltersObject({
                    [filterName]: newSearchFilterParent,
                });
            }
        };
    };

    /**
     * Function for handling the select onChange event.
     * @param selectValue - selected value on dropdown
     * @param filter - name of the filter
     * @param fieldKey - key for the component
     * @param stateName - state name of the filter
     */
    const handleSelectOnChange = (
        selectValue: string,
        filter: any,
        fieldKey: string,
        stateName: string
    ) => {
        const newFilterValue: any = {
            value: selectValue,
        };
        if (
            selectValue ===
            dateSelectOptions.CUSTOM_DAYS_RANGE
        ) {
            const customType =
                filter.filterOptionsCustomTypes[selectValue];

            if (customType === 'all-days-range-from-to') {
                if (get(widgetDetails, `${fieldKey}${"--RangeTypeFrom"}`) === "Last") {
                    newFilterValue.From = {
                        Last: get(widgetDetails, `${fieldKey}${"--Last--From"}`)
                    }
                }
                else {
                    newFilterValue.From = {
                        Next: get(widgetDetails, `${fieldKey}${"--Next--From"}`)
                    }
                }

                if (get(widgetDetails, `${fieldKey}${"--RangeTypeTo"}`) === "Last") {
                    newFilterValue.To = {
                        Last: get(widgetDetails, `${fieldKey}${"--Last--To"}`)
                    }
                }
                else {
                    newFilterValue.To = {
                        Next: get(widgetDetails, `${fieldKey}${"--Next--To"}`)
                    }
                }
            }
            else if (customType === 'all-days-range-from-to-last') {
                newFilterValue.From = {
                    Last: get(widgetDetails, `${fieldKey}${"--Last--From"}`)
                };
                newFilterValue.To = {
                    Last: get(widgetDetails, `${fieldKey}${"--Last--To"}`)
                };
            }
            else if (customType === 'last-days-range') {
                newFilterValue.Last = get(widgetDetails, `${fieldKey}${"--Last"}`);
            }
            else if (customType === 'all-days-range') {
                if (get(widgetDetails, `${fieldKey}${"--RangeType"}`) === "Last") {
                    newFilterValue.Last = get(widgetDetails, `${fieldKey}${"--Last"}`);
                }
                else {
                    newFilterValue.Next = get(widgetDetails, `${fieldKey}${"--Next"}`);
                }
            }
            else {
                newFilterValue.Last = 1;
            }
        }

        changeSearchFilter(
            stateName,
            newFilterValue
        );
    }

    /**
    * Function to populate the select/dropdown filter.
    * @param customType - nype of filter, date-range or etc
    * @param filterName - name of filter
    * @param filterFieldKey - key for the component
    * @param filterMaxDate - optional max date for filter, can be a moment object or string
    */
    const populateSelectCustomType = (
        customType: string,
        filterName: string,
        filterFieldKey: string,
        filterMaxDate?: any
    ) => {
        if (customType === 'date-range') {
            const fromDateFieldKey = `${filterFieldKey}${"--From"}`
            const toDateFieldKey = `${filterFieldKey}${"--To"}`
            let disabledDate;
            if (filterMaxDate) {
                disabledDate = (dateValue: any) => {
                    return moment(
                        moment(dateValue).format(dateFormatYYYYMMDDDash)
                    ).isAfter(
                        moment(
                            moment(filterMaxDate).format(dateFormatYYYYMMDDDash)
                        )
                    );
                };
            }
            return (
                <Col span={12} key={filterFieldKey}>
                    <FormItem label={getTranslatedText("From")}>
                        {getFieldDecorator(fromDateFieldKey, {
                            initialValue: !isEmpty(get(widgetDetails, fromDateFieldKey)) ?
                                moment(get(widgetDetails, fromDateFieldKey)) :
                                null,
                        })(
                            <DatePicker
                                className="dashboard-datepicker"
                                format={dateFormatDDMMYYYYSlash}
                                disabledDate={disabledDate}
                                placeholder={getTranslatedText("Start Date")}
                                onChange={selectDateRange('From', filterName)}
                                getCalendarContainer={populatePopoverContainer(
                                    drawerRef
                                )}
                                allowClear={true}
                            />
                        )}
                    </FormItem>
                    <FormItem label={getTranslatedText("To")}>
                        {getFieldDecorator(toDateFieldKey, {
                            initialValue: !isEmpty(get(widgetDetails, toDateFieldKey)) ?
                                moment(get(widgetDetails, toDateFieldKey)) :
                                null,
                        })(
                            <DatePicker
                                className="dashboard-datepicker"
                                format={dateFormatDDMMYYYYSlash}
                                disabledDate={disabledDate}
                                placeholder={getTranslatedText("End Date")}
                                onChange={selectDateRange('To', filterName)}
                                getCalendarContainer={populatePopoverContainer(
                                    drawerRef
                                )}
                                allowClear={true}
                            />
                        )}
                    </FormItem>
                </Col>
            );
        } else if (customType === 'all-days-range-from-to-last') {
            const daysRangeType = ['Last'];
            const rangeTypeSelectedFrom = 'Last';
            const rangeTypeSelectedTo = 'Last';

            const daysValueFrom = get(
                searchFilters,
                `${filterName}.From.${rangeTypeSelectedFrom}`
            );
            const daysValueTo = get(
                searchFilters,
                `${filterName}.To.${rangeTypeSelectedTo}`
            );

            return (
                <Col span={12} key={filterFieldKey}>
                    {map(['From', 'To'], (daysType: 'From' | 'To') => {
                        let typeLabel = 'Selected from';
                        let rangeTypeSelected: 'Last' = rangeTypeSelectedFrom;
                        let daysValue = daysValueFrom;

                        if (daysType === 'To') {
                            typeLabel = 'Go to';
                            rangeTypeSelected = rangeTypeSelectedTo;
                            daysValue = daysValueTo;
                        }

                        const daysTypeFieldKey = `${filterFieldKey}${"--Last--"}${daysType}`

                        return (
                            <div key={daysType}>
                                <span>{getTranslatedText(typeLabel)}</span><br />
                                <div className="dashboard-div">
                                    <FormItem>
                                        <SelectReadonly
                                            readOnly={formDisabled}
                                            onChange={(rangeType: 'Last') => {
                                                selectDaysRange(
                                                    rangeType,
                                                    filterName,
                                                    daysType
                                                )(1);
                                            }}
                                            value={rangeTypeSelected}
                                            placeholder={getTranslatedText("Type")}
                                            getPopupContainer={populatePopoverContainer(
                                                drawerRef
                                            )}
                                        >
                                            {map(
                                                daysRangeType,
                                                (rangeType: string) => (
                                                    <Option
                                                        key={rangeType}
                                                        value={rangeType}
                                                    >
                                                        {rangeType}
                                                    </Option>
                                                )
                                            )}
                                        </SelectReadonly>
                                    </FormItem>
                                    &nbsp;&nbsp;
                                    <FormItem>
                                        {getFieldDecorator(daysTypeFieldKey, {
                                            initialValue: daysValue
                                        })(
                                            <InputNumber
                                                placeholder={getTranslatedText("Count")}
                                                onChange={selectDaysRange(
                                                    rangeTypeSelected,
                                                    filterName,
                                                    daysType
                                                )}
                                                min={1}
                                            />
                                        )}
                                    </FormItem>
                                    &nbsp;&nbsp;
                                    <span>
                                        {getTranslatedText(`day${daysValue > 1 ? 's' : ''}`)}
                                    </span>
                                </div>
                            </div>
                        );
                    })
                    }
                </Col >
            );
        }
    };

    /**
     * Function for populating the invoice fields filters.
     * @param customFieldType
     */
    const populateCustomerFilters = () => {
        return customersSettlementFilters.map(
            (filter: DynamicObject) => {
                const fieldKey = `${invoiceFieldIndicator}${filter.filterStateName}`;
                const stateName = filter.filterStateName || filter.filterName;

                if (filter.filterElement === 'select-multiple-and-input-days') {
                    const daysValueFieldKey = `${fieldKey}${"--DaysValue"}`

                    const daysValue = !isUndefined(get(searchFilters, `${daysValueFieldKey}.From.Last`)) ?
                        get(searchFilters, `${daysValueFieldKey}.From.Last`) :
                        get(widgetDetails, daysValueFieldKey)

                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={getTranslatedText(filter.filterName)}>
                                {
                                    map(
                                        filter.filterOptions,
                                        (optionSelect: any, index: number) => {
                                            const valuesArray = get(
                                                values(optionSelect),
                                                0
                                            );
                                            const optionFieldKey = `${fieldKey}${"--"}${filter.filterOptions[index].filterOptionName}`
                                            return (
                                                <div key={index}>
                                                    <FormItem>
                                                        {getFieldDecorator(optionFieldKey, {
                                                            initialValue: get(
                                                                widgetDetails,
                                                                optionFieldKey
                                                            ),
                                                        })(
                                                            <SelectReadonly
                                                                allowClear={true}
                                                                placeholder={getTranslatedText(filter.filterOptions[index].filterPlaceholder)}
                                                                readOnly={formDisabled}
                                                                style={{ width: '100%' }}
                                                                getPopupContainer={populatePopoverContainer(
                                                                    drawerRef
                                                                )}
                                                            >
                                                                {map(
                                                                    valuesArray,
                                                                    (option: any) => {
                                                                        let value, label;
                                                                        if (
                                                                            isObject(option)
                                                                        ) {
                                                                            value = get(
                                                                                option,
                                                                                'value'
                                                                            );
                                                                            label = get(
                                                                                option,
                                                                                'label'
                                                                            );
                                                                        } else {
                                                                            value = option;
                                                                            label = option;
                                                                        }

                                                                        return (
                                                                            <Option
                                                                                key={label}
                                                                                value={
                                                                                    value
                                                                                }
                                                                            >
                                                                                {label}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                )}
                                                            </SelectReadonly>
                                                        )}
                                                    </FormItem>
                                                </div>
                                            )
                                        }
                                    )
                                }
                                <div className='dashboard-div'>
                                    <FormItem>
                                        {getFieldDecorator(daysValueFieldKey, {
                                            initialValue: get(
                                                widgetDetails,
                                                daysValueFieldKey
                                            )
                                        })(
                                            <InputNumber
                                                placeholder={getTranslatedText("Count")}
                                                onChange={selectDaysRange(
                                                    "Last",
                                                    daysValueFieldKey,
                                                    "From"
                                                )}
                                                step={1}
                                                readOnly={formDisabled}
                                            />
                                        )}
                                    </FormItem>
                                    &nbsp;&nbsp;
                                    <span>
                                        {getTranslatedText(`day${daysValue > 1 ? 's' : ''}`)}
                                    </span>
                                </div>
                            </FormItem>
                        </Col >
                    );
                }
                else if (filter.filterElement === 'radio-group') {
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={getTranslatedText(filter.filterName)}>
                                {getFieldDecorator(fieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        fieldKey
                                    ),
                                })(
                                    <SelectReadonly
                                        allowClear={true}
                                        placeholder={getTranslatedText(filter.filterPlaceholder)}
                                        readOnly={formDisabled}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    >
                                        {map(filter.filterOptions, ({ label, value }: any) => (
                                            <Option key={label} value={value}>
                                                {label}
                                            </Option>
                                        ))}
                                    </SelectReadonly>
                                )}
                            </FormItem>
                        </Col >
                    );
                }
                else if (filter.filterElement === 'select') {
                    const dateTypeFieldKey = `${fieldKey}${"--DateType"}`
                    const optionsObject: any = {};
                    forEach(filter.filterOptions, (option: string) => {
                        if (!optionsObject[option]) {
                            optionsObject[option] = option;
                        }
                    });

                    const selectCustomValueType = !isUndefined(searchFilters[stateName]) ?
                        get(searchFilters[stateName], 'value') :
                        get(widgetDetails, dateTypeFieldKey)

                    const selectCustomType = filter.filterOptionsCustomTypes[selectCustomValueType];

                    // Manually simulate onChange event on initial load
                    if ((selectCustomValueType === dateSelectOptions.CUSTOM_DATE_RANGE ||
                        selectCustomValueType === dateSelectOptions.CUSTOM_DAYS_RANGE) &&
                        isUndefined(searchFilters[stateName])) {
                        handleSelectOnChange(selectCustomValueType, filter, fieldKey, stateName);
                    }
                    return (
                        <Col span={12} key={dateTypeFieldKey}>
                            <FormItem label={getTranslatedText(filter.filterName)}>
                                {getFieldDecorator(dateTypeFieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        dateTypeFieldKey
                                    ),
                                })(
                                    <SelectReadonly
                                        allowClear={true}
                                        readOnly={formDisabled}
                                        className="date-picker-select"
                                        placeholder={getTranslatedText(filter.filterPlaceholder)}
                                        onChange={(selectValue: any) => {
                                            handleSelectOnChange(selectValue, filter, fieldKey, stateName);
                                        }}
                                        getPopupContainer={populatePopoverContainer(
                                            drawerRef
                                        )}
                                    >
                                        {map(optionsObject, (option: any) => (
                                            <Option key={option} value={option}>
                                                {!isUndefined(option) ? option : getTranslatedText('Select Date Range')}
                                            </Option>
                                        ))}
                                    </SelectReadonly>
                                )}
                            </FormItem>
                            {selectCustomType &&
                                populateSelectCustomType(
                                    selectCustomType,
                                    stateName,
                                    fieldKey,
                                    filter.filterMaxDate
                                )}
                        </Col >
                    );
                }
                else if (filter.filterElement === 'input-autocomplete') {
                    const filterNameUsed =
                        filter.filterStateName === 'Customer' &&
                            !isOrgView
                            ? capitalize(customerLabel)
                            : filter.filterName;
                    const stateName = filter.filterStateName || filter.filterName;
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={getTranslatedText(filterNameUsed)}>
                                <InputAutoCompleteWithButtonDashboard
                                    readOnly={formDisabled}
                                    hasNoOkButton={true}
                                    updateField={(value: string) => {
                                        changeSearchFilter(stateName, value);
                                    }}
                                    stateValue={searchFilters[stateName]}
                                    queryName={filter.filterQuery}
                                    queryFilterName={filter.filterNameQuery}
                                    filterField={filter.filterStateName}
                                    sortField={filter.filterSort}
                                    responseName={filter.filterResponse}
                                    labelField={filter.filterLabelField}
                                    placeholder={filter.filterPlaceholder}
                                    filterSubChecking={filter.filterSubChecking}
                                    queryFilterNameSub={filter.filterNameQuerySub}
                                    getFieldDecorator={getFieldDecorator}
                                    widgetDetails={widgetDetails}
                                    filterFieldKey={fieldKey}
                                />
                            </FormItem>
                        </Col>
                    );
                }
                else {
                    const filterNameUsed =
                        filter.filterStateName === 'Customer' &&
                            !isOrgView
                            ? capitalize(customerLabel)
                            : filter.filterName;
                    return (
                        <Col span={12} key={fieldKey}>
                            <FormItem label={getTranslatedText(filterNameUsed)}>
                                {getFieldDecorator(fieldKey, {
                                    initialValue: get(
                                        widgetDetails,
                                        fieldKey
                                    ),
                                })(
                                    <Input
                                        placeholder={filterNameUsed}
                                        readOnly={formDisabled}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    );
                }
            }
        );
    };

    /**
     * Common function for populating the custom fields filters.
     * @param customFieldType
     */
    const populateCustomFieldsFilters = (customFieldType: string) => {
        return filter(customFieldsFilterList, ['Type', customFieldType]).map(
            ({ Type, FieldName }: DynamicObject) => {
                const customFieldKey = `${customFieldIndicator}${Type}--${FieldName}`;
                return (
                    <Col span={12} key={customFieldKey}>
                        <FormItem label={getTranslatedText(FieldName)}>
                            {getFieldDecorator(customFieldKey, {
                                initialValue: get(
                                    widgetDetails,
                                    customFieldKey
                                ),
                            })(
                                <Input
                                    placeholder={FieldName}
                                    readOnly={formDisabled}
                                />
                            )}
                        </FormItem>
                    </Col>
                );
            }
        );
    };

    /**
     * Function for populating the row count options.
     */
    const populateRowCountOptions = () =>
        map(displayRowCountOptions, (count: number) => (
            <Option key={count} value={count}>
                {count}
            </Option>
        ));

    /**
    * Function that populates the dropdown based on given list of options.
    */
    const populateDisplayTypeOptions = () =>
        map(chartWidgetDisplayNoPieTypes, ({ label, value }: any) => (
            <Option key={value} value={value}>
                {getTranslatedText(label)}
            </Option>
        ));


    /**
     * Function for populating the sort by options.
     */
    const populateSortByOption = () => {
        const option = map(customersSettlementSortByOptions, ({ label, value }) => {
            const labelUsed = label
                .replace('Customer', capitalize(customerLabel))
                .replace('customer', toLower(customerLabel));

            return (
                <Option key={value} value={value}>
                    {labelUsed}
                </Option>
            );
        });

        return (
            <Row>
                <FormItem label={getTranslatedText("Sort by")}>
                    {getFieldDecorator('SortField', {
                        initialValue:
                            get(widgetDetails, 'SortField') ||
                            get(customersSettlementSortByOptions[1], 'value'),
                    })(
                        <SelectReadonly
                            readOnly={formDisabled}
                            style={{ width: 200 }}
                            getPopupContainer={populatePopoverContainer(
                                drawerRef
                            )}
                        >
                            {option}
                        </SelectReadonly>
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('SortFieldAsc', {
                        initialValue: get(widgetDetails, 'SortFieldAsc') || false,
                        valuePropName: 'checked'
                    })(
                        <Checkbox
                            disabled={formDisabled}
                        >
                            {getTranslatedText(`Ascending`)}
                        </Checkbox>
                    )}
                </FormItem>
            </Row>
        );
    };


    return (
        <Row>
            <Col>
                <Row>
                    <Col span={24}>
                        <FormItem label={getTranslatedText("Filter on display type")}>
                            {getFieldDecorator('displayType', {
                                initialValue:
                                    get(widgetDetails, 'displayType') ||
                                    get(
                                        chartWidgetDisplayNoPieTypes,
                                        '1.value'
                                    ),
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: '100%' }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateDisplayTypeOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <h3>
                                    {getTranslatedText('Filter by invoice fields')}
                                </h3>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            {populateCustomerFilters()}
                        </Row>
                    </Col>
                </Row>
                <Divider />
                {/* <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <h3>Filter by invoice custom fields</h3>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            {populateCustomFieldsFilters(
                                CUSTOM_FIELD_TYPES.INVOICE
                            )}
                        </Row>
                    </Col>
                </Row>
                <Divider /> */}
                <Row>
                    <Col>
                        <Row>
                            <Col span={24}>
                                <h3>
                                    {getTranslatedText(`Filter by ${isOrgView ? 'customer' : toLower(customerLabel)} custom fields`)}
                                </h3>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            {populateCustomFieldsFilters(
                                CUSTOM_FIELD_TYPES.CUSTOMER
                            )}
                        </Row>
                    </Col>
                </Row>
                {isOrgView && (
                    <OrganisationWidgetCommonFilters
                        widgetDetails={widgetDetails}
                        getFieldDecorator={getFieldDecorator}
                        getFieldValue={getFieldValue}
                        setFieldsValue={setFieldsValue}
                        drawerRef={drawerRef}
                        isRegional
                        organisationCurrenciesAll={organisationCurrenciesAll}
                    />
                )}
                <Divider />
                <Row>
                    <Col>
                        {populateSortByOption()}
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col>
                        <FormItem label={getTranslatedText("Number of rows (Amount)")}>
                            {getFieldDecorator('rowCount', {
                                initialValue:
                                    get(widgetDetails, 'rowCount') ||
                                    get(displayRowCountOptions, '0'),
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText('Row count is required!'),
                                    },
                                ],
                            })(
                                <SelectReadonly
                                    readOnly={formDisabled}
                                    style={{ width: 80 }}
                                    getPopupContainer={populatePopoverContainer(
                                        drawerRef
                                    )}
                                >
                                    {populateRowCountOptions()}
                                </SelectReadonly>
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default withAccountingSystemHandler(CustomersSettlementWidgetFields);
