import API, { graphqlOperation } from '@aws-amplify/api';
import { get, map, toLower } from 'lodash';
import moment from 'moment-timezone';
import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import {
    CUSTOMERS_PAGE,
    DETAILS_TAB,
} from '../../config/tableAndPageConstants';
import {
    FamilyNameAttribute,
    GivenNameAttribute,
} from '../../constants/authUserAttributes';
import queries from '../../graphql/queries.graphql';
import {
    checkShouldRequestRefetch,
    formatDateToDateObjectUTC,
    getGraphqlQueryString,
    getRegionallyApiResponse,
    getRegionallyGraphqlResponse,
    getRegionConfigFromList,
    getSortFieldsWithCustomFields,
    removeAppliedFiltersForApiRequest,
    checkIfEmailIsValid
} from '../../utils/commonFunctions';
import { Conversation, PageData } from '../common/types';
import { getCurrentUser } from '../users/sagas';
import { DynamicObject } from './../../utils/commonInterfaces';
import {
    bulkSaveCustomerUpdateResponseAction,
    customerConversationsFetchedForOrganisationAction as customerConversationLinesFetchedForOrganisationAction,
    customerCreditsFetchedForOrganisationAction,
    customerInvoicesFetchedForOrganisationAction,
    customerPaymentsFetchedForOrganisationAction,
    customersFetchedForOrganisationAction,
    getCustomerConversationErrorAction,
    getCustomerConversationSuccessAction,
    getCustomerCreditsErrorAction,
    getCustomerCreditsSuccessAction,
    getCustomerDataErrorAction,
    getCustomerDataSuccessAction,
    getCustomerInvoicesErrorAction,
    getCustomerInvoicesSuccessAction,
    getCustomerPaymentsErrorAction,
    getCustomerPaymentsRequestAction,
    getCustomerPaymentsSuccessAction,
    getCustomersErrorAction,
    getCustomersForOrganisationRequestAction,
    getCustomersRequestAction,
    getCustomersSuccessAction,
    saveCustomerUpdateResponseAction,
    setCustomerSelectedIdSuccessAction
} from './actions';
import { Customer, CustomersActionTypes, CustomerSendInformationRequestPayload, CustomersFetchedData } from './types';
import { getRegionKeyConfig, getRegionSettingConfig } from '../auth/sagas';
import { Auth } from 'aws-amplify';
import { SubscribeToUpdates } from '../../utils/SubscriptionService';
import { store } from '../..';
import { Invoice, InvoicesFetchedData } from '../invoices/types';
import { AtbViewType } from '../../constants/settings';
import { Credit, CreditsFetchedData } from '../credits/types';
import { Payment, PaymentsFetchedData } from '../payments/types';
import { ConversationLinesFetchedData } from '../conversationLine/types';
import { getOrganisationActionUrl } from '../organisations/sagas';

export const getCustomerData = (state: ApplicationState) =>
    state.customers.activeData;

export const getCustomerInvoicesData = (state: ApplicationState) =>
    state.customers.activeData.invoices;

export const getCustomerSelectedId = (state: ApplicationState) =>
    state.customers.activeData.selectedId;

export const getCustomerUILabel = (state: ApplicationState) =>
    get(state.companies.selectedUserCompany, 'Company.CustomerTitleLabel') ||
    'customer';

export const getCustomersPageData = (state: ApplicationState) =>
    state.customers.pageData;

export const getCompanyIdByCustomerSelectedId = (state: ApplicationState) =>
{
    const customerSelectedId = state.customers.activeData.selectedId;
    const customerSelected = state.customers.data.find(c => c.Id === customerSelectedId) as any;

    return customerSelected ? customerSelected.CompanyId : undefined;
}

let refetchCount = 0;

/**
 * Function that calls the API for fetching the customer list.
 * @param param0
 */
function* handleGetCustomersRequest({ payload }: any) {
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            atbType,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
            usingCustomerWorkflow,
            usingMultipleWorkflow,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customer',
            true
        );

        const assignedUserValue = cleanFilters.AssignedUserId;
        if (assignedUserValue) {
            if (checkIfEmailIsValid(assignedUserValue)) {
                delete cleanFilters.AssignedUserId;
                cleanFilters.AssignedEmailAddress = assignedUserValue;
            }
        }
        
        const sortObject = getSortFieldsWithCustomFields(sortBy);

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CUSTOMERS_FOR_COMPANY, {
                ...cleanFilters,
                ATBType: atbType,
                // SortField: sortBy,
                ...sortObject,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * CUSTOMERS_PAGE.pageSize,
                IsCloudImportType: isUsingCloudImportType,
                UsingCustomerWorkflow: usingCustomerWorkflow,
                IsMultipleWorkflow: usingMultipleWorkflow,
            })
        );

        const { Customers } = get(res.data, 'GetCustomersForCompany');
        const responsePayload = {
            data: Customers,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage:
                    !(Customers.length < pageSize) &&
                    !(pageSize < CUSTOMERS_PAGE.pageSize),
            },
        };

        refetchCount = 0;
        yield put(getCustomersSuccessAction(responsePayload));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getCustomersRequestAction(payload));
        } else {
            const customerLabel: string = yield select(getCustomerUILabel);
            const errorMessage = `Error fetching ${toLower(
                customerLabel
            )} list. Please try again later.`;
            yield put(getCustomersErrorAction([errorMessage]));
        }
    }
}

function* errorHandler(err: any, payload: any) {
    if (err instanceof Error) {
        console.log('Error', err);
    } else {
        console.error('An unknown error occured.', err);
    }

    if (
        refetchCount <= maxAPIRefetchCount &&
        checkShouldRequestRefetch(err)
    ) {
        refetchCount++;
        yield delay(refetchAPIDelay);
        yield put(getCustomersForOrganisationRequestAction({ ...payload, refetchCount }));
    } else {
        const customerLabel: string = yield select(getCustomerUILabel);
        const errorMessage = `Error fetching ${toLower(
            customerLabel
        )} list. Please try again later.`;
        yield put(getCustomersErrorAction([errorMessage]));
    }
}

function CustomersFetchedForOrganisationReceived(params: { pageSize: number, currentPage: number }) {
    return function (data: CustomersFetchedData) {
        store.dispatch(customersFetchedForOrganisationAction({ data, ...params }));
    };
}

function CustomerInvoicesFetchedForOrganisationReceived(params: { pageSize: number, currentPage: number }) {
    return function (data: InvoicesFetchedData) {
        store.dispatch(customerInvoicesFetchedForOrganisationAction({ data, ...params }));
    };
}

function CustomerCreditsFetchedForOrganisationReceived(params: { pageSize: number, currentPage: number }) {
    return function (data: CreditsFetchedData) {
        store.dispatch(customerCreditsFetchedForOrganisationAction({ data, ...params }));
    };
}

function CustomerPaymentsFetchedForOrganisationReceived(params: { pageSize: number, currentPage: number }) {
    return function (data: PaymentsFetchedData) {
        store.dispatch(customerPaymentsFetchedForOrganisationAction({ data, ...params }));
    };
}

function CustomerConversationLinesFetchedForOrganisationReceived(params: { pageSize: number, currentPage: number }) {
    return function (data: ConversationLinesFetchedData) {
        store.dispatch(customerConversationLinesFetchedForOrganisationAction({ data, ...params }));
    };
}

function* watchCustomersFetchedForOrganisationReceived() {
    yield takeLatest(CustomersActionTypes.CUSTOMERS_FETCHED_FOR_ORGANISATION_RECEIVED, function* (action: any) {
        const { data, pageSize, currentPage } = action.payload;
        const customers: Customer[] = data.OnCustomersFetchedForOrganisation.Customers;

        const responsePayload = {
            data: customers,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage:
                    !(customers.length < pageSize) &&
                    !(pageSize < CUSTOMERS_PAGE.pageSize),
            },
        };

        refetchCount = 0;
        yield put(getCustomersSuccessAction(responsePayload));
    });
}

function* watchCustomerInvoicesFetchedForOrganisationReceived() {
    yield takeLatest(CustomersActionTypes.CUSTOMER_INVOICES_FETCHED_FOR_ORGANISATION_RECEIVED, function* (action: any) {
        const { data, pageSize, currentPage } = action.payload;
        const invoices: Invoice[] = data.OnInvoicesFetchedForOrganisation.Invoices;
       
        const responsePayload = {
            data: invoices,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage: !(invoices.length < pageSize)
            },
        };

        yield put(getCustomerInvoicesSuccessAction(responsePayload));
    });
}

function* watchCustomerCreditsFetchedForOrganisationReceived() {
    yield takeLatest(CustomersActionTypes.CUSTOMER_CREDITS_FETCHED_FOR_ORGANISATION_RECEIVED, function* (action: any) {
        const { data, pageSize, currentPage } = action.payload;
        const credits: Credit[] = data.OnCreditsFetchedForOrganisation.Credits;

        const responsePayload = {
            data: credits,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage: !(credits.length < pageSize)
            },
        };

        yield put(getCustomerCreditsSuccessAction(responsePayload));
    });
}

function* watchCustomerPaymentsFetchedForOrganisationReceived() {
    yield takeLatest(CustomersActionTypes.CUSTOMER_PAYMENTS_FETCHED_FOR_ORGANISATION_RECEIVED, function* (action: any) {
        const { data, pageSize, currentPage } = action.payload;
        const payments: Payment[] = data.OnPaymentsFetchedForOrganisation.Payments;

        const responsePayload = {
            data: payments,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage: !(payments.length < pageSize)
            },
        };

        yield put(getCustomerPaymentsSuccessAction(responsePayload));
    });
}

function* watchCustomerConversationLinesFetchedForOrganisationReceived() {
    yield takeLatest(CustomersActionTypes.CUSTOMER_CONVERSATION_LINES_FETCHED_FOR_ORGANISATION_RECEIVED, function* (action: any) {
        const { data, pageSize, currentPage } = action.payload;
        const conversationLines: Conversation[] = data.OnConversationLinesFetchedForOrganisation.ConversationLines;

        const responsePayload = {
            data: conversationLines,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage: !(conversationLines.length < pageSize)
            },
        };

        yield put(getCustomerConversationSuccessAction(responsePayload));
    });
}

function* handleGetCustomersForOrganisationRequest({ payload: sagaPayload }: any) {
    let { refetchCount, ...payload } = sagaPayload;
    refetchCount = refetchCount || 0;
    try {
        // To call async functions, use redux-saga's `call()`.
        const {
            filters,
            atbType,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
            usingCustomerWorkflow,
            usingMultipleWorkflow,
            region,
            conversionCurrency,
            organisationCurrencies,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customer',
            true
        );

        const currentSession: DynamicObject = yield Auth.currentSession();
        const accessToken = get(currentSession, 'accessToken.jwtToken');

        //// Use the subscription function
        //yield fork(
        //    (subscriptionQuery, onNext, onError) =>
        //        SubscribeToUpdates<CustomersFetchedData>(
        //            subscriptionQuery,
        //            { IsCloudImportType: isUsingCloudImportType },  // Pass the variables here
        //            onNext,
        //            onError,
        //            accessToken
        //        ),
        //    subscriptions.ON_CUSTOMERS_FETCHED_FOR_ORGANISATION,
        //    CustomersFetchedForOrganisationReceived({ pageSize, currentPage }),
        //    function handleError(error: any) {
        //        console.error('Subscription error:', JSON.stringify(error, null, 2));
        //    }
        //);

        const sortObject = getSortFieldsWithCustomFields(sortBy);

        let apiUrl: string | undefined = undefined;
        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        let res: DynamicObject;
        const usedQuery = queries.GET_CUSTOMERS_FOR_ORGANISATION;
        const variables = {
            ...cleanFilters,
            ATBType: atbType,
            // SortField: sortBy,
            ...sortObject,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage * CUSTOMERS_PAGE.pageSize,
            IsCloudImportType: isUsingCloudImportType,
            UsingCustomerWorkflow: usingCustomerWorkflow,
            IsMultipleWorkflow: usingMultipleWorkflow,
            ConversionCurrency: conversionCurrency,
            OrganisationCurrencies: organisationCurrencies,
        };

        if (apiUrl) {
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(usedQuery),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(usedQuery, variables)
            );
        }

        const { Customers } = get(res.data, 'GetCustomersForOrganisation');
        const responsePayload = {
            data: Customers,
            pageData: {
                pageSize: pageSize,
                currentPage: currentPage,
                hasNextPage:
                    !(Customers.length < pageSize) &&
                    !(pageSize < CUSTOMERS_PAGE.pageSize),
            },
        };

        refetchCount = 0;
        yield put(getCustomersSuccessAction(responsePayload));
    } catch (err) {
        errorHandler(err, payload);
    }
}

/**
 * Function calling the API for fetching the customer data based on the given id.
 * @param param0
 */
function* handleGetCustomerDataRequest({
    payload: {
        customerId,
        isUsingCloudImportType
    },
}: any) {
    const customerLabel: string = yield select(getCustomerUILabel);
    const errorMessage = `Error fetching ${toLower(
        customerLabel
    )} details. Please try again later.`;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CUSTOMER_DETAILS_FOR_COMPANY, {
                CustomerId: customerId,
                IsCloudImportType: isUsingCloudImportType,
            })
        );

        const Customer = get(res.data, 'GetCustomerDetailsForCompany');

        if (Customer) {
            const responsePayload = {
                record: Customer,
            };

            yield put(getCustomerDataSuccessAction(responsePayload));
        } else {
            yield put(getCustomerDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCustomerDataErrorAction([errorMessage]));
    }
}

/**
 * Function calling the API for fetching the customer data based on the given id.
 * @param param0
 */
function* handleGetOrganisationCustomerDataRequest({
    payload: {
        customerId,
        companyId,
        region,
        isUsingCloudImportType,
        conversionCurrency,
    },
}: any) {
    const customerLabel: string = yield select(getCustomerUILabel);
    const errorMessage = `Error fetching ${toLower(
        customerLabel
    )} details. Please try again later.`;
    try {
        let apiUrl: string | undefined = undefined;
        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        let res: DynamicObject;
        
        let variables = {
            CompanyId: companyId,
            CustomerId: customerId,
            IsCloudImportType: isUsingCloudImportType,
            ConversionCurrency: conversionCurrency,
            CompanyIds: [companyId]
        };
        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(queries.GET_CUSTOMER_DETAILS_FOR_ORGANISATION),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(queries.GET_CUSTOMER_DETAILS_FOR_ORGANISATION, variables)
            );
        }
        const Customer = get(res.data, 'GetCustomerDetailsForOrganisation');

        if (Customer) {
            const responsePayload = {
                record: Customer,
            };

            yield put(getCustomerDataSuccessAction(responsePayload));
        } else {
            yield put(getCustomerDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCustomerDataErrorAction([errorMessage]));
    }
}

/**
 * Function that sets the selected customer id for reference.
 * @param param0
 */
function* handleSetCustomerSelectedIdRequest({ payload }: any) {
    const { customerId, callback } = payload;
    yield put(setCustomerSelectedIdSuccessAction(customerId));
    if (callback) callback();
}

/**
 * Function that gets the invoices list for a certain customer.
 * @param param0
 */
function* handleGetCustomerInvoicesRequest({ payload }: any) {
    const customerLabel: string = yield select(getCustomerUILabel);
    const errorMessage = `Error fetching ${toLower(customerLabel)}'s invoice list. Please try again later.`;
    try {
        const {
            filters,
            invoiceState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customer'
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_INVOICES_FOR_COMPANY, {
                ...cleanFilters,
                InvoiceState: invoiceState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.INVOICE_LIST_COMPLETE.pageSize,
            })
        );

        const { Invoices } = get(res.data, 'GetInvoicesForCompany');
        if (Invoices) {
            const responsePayload = {
                data: Invoices,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage: !(Invoices.length < pageSize),
                },
            };

            yield put(getCustomerInvoicesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCustomerInvoicesErrorAction([errorMessage]));
    }
}

/**
 * Function that gets the invoices list for a certain customer.
 * @param param0
 */
function* handleGetOrganisationCustomerInvoicesRequest({
    payload: sagaPayload
}: any) {
    const customerLabel: string = yield select(getCustomerUILabel);
    const errorMessage = `Error fetching ${toLower(customerLabel)}'s invoice list. Please try again later.`;
    const { payload, region } = sagaPayload;

    try {    
        const {
            filters,
            invoiceState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            conversionCurrency,
            organisationCurrencies,
            isUsingCloudImportType
        } = payload;
        
        const currentSession: DynamicObject = yield Auth.currentSession();
        const accessToken = get(currentSession, 'accessToken.jwtToken');

        //yield fork(
        //    (subscriptionQuery, onNext, onError) =>
        //        SubscribeToUpdates<InvoicesFetchedData>(
        //            subscriptionQuery,
        //            {
        //                IsCloudImportType: isUsingCloudImportType,
        //                IsMultipleWorkflow: true,
        //                UsingCustomerWorkflow: true
        //            },  // Pass the variables here
        //            onNext,
        //            onError,
        //            accessToken
        //        ),
        //    subscriptions.ON_INVOICES_FETCHED_FOR_ORGANISATION,
        //    CustomerInvoicesFetchedForOrganisationReceived({ pageSize, currentPage }),
        //    function handleError(error: any) {
        //        console.error('Subscription error:', JSON.stringify(error, null, 2));
        //    }
        //);

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customer'
        );
        let apiUrl: string | undefined = undefined;
        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        let res: DynamicObject;
        const usedQuery = queries.GET_INVOICES_FOR_ORGANISATION;

        const variables = {
            ...cleanFilters,
            InvoiceState: invoiceState,
            SortField: sortBy,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage * DETAILS_TAB.INVOICE_LIST_COMPLETE.pageSize,
            OrganisationCurrencies: organisationCurrencies,
            ConversionCurrency: conversionCurrency
        };

        if (apiUrl) {
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(usedQuery),
                        variables,
                    }),
                }
            );
            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(usedQuery, variables)
            );
        }
        
        const { Invoices } = get(res.data, 'GetInvoicesForOrganisation');
        
        if (Invoices) {
            const responsePayload = {
                data: Invoices,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage: !(Invoices.length < pageSize),
                },
            };

            yield put(getCustomerInvoicesSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCustomerInvoicesErrorAction([errorMessage]));
    }
}

/**
 * Function that gets the credits list for a certain customer.
 * @param param0
 */
function* handleGetCustomerCreditsRequest({ payload }: any) {
    const customerLabel: string = yield select(getCustomerUILabel);
    const errorMessage = `Error fetching ${toLower(customerLabel)}'s credit list. Please try again later.`;
    try {
        const {
            filters,
            creditState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customer'
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CREDITS_FOR_COMPANY, {
                ...cleanFilters,
                CreditState: creditState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CREDIT_LIST_COMPLETE.pageSize,
            })
        );

        const { Credits } = get(res.data, 'GetCreditsForCompany');
        if (Credits) {
            const responsePayload = {
                data: Credits,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage: !(Credits.length < pageSize),
                },
            };

            yield put(getCustomerCreditsSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCustomerCreditsErrorAction([errorMessage]));
    }
}

/**
 * Function that gets the credits list for a certain customer.
 * @param param0
 */
function* handleGetOrganisationCustomerCreditsRequest({
    payload: sagaPayload
}: any) {
    const customerLabel: string = yield select(getCustomerUILabel);
    const errorMessage = `Error fetching ${toLower(customerLabel)}'s credit list. Please try again later.`;
    const { payload, region } = sagaPayload;

    try {
        const {
            filters,
            creditState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            conversionCurrency,
            organisationCurrencies,
            isUsingCloudImportType,
        } = payload;

        const currentSession: DynamicObject = yield Auth.currentSession();
        const accessToken = get(currentSession, 'accessToken.jwtToken');

        //yield fork(
        //    (subscriptionQuery, onNext, onError) =>
        //        SubscribeToUpdates<CreditsFetchedData>(
        //            subscriptionQuery,
        //            { IsCloudImportType: isUsingCloudImportType },  // Pass the variables here
        //            onNext,
        //            onError,
        //            accessToken
        //        ),
        //    subscriptions.ON_CREDITS_FETCHED_FOR_ORGANISATION,
        //    CustomerCreditsFetchedForOrganisationReceived({ pageSize, currentPage }),
        //    function handleError(error: any) {
        //        console.error('Subscription error:', JSON.stringify(error, null, 2));
        //    }
        //);

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customer'
        );
        let apiUrl: string | undefined = undefined;
        
        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        let res: DynamicObject;
        const usedQuery = queries.GET_CREDITS_FOR_ORGANISATION;

        var variables = {
            ...cleanFilters,
            CreditState: creditState,
            SortField: sortBy,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage * DETAILS_TAB.CREDIT_LIST_COMPLETE.pageSize,
            ConversionCurrency: conversionCurrency,
            OrganisationCurrencies: organisationCurrencies,
        };

        if (apiUrl) {
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(usedQuery),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(usedQuery, variables)
            );
        }

        const { Credits } = get(res.data, 'GetCreditsForOrganisation');
        if (Credits) {
            const responsePayload = {
                data: Credits,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage: !(Credits.length < pageSize),
                },
            };

            yield put(getCustomerCreditsSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCustomerCreditsErrorAction([errorMessage]));
    }
}

/**
 * Function that gets the payments list for a certain customer.
 * @param param0
 */
function* handleGetCustomerPaymentsRequest({ payload }: any) {
    const customerLabel: string = yield select(getCustomerUILabel);
    const errorMessage = `Error fetching ${toLower(customerLabel)}'s payment list. Please try again later.`;
    try {
        const {
            filters,
            paymentState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
        } = payload;
        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customer'
        );
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_PAYMENTS_FOR_COMPANY, {
                ...cleanFilters,
                PaymentState: paymentState,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.PAYMENT_LIST_COMPLETE.pageSize,
                IsCloudImportType: isUsingCloudImportType,
            })
        );

        const { Payments } = get(res.data, 'GetPaymentsForCompany');
        if (Payments) {
            const responsePayload = {
                data: Payments,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage: !(Payments.length < pageSize),
                },
            };

            yield put(getCustomerPaymentsSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCustomerPaymentsErrorAction([errorMessage]));
    }
}

/**
 * Function that gets the payments list for a certain customer.
 * @param param0
 */
function* handleGetOrganisationCustomerPaymentsRequest({
    payload: sagaPayload
}: any) {
    const customerLabel: string = yield select(getCustomerUILabel);
    const errorMessage = `Error fetching ${toLower(customerLabel)}'s payment list. Please try again later.`;
    const { payload, region } = sagaPayload;

    try {
        const {
            filters,
            paymentState,
            sortBy,
            sortAscending,
            pageSize,
            currentPage,
            isUsingCloudImportType,
            conversionCurrency,
            organisationCurrencies
        } = payload;

        const currentSession: DynamicObject = yield Auth.currentSession();
        const accessToken = get(currentSession, 'accessToken.jwtToken');

        //yield fork(
        //    (subscriptionQuery, onNext, onError) =>
        //        SubscribeToUpdates<PaymentsFetchedData>(
        //            subscriptionQuery,
        //            { IsCloudImportType: isUsingCloudImportType },  // Pass the variables here
        //            onNext,
        //            onError,
        //            accessToken
        //        ),
        //    subscriptions.ON_PAYMENTS_FETCHED_FOR_ORGANISATION,
        //    CustomerPaymentsFetchedForOrganisationReceived({ pageSize, currentPage }),
        //    function handleError(error: any) {
        //        console.error('Subscription error:', JSON.stringify(error, null, 2));
        //    }
        //);

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customer'
        );
        let apiUrl: string | undefined = undefined;
        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        let res: DynamicObject;
        const usedQuery = queries.GET_PAYMENTS_FOR_ORGANISATION;

        const variables = {
            ...cleanFilters,
            PaymentState: paymentState,
            SortField: sortBy,
            Ascending: sortAscending,
            PageSize: pageSize,
            Skip: currentPage * DETAILS_TAB.PAYMENT_LIST_COMPLETE.pageSize,
            IsCloudImportType: isUsingCloudImportType,
            OrganisationCurrencies: organisationCurrencies,
            ConversionCurrency: conversionCurrency,
        };

        if (apiUrl) {
            const response: Response = yield fetch(
                `${apiUrl}/graphql`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify({
                        query: getGraphqlQueryString(usedQuery),
                        variables,
                    }),
                }
            );

            res = yield response.json();
        } else {
            res = yield call(
                [API, 'graphql'],
                graphqlOperation(usedQuery, variables)
            );
        }

         const { Payments } = get(res.data, 'GetPaymentsForOrganisation');
         if (Payments) {
             const responsePayload = {
                 data: Payments,
                 pageData: {
                     pageSize: pageSize,
                     currentPage: currentPage,
                     hasNextPage: !(Payments.length < pageSize),
                 },
             };

             yield put(getCustomerPaymentsSuccessAction(responsePayload));
         }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCustomerPaymentsErrorAction([errorMessage]));
    }
}

/**
 * Function responsible for updating the VIP status of a single customer.
 * @param param0
 */
function* handleSaveCustomerVIPUpdateRequest({ payload: sagaPayload }: any) {
    const { customerId, customerObject, callback } = sagaPayload;
    try {
        const payload = {
            CustomerId: customerId,
            Customer: customerObject,
        };
        yield call([API, 'post'], API_NAME, '/customer/save', {
            body: payload,
        });
        yield put(saveCustomerUpdateResponseAction());
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        yield put(saveCustomerUpdateResponseAction());

        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function responsible for updating the VIP status of a single customer.
 * @param param0
 */
function* handleSaveOrganisationCustomerVIPUpdateRequest({ payload: sagaPayload }: any) {
    const { payload, region } = sagaPayload;
    const { customerId, companyId, customerObject, callback } = payload;
    try {
        const postPayload = {
            CustomerId: customerId,
            CompanyId: companyId,
            Customer: customerObject,
        };

        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        yield call(
            getRegionallyApiResponse,
            apiUrl,
            '/customer/save',
            'POST',
            postPayload
        );
        
        yield put(saveCustomerUpdateResponseAction());
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        yield put(saveCustomerUpdateResponseAction());

        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function for updating the VIP status of multiple customers.
 * @param param0
 */
function* handleBulkSaveCustomersVIPUpdateRequest({
    payload: sagaPayload,
}: any) {
    const { filter, customerIds, excludeCustomers, customerObject, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'customer'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }

    const payload = {
        CustomerManagementFilter: {
            ...cleanFilters,
            CustomerIds: customerIds,
            ExcludeCustomers: excludeCustomers,
        },
        Customer: customerObject,
    };

    try {
        yield call([API, 'post'], API_NAME, '/customer/batch/save', {
            body: payload,
        });
        yield put(bulkSaveCustomerUpdateResponseAction());
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        yield put(bulkSaveCustomerUpdateResponseAction());

        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function for fetching the conversation list of a given customer.
 * @param param0
 */
function* handleGetCustomerConversationRequest({ payload }: any) {
    const customerLabel: string = yield select(getCustomerUILabel);
    const errorMessage = `Error fetching ${toLower(customerLabel)}'s conversation list. Please try again later.`;
    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } =
            payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customer',
            true
        );

        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CONVERSATION_LINES_FOR_COMPANY, {
                ...cleanFilters,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
            })
        );

        const { ConversationLines } = get(
            res.data,
            'GetConversationLinesForCompany'
        );
        const Conversation = ConversationLines;

        if (Conversation) {
            const responsePayload = {
                data: Conversation,
                pageData: {
                    pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(Conversation.length < pageSize) &&
                        !(
                            pageSize <
                            DETAILS_TAB.CONVERSATION_TIMELINE.pageSize
                        ),
                },
            };

            yield put(getCustomerConversationSuccessAction(responsePayload));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCustomerConversationErrorAction([errorMessage]));
    }
}

/**
 * Function for fetching the conversation list of a given customer.
 * @param param0
 */
function* handleGetOrganisationCustomerConversationRequest({
    payload: sagaPayload
}: any) {
    const customerLabel: string = yield select(getCustomerUILabel);
    const errorMessage = `Error fetching ${toLower(customerLabel)}'s conversation list. Please try again later.`;
    const { payload, region, companyIds } = sagaPayload;

    try {
        const { filters, sortBy, sortAscending, pageSize, currentPage } = payload;

        const cleanFilters = removeAppliedFiltersForApiRequest(
            filters,
            true,
            'customer',
            true
        );
        
        const apiUrl: string | undefined = yield select(
            getOrganisationActionUrl
        );

        const res: DynamicObject = yield call(
            getRegionallyGraphqlResponse,
            apiUrl,
            queries.GET_CONVERSATION_LINES_FOR_ORGANISATION,
            {
                ...cleanFilters,
                CompanyIds: companyIds,
                SortField: sortBy,
                Ascending: sortAscending,
                PageSize: pageSize,
                Skip: currentPage * DETAILS_TAB.CONVERSATION_TIMELINE.pageSize,
            }
        );

        const { ConversationLines } = get(res.data, 'GetConversationLinesForOrganisation');
        const Conversation = ConversationLines;

         if (Conversation) {
             const responsePayload = {
                 data: Conversation,
                 pageData: {
                     pageSize,
                     currentPage: currentPage,
                     hasNextPage:
                         !(Conversation.length < pageSize) &&
                         !(
                             pageSize <
                             DETAILS_TAB.CONVERSATION_TIMELINE.pageSize
                         ),
                 },
             };

             yield put(getCustomerConversationSuccessAction(responsePayload));
         }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getCustomerConversationErrorAction([errorMessage]));
    }
}

/**
 * Function for adding comment to the customer's conversation list.
 * @param param0
 */
function* handleCustomerAddCommentRequest({ payload: sagaPayload }: any) {
    const { filter, customerIds, excludeCustomers, comment, callback } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'customer'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }

    const payload = {
        CustomerManagementFilter: {
            ...cleanFilters,
            CustomerIds: customerIds,
            ExcludeCustomers: excludeCustomers,
        },
        Comment: comment,
    };

    try {
        yield call(
            [API, 'post'],
            API_NAME,
            '/conversation/save/customercomment',
            {
                body: payload,
            }
        );
        if (callback) {
            let RefetchList = true;
            if (customerIds.length === 1 && excludeCustomers === false) {
                RefetchList = false;
                const currentUser: DynamicObject = yield select(getCurrentUser);
                const customersUpdated: DynamicObject[] = map(
                    customerIds,
                    (uId: string) => {
                        return {
                            Id: uId,
                            ConversationLine: {
                                Content: comment,
                                CreatedDateTime: formatDateToDateObjectUTC(
                                    moment(),
                                    undefined,
                                    true
                                ),
                                User: {
                                    GivenName: get(
                                        currentUser,
                                        GivenNameAttribute
                                    ),
                                    FamilyName: get(
                                        currentUser,
                                        FamilyNameAttribute
                                    ),
                                },
                            },
                        };
                    }
                );
                const pageData: PageData = yield select(getCustomersPageData);
                yield put(
                    getCustomersSuccessAction({
                        data: customersUpdated,
                        pageData,
                        mergeData: true,
                    })
                );
            }
            const response = {
                IsSuccess: true,
                RefetchList,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function for adding comment to the customer's conversation list.
 * @param param0
 */
function* handleOrganisationCustomerAddCommentRequest({ payload: sagaPayload }: any) {
    const { payload, region, companyIds } = sagaPayload;
    const { filter, customerIds, excludeCustomers, comment, callback } =
        payload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'customer'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }

    const postPayload = {
        CustomerManagementFilter: {
            ...cleanFilters,
            CustomerIds: customerIds,
            CompanyIds: companyIds,
            ExcludeCustomers: excludeCustomers,
        },
        Comment: comment,
    };

    try {
        let apiUrl: string | undefined = undefined;

        if (region) {
            let regionKeyConfig: DynamicObject[] = yield select(
                getRegionKeyConfig
            );
            let regionSettingsConfig: DynamicObject[] = yield select(
                getRegionSettingConfig
            );
            const configUsed = getRegionConfigFromList(
                region,
                regionKeyConfig,
                regionSettingsConfig
            );
            apiUrl = get(configUsed, 'Url');
        }

        if (apiUrl) {
            const currentSession: DynamicObject = yield Auth.currentSession();
            const accessToken = get(currentSession, 'accessToken.jwtToken');

            yield fetch(
                `${apiUrl}/conversation/save/customercomment`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: accessToken,
                    },
                    body: JSON.stringify(postPayload),
                }
            );
        }
        else {
            yield call(
                [API, 'post'],
                API_NAME,
                '/conversation/save/customercomment',
                {
                    body: postPayload,
                }
            );
        }
        
        if (callback) {
            let RefetchList = true;
            if (customerIds.length === 1 && excludeCustomers === false) {
                RefetchList = false;
                const currentUser: DynamicObject = yield select(getCurrentUser);
                const customersUpdated: DynamicObject[] = map(
                    customerIds,
                    (uId: string) => {
                        return {
                            Id: uId,
                            ConversationLine: {
                                Content: comment,
                                CreatedDateTime: formatDateToDateObjectUTC(
                                    moment(),
                                    undefined,
                                    true
                                ),
                                User: {
                                    GivenName: get(
                                        currentUser,
                                        GivenNameAttribute
                                    ),
                                    FamilyName: get(
                                        currentUser,
                                        FamilyNameAttribute
                                    ),
                                },
                            },
                        };
                    }
                );
                const pageData: PageData = yield select(getCustomersPageData);
                yield put(
                    getCustomersSuccessAction({
                        data: customersUpdated,
                        pageData,
                        mergeData: true,
                    })
                );
            }
            const response = {
                IsSuccess: true,
                RefetchList,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function for send information to the customers
 * @param param0
 */
function* handleCustomerSendInformationRequest({ payload: sagaPayload }: any) {
    const { filter, customerIds, excludeCustomers, TemplateName, callback } =
        sagaPayload as CustomerSendInformationRequestPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'customer'
    );

    if (cleanFilters.CustomFieldFilters) {
        cleanFilters.CustomFieldFilters = JSON.parse(
            cleanFilters.CustomFieldFilters
        );
    }

    const payload = {
        CustomerManagementFilter: {
            ...cleanFilters,
            CustomerIds: customerIds,
            ExcludeCustomers: excludeCustomers,
        },
        TemplateName: TemplateName,
    };

    try {

        yield call(
            [API, 'post'],
            API_NAME,
            '/task/action/manual-communication/send',
            {
                body: payload,
            }
        );
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function called for assign user to customers.
 * @param param0
 */
function* handleCustomerAssignUserRequest({
    payload: sagaPayload,
}: any) {

    const { filter, customerIds, excludeCustomers, userObject, callback, companyId } =
        sagaPayload;
    const cleanFilters = removeAppliedFiltersForApiRequest(
        filter,
        true,
        'customer'
    );

    const payload = {
        CustomerManagementFilter: {
            ...cleanFilters,
            CustomerIds: customerIds,
            ExcludeCustomers: excludeCustomers,
            CompanyId: companyId
        },
        ...userObject,
    };

    try {
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/customer/action/assign-user',
            {
                body: payload,
            }
        );

        let successMessages: any = [];
        successMessages.push(
            `Customer${payload.CustomerManagementFilter.CustomerIds.length > 1 || payload.CustomerManagementFilter.ExcludeCustomers ? 's have' : ' has'
            } been assigned successfully!`
        );

        if (callback) {
            const response = {
                IsSuccess: true,
                Messages: successMessages,
            };
            callback(response);
        }
    } catch (err) {
        if (callback) {
            const returnData = get(err.response, 'data')
                ? err.response.data
                : { Messages: [err.message] };
            returnData.IsSuccess = false;
            callback(returnData);
        }
    }
}

/**
 * Function for getting the customer custom fields for a company.
 * @param param0
 */
function* handleGetCustomerCustomFieldsForCompanyRequest({ payload }: any) {
    const errorMessage = '';
    const { 
            CompanyId, 
            callback
        } = payload;
    try {
        const errorMessage =
        'Error fetching custom field values. Please try again later.';
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CUSTOMER_CUSTOM_FIELDS_FOR_COMPANY, {
                CompanyId: CompanyId,
            })
        );
        
        const CustomFieldValues  = get(res.data, 'GetCustomerCustomFieldsForCompany');
        
        if (callback) {
            CustomFieldValues.IsSuccess = true;
            callback(CustomFieldValues);
        }

    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for getting the customer custom fields for a company.
 * @param param0
 */
function* handleGetCustomerFirstLetterValuesRequest({ payload }: any) {
    const {
        companyId,
        callback
    } = payload;
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CUSTOMER_FIRST_LETTER_VALUES, {
                CompanyId: companyId,
            })
        );

        const values = get(res.data, 'GetCustomerFirstLetterValuesForOrganisation');

        if (callback) {
            values.IsSuccess = true;
            callback(values);
        } else {

        }
    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function for getting the customer custom fields for a organisation.
 * @param param0
 */
function* getCustomerFirstLetterValuesForOrganisationRequest({ payload }: any) {
    const {
        companyIds,
        callback
    } = payload;
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_CUSTOMER_FIRST_LETTER_VALUES_FOR_ORGANISATION, {
                CompanyIds: companyIds,
            })
        );

        const values = get(res.data, 'GetCustomerFirstLetterValuesForOrganisation');

        if (callback) {
            values.IsSuccess = true;
            callback(values);
        } else {

        }
    } catch (err) {
        if (callback) callback([]);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetCustomersRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_CUSTOMERS_REQUEST,
        handleGetCustomersRequest
    );
}

function* watchGetCustomersForOrganisationRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_CUSTOMERS_FOR_ORGANISATION_REQUEST,
        handleGetCustomersForOrganisationRequest
    );
}

function* watchGetCustomerDataRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_CUSTOMER_DATA_REQUEST,
        handleGetCustomerDataRequest
    );
}

function* watchGetOrganisationCustomerDataRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_ORGANISATION_CUSTOMER_DATA_REQUEST,
        handleGetOrganisationCustomerDataRequest
    );
}

function* watchSetCustomerSelectedIdRequest() {
    yield takeLatest(
        CustomersActionTypes.SET_CUSTOMER_SELECTED_ID_REQUEST,
        handleSetCustomerSelectedIdRequest
    );
}

function* watchGetCustomerInvoicesRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_CUSTOMER_INVOICES_REQUEST,
        handleGetCustomerInvoicesRequest
    );
}

function* watchGetOrganisationCustomerInvoicesRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_ORGANISATION_CUSTOMER_INVOICES_REQUEST,
        handleGetOrganisationCustomerInvoicesRequest
    );
}

function* watchGetCustomerCreditsRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_CUSTOMER_CREDITS_REQUEST,
        handleGetCustomerCreditsRequest
    );
}

function* watchGetOrganisationCustomerCreditsRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_ORGANISATION_CUSTOMER_CREDITS_REQUEST,
        handleGetOrganisationCustomerCreditsRequest
    );
}

function* watchGetCustomerPaymentsRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_CUSTOMER_PAYMENTS_REQUEST,
        handleGetCustomerPaymentsRequest
    );
}

function* watchGetOrganisationCustomerPaymentsRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_ORGANISATION_CUSTOMER_PAYMENTS_REQUEST,
        handleGetOrganisationCustomerPaymentsRequest
    );
}

function* watchSaveCustomerVIPUpdateRequest() {
    yield takeLatest(
        CustomersActionTypes.SAVE_CUSTOMER_UPDATE_REQUEST,
        handleSaveCustomerVIPUpdateRequest
    );
}

function* watchSaveOrganisationCustomerVIPUpdateRequest() {
    yield takeLatest(
        CustomersActionTypes.SAVE_ORGANISATION_CUSTOMER_UPDATE_REQUEST,
        handleSaveOrganisationCustomerVIPUpdateRequest
    );
}

function* watchBulkSaveCustomersVIPUpdateRequest() {
    yield takeLatest(
        CustomersActionTypes.BULK_SAVE_CUSTOMER_UPDATE_REQUEST,
        handleBulkSaveCustomersVIPUpdateRequest
    );
}

function* watchGetCustomerConversationRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_CUSTOMER_CONVERSATION_REQUEST,
        handleGetCustomerConversationRequest
    );
}

function* watchGetOrganisationCustomerConversationRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_ORGANISATION_CUSTOMER_CONVERSATION_REQUEST,
        handleGetOrganisationCustomerConversationRequest
    );
}

function* watchCustomerAddCommentRequest() {
    yield takeLatest(
        CustomersActionTypes.CUSTOMER_ADD_COMMENT_REQUEST,
        handleCustomerAddCommentRequest
    );
}

function* watchOrganisationCustomerAddCommentRequest() {
    yield takeLatest(
        CustomersActionTypes.ORGANISATION_CUSTOMER_ADD_COMMENT_REQUEST,
        handleOrganisationCustomerAddCommentRequest
    );
}

function* watchCustomerSendInformationRequest() {
    yield takeLatest(
        CustomersActionTypes.CUSTOMER_SEND_INFORMATION_REQUEST,
        handleCustomerSendInformationRequest
    );
}

function* watchCustomerAssignUserRequest() {
    yield takeLatest(
        CustomersActionTypes.CUSTOMER_ASSIGN_USER_REQUEST,
        handleCustomerAssignUserRequest);

    }
function* watchGetCustomerCustomFieldsForCompanyRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_CUSTOMER_CUSTOM_FIELDS_FOR_COMPANY_REQUEST,
        handleGetCustomerCustomFieldsForCompanyRequest
    );
}

function* watchGetCustomerFirstLetterValuesRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_CUSTOMER_FIRST_LETTER_VALUES_REQUEST,
        handleGetCustomerFirstLetterValuesRequest
    );
}

function* watchGetCustomerFirstLetterValuesForOrganisationRequest() {
    yield takeLatest(
        CustomersActionTypes.GET_CUSTOMER_FIRST_LETTER_VALUES_FOR_ORGANISATION_REQUEST,
        getCustomerFirstLetterValuesForOrganisationRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* customersSaga() {
    yield all([
        fork(watchGetCustomersRequest),
        fork(watchGetCustomerDataRequest),
        fork(watchGetOrganisationCustomerDataRequest),
        fork(watchSetCustomerSelectedIdRequest),
        fork(watchGetCustomerInvoicesRequest),
        fork(watchGetOrganisationCustomerInvoicesRequest),
        fork(watchGetCustomerCreditsRequest),
        fork(watchGetOrganisationCustomerCreditsRequest),
        fork(watchGetCustomerPaymentsRequest),
        fork(watchGetOrganisationCustomerPaymentsRequest),
        fork(watchSaveCustomerVIPUpdateRequest),
        fork(watchSaveOrganisationCustomerVIPUpdateRequest),
        fork(watchBulkSaveCustomersVIPUpdateRequest),
        fork(watchGetCustomerConversationRequest),
        fork(watchGetOrganisationCustomerConversationRequest),
        fork(watchCustomerAddCommentRequest),
        fork(watchCustomerSendInformationRequest),
        fork(watchCustomerAssignUserRequest),
        fork(watchGetCustomerCustomFieldsForCompanyRequest),
        fork(watchGetCustomerFirstLetterValuesRequest),
        fork(watchOrganisationCustomerAddCommentRequest),
        fork(watchGetCustomersForOrganisationRequest),
        fork(watchGetCustomerFirstLetterValuesForOrganisationRequest),
        fork(watchCustomersFetchedForOrganisationReceived),
        fork(watchCustomerInvoicesFetchedForOrganisationReceived),
        fork(watchCustomerCreditsFetchedForOrganisationReceived),
        fork(watchCustomerPaymentsFetchedForOrganisationReceived),
        fork(watchCustomerConversationLinesFetchedForOrganisationReceived),
    ]);
}

export default customersSaga;
