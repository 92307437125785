/**
 * File for `Notifications` widget.
 */

import { Spin, Table } from 'antd';
import {
    clone,
    filter,
    forEach,
    get,
    includes,
    isEmpty,
    isNumber,
    isUndefined,
    map,
} from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    TooltipFormatter,
    XAxis,
    YAxis,
} from 'recharts';
import { CUSTOM_FIELD_TYPES } from '../../config/tableAndPageConstants';
import {
    widgetDisplayTypeValues,
    populatePayloadForOrganisationWidgets,
    widgetDateRangeValues,
    tableNumberFormatter
} from '../../constants/dashboards';
import { dateFormatYYYYMMDDDash } from '../../constants/dateFormats';
import { getDashboardNotificationsRequestAction } from '../../store/dashboards/actions';
import { dashboardBypassAPIFetch, getTranslatedText } from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    appliedFilterIndicator,
    customFieldIndicator,
} from '../common/FilterBar';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import {
    defaultEndDate,
    defaultStartDate,
    displayColumnIndicator,
    notificationGroupingOptions,
} from './NotificationsWidgetFields';
import { getTemplateContentType } from '../../utils/contentFunctions';

interface IProps {
    widgetDetails: DynamicObject;
    readonly formatNumber: (amount: number) => JSX.Element;
    preview?: boolean;
    isOrgView?: boolean;
    readonly functionRefObj?: any;
}

const NotificationsWidget: React.FC<IProps> = ({
    widgetDetails,
    formatNumber,
    preview,
    isOrgView,
    functionRefObj
}: IProps) => {    
    const unmountedRef = useRef<any>(null);
    const dispatch = useDispatch();

    const initialTableColumns = filter(notificationGroupingOptions, [
        'defaultChecked',
        true,
    ]).map(({ label, value }: DynamicObject) => ({
        title: getTranslatedText(label),
        dataIndex: value,
    }));

    const [widgetState, setTableState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        dataSource: DynamicObject[];
        columns: DynamicObject[];
    }>({
        lastWidgetDetails: {},
        loading: false,
        dataSource: [],
        columns: initialTableColumns,
    });

    const [checkedDisplayOptions, setCheckedDisplayOptions] = useState<
        DynamicObject | undefined
    >(undefined);

    /**
     * Common function for updating the `widgetState` state.
     * @param widgetStateObject
     */
    const updateWidgetStateObject = (widgetStateObject: {}) => {
        setTableState({
            ...widgetState,
            ...widgetStateObject,
        });
    };

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        let customerFieldValue: string = '';
        const notificationColumns: DynamicObject[] = [];
        let hasDisplayValue = false;
        const customFieldFilters: DynamicObject = {};
        const customFieldsUsed = [
            CUSTOM_FIELD_TYPES.CUSTOMER,
            CUSTOM_FIELD_TYPES.INVOICE,
        ];

        notificationColumns.push({
            title: ('Date'),
            dataIndex: 'xDisplay'
        })

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            const fieldName = wdKey.replace(displayColumnIndicator, '');
            
            if (
                includes(wdKey, displayColumnIndicator) &&
                !isUndefined(wdValue)
            ) {
                hasDisplayValue = true;
                if (wdValue) {
                    const columnTitle: string = get(
                        notificationGroupingOptions,
                        `${fieldName}.label`,
                        ''
                    ) as string;
                    notificationColumns.push({
                        title: columnTitle,
                        dataIndex: columnTitle,
                    });
                }
            }
            if (includes(wdKey, customFieldIndicator)) {
                const customFieldType = get(
                    wdKey.replace(customFieldIndicator, '').split('--'),
                    0
                );

                if (includes(customFieldsUsed, customFieldType)) {
                    customFieldFilters[wdKey + appliedFilterIndicator] =
                        wdValue;
                }
            }

            else if (includes(wdKey, 'Customer')) {
                customerFieldValue = wdValue;
            }
        });

        let startDate: any = clone(defaultStartDate);
        let endDate: any = clone(defaultEndDate);
        const dateRange = get(widgetDetails, 'dateRangeSelected');
        if (dateRange === widgetDateRangeValues.CUSTOM_DATE_RANGE) {
            const customStartDate =
                get(widgetDetails, 'customDateRangeStart') || defaultStartDate;
            const customEndDate =
                get(widgetDetails, 'customDateRangeEnd') || defaultEndDate;
            startDate =
                moment(customStartDate).format(dateFormatYYYYMMDDDash) +
                'T00:00:00';
            endDate =
                moment(customEndDate).format(dateFormatYYYYMMDDDash) +
                'T23:59:59';
        } else if (dateRange === widgetDateRangeValues.THIS_CALENDAR_YEAR) {
            startDate = moment().format('YYYY-01-01T00:00:00');
            endDate = moment().format('YYYY-12-31T23:59:59');
        } else if (dateRange === widgetDateRangeValues.LAST_CALENDAR_YEAR) {
            startDate = moment()
                .subtract(1, 'year')
                .format('YYYY-01-01T00:00:00');
            endDate = moment()
                .subtract(1, 'year')
                .format('YYYY-12-31T23:59:59');
        } else if (
            dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU ||
            dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
        ) {
            const thisYearFinancialYearStart = moment().format(
                'YYYY-07-01T00:00:00'
            );
            const thisYearFinancialYearStartEnd = moment()
                .add(1, 'year')
                .format('YYYY-06-30T23:59:59');
            if (dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU) {
                startDate = thisYearFinancialYearStart;
                endDate = thisYearFinancialYearStartEnd;
            } else if (
                dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
            ) {
                startDate = moment(thisYearFinancialYearStart)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T00:00:00`);
                endDate = moment(thisYearFinancialYearStartEnd)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T23:59:59`);
            }
        }

        const IsMonthlyView = getIfIsMonthlyView();

        let payload: DynamicObject = {
            filters: {
                ...customFieldFilters,
            },
            DateMin: startDate,
            DateMax: endDate,
            IsMonthlyView,
            Customer: customerFieldValue
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationWidgets({
                payload,
                widgetDetails,
            });
        }

        dispatch(
            getDashboardNotificationsRequestAction(
                payload,
                isOrgView,
                (notifications: DynamicObject[]) => {
                    if (unmountedRef.current) return;

                    let usedColumns: any = [];
                    if (hasDisplayValue) {
                        usedColumns = notificationColumns;
                    } else {
                        usedColumns = widgetState.columns;
                    }

                    const filterColumns = usedColumns
                        .filter((col: any) => col.title !== 'Date')
                        .map((item: any) => {
                        if (item.title === 'Emails sent') {
                            return { title: 'Email' };
                        } else if (item.title === 'SMS sent') {
                            return { title: 'Sms' };
                        } else if (item.title === 'Postal letters') {
                            return { title: 'Postal' };
                        } else if (item.title === 'Total Notifications'){
                            return { title: 'Total' };
                        }
                      });
                   
                    const filterNotifications = filter(notifications, (item) => {
                        const countType = item.CountType;
                        return filterColumns.some((obj:any) => obj.title === countType)
                      });

                    const usedDataSource = constructChartsArray(
                        startDate,
                        endDate,
                        IsMonthlyView,
                        filterNotifications
                    );

                    updateWidgetStateObject({
                        dataSource: usedDataSource,
                        loading: false,
                        lastWidgetDetails: clone(widgetDetails),
                        columns: usedColumns,
                    });
                },
                payloadCallback
            )
        );
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            widgetState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateWidgetStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }

    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    /**
     * Function for mapping the values based on the response from query for chart
     * @param chartDataList
     */
    const getNotificationsCountDataObject = (chartDataList: any[]) => {
        let notificationsCountDataObject: any = {};
        forEach(notificationGroupingOptions, (ngo: DynamicObject) => {
            const responsePropertyName = get(ngo, 'value');
            const isChecked = get(
                widgetDetails,
                responsePropertyName,
                get(ngo, 'defaultChecked')
            );
            if (isChecked) {
                const filterTypeName = get(ngo, 'label');
                notificationsCountDataObject[filterTypeName] = 0;
                forEach(chartDataList, (chartData) => {
                    if (get(ngo, 'type') === get(chartData, 'CountType')) {
                        const itemCount = get(chartData, 'Count');
                        notificationsCountDataObject[filterTypeName] =
                            itemCount;
                    }
                });
            }
        });

        return notificationsCountDataObject;
    };

    /**
     * Function for constructing the charts data.
     * @param startDate
     * @param endDate
     * @param isMonthlyView
     * @param dataSource
     */
    const constructChartsArray = (
        startDate: string,
        endDate: string,
        isMonthlyView: boolean,
        dataSource: DynamicObject
    ) => {
        const momentStartDate = moment(startDate);
        const momentEndDate = moment(endDate);
        const interim = momentStartDate.clone();
        const chartArray: any = [];
        let indexValue = 0; 
        if (isMonthlyView) {
            while (
                momentEndDate > interim ||
                interim.format('M') === momentEndDate.format('M')
            ) {
                const inerimKeyValue = interim.format('M-YYYY');
                const chartDataList = filter(
                    dataSource,
                    (d) => `${d.DisplayPeriod}-${d.Year}` === inerimKeyValue
                );
                const dataObject: DynamicObject = {
                    key: inerimKeyValue,
                    xDisplay: interim.format('MMM-YYYY'),
                };

                const notificationsDataObject =
                    getNotificationsCountDataObject(chartDataList);
                const chartObject = {
                    ...dataObject,
                    ...notificationsDataObject,
                };

                chartArray.push(chartObject);

                interim.add(1, 'month');
            }
        } else {
            while (
                momentEndDate > interim ||
                interim.format('W') === momentEndDate.format('W')
            ) {
                const inerimKeyValue = interim.format('W-YYYY');
                const chartDataList = filter(
                    dataSource,
                    (d) => `${d.DisplayPeriod}-${d.Year}` === inerimKeyValue
                );
            
                const dataObject = {
                    key: indexValue,
                    xDisplay: interim.format('W-YYYY'),
                };

                const notificationsDataObject =
                    getNotificationsCountDataObject(chartDataList);
                const chartObject = {
                    ...dataObject,
                    ...notificationsDataObject,
                };

                chartArray.push(chartObject);

                interim.add(1, 'week');

                indexValue++;
            }
        }

        return chartArray;
    };

    /**
     * Function for formatting the tooltip.
     * @param value
     */
    const tooltipFormatter: TooltipFormatter = (value: any, name: string) => {
        const formattedValue = isNumber(value) ? formatNumber(value) : value;
        const translatedName = getTranslatedText(name);
        return [formattedValue, translatedName];
    };

    const customContent = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-widget-tooltip">
                    <p className="label">{payload[0].payload.xDisplay}</p>
                    {!isUndefined(payload[0].payload["Emails sent"]) && <p className="label">{getTranslatedText('Email sent')} : {`${formatNumber(payload[0].payload["Emails sent"])}`}</p>}
                    {!isUndefined(payload[0].payload["SMS sent"]) && <p className="label">{getTranslatedText('SMS sent')} : {`${formatNumber(payload[0].payload["SMS sent"])}`}</p>}
                    {!isUndefined(payload[0].payload["Postal letters"]) && <p className="label">{getTranslatedText('Postal letters')} : {`${formatNumber(payload[0].payload["Postal letters"])}`}</p>}
                    {!isUndefined(payload[0].payload["Total Notifications"]) && <p className="label">{getTranslatedText('Total notifications')} : {`${formatNumber(payload[0].payload["Total Notifications"])}`}</p>}
                </div>
            );
        }

        return null;
    };

    /**
     * Function that sets the display options to be used
     */
    const initializeCheckedDisplayOptions = () => {
        let currentSelectedDisplayOptions: DynamicObject = {};
        forEach(notificationGroupingOptions, (ngo: DynamicObject) => {
            const fieldValue = get(ngo, 'value');
            const displayFilter = `${displayColumnIndicator}${fieldValue}`;
            const displayOption = get(widgetDetails, displayFilter);
            
            let isChecked = false;
            if (!isUndefined(displayOption)) {
                isChecked = displayOption;
            } else {
                isChecked = get(ngo, 'defaultChecked');
            }

            if (isChecked) {
                currentSelectedDisplayOptions[fieldValue] = ngo;
            }
        });

        setCheckedDisplayOptions(currentSelectedDisplayOptions);
    };

    useEffect(initializeCheckedDisplayOptions, [widgetDetails]);

    /**
     * Function for getting if the chart grouping option is monthly or not.
     */
    const getIfIsMonthlyView = () => {
        return !isUndefined(get(widgetDetails, 'groupingOptions'))
            ? get(widgetDetails, 'groupingOptions')
            : true;
    };

    /**
     * Function for populating the label whether the chart is set to Monthly or Weekly view.
     */
    const populateChartLabel = () => {
        if (!widgetState.loading) {
            const isMonthlyView = getIfIsMonthlyView();
            const labelText = isMonthlyView ? 'Monthly view' : 'Weekly view';

            return <div className="chart-title">{getTranslatedText(labelText)}</div>;
        }

        return null;
    };

    /**
     * Function for populating the bar items for chart.
     */
    const populateBarItemsForChart = () => {
        return map(checkedDisplayOptions, (cdo: DynamicObject) => (
            <Bar
                key={get(cdo, 'value')}
                dataKey={get(cdo, 'label')}
                fill={get(cdo, 'color')}
            />
        ));
    };

    /**
     * Function for populating the line items for chart.
     */
    const populateLineItemsForChart = () => {
        return map(checkedDisplayOptions, (cdo: DynamicObject) => (
            <Line
                key={get(cdo, 'value')}
                dataKey={get(cdo, 'label')}
                stroke={get(cdo, 'color')}
                type="monotone"
            />
        ));
    };

    const COLORS = [
        '#0088FE',
        '#00C49F',
        '#FFBB28',
        '#FF8042',
        '#F44336',
        '#9C27B0',
        '#FFEB3B',
        '#795548',
        '#8BC34A',
        '#263238',
    ];

    /**
     * Function for rendering the labels for pie chart.
     * @param props
     */
    const renderCustomizedLabel = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            midAngle,
            outerRadius,
            xDisplay,
            percent,
            innerRadius,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const textAnchor = cos >= 0 ? 'start' : 'end';
        const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
        const x = cx + radius * cos;
        const y = cy + radius * sin;
        return (
            <g>
                <text
                    x={x}
                    y={y}
                    textAnchor={textAnchor}
                    fill="#333"
                    dominantBaseline="central"
                >
                    {/*{getTranslatedText(xDisplay)}*/}
                    {xDisplay}
                </text>
                <text x={x} y={y} dy={18} textAnchor={textAnchor} fill="#999">
                    {`${(percent * 100).toFixed(2)}%`}
                </text>
            </g>
        );
    };

    const populateWidgetContent = () => {
        const displayView = get(widgetDetails, 'displayType');

        if(displayView === widgetDisplayTypeValues.TABLE){
            widgetState.columns.sort((a, b) => a.title.localeCompare(b.title));

            return (
                <Table
                    rowClassName={(record) => {
                        return get(record, 'xDisplay') === 'Grand total'
                            ? 'table-total-row'
                            : '';
                    }}
                    className="table-striped-rows table-ws-nw"
                    columns={widgetState.columns.map(column => ({
                        ...column,
                        title: getTranslatedText(column.title)
                    }))}
                    dataSource={tableNumberFormatter(widgetState.dataSource, formatNumber)}
                    loading={widgetState.loading}
                    pagination={false}
                    size="middle"
                    rowKey="key"
                    locale={{
                        emptyText: getTranslatedText('No Data'),
                    }}
                />
            );
        } else if (displayView === widgetDisplayTypeValues.PIE_CHART) {
            const pieChartData = clone(widgetState.dataSource);
            if (!isEmpty(widgetState.dataSource)) {
                pieChartData.pop();
            }

            const filteredData = (widgetState.dataSource).filter((dataPoint: any) => dataPoint["Total Notifications"] > 0);

            return (
                <Spin wrapperClassName="spinner-wh100" spinning={widgetState.loading}>
                    <ResponsiveContainer width="99%" height="99%">
                        <PieChart>
                            <Pie
                                paddingAngle={1}
                                minAngle={1}
                                data={filteredData}
                                label={renderCustomizedLabel}
                                labelLine={true}
                                outerRadius="70%"
                                fill="#8884d8"
                                dataKey="Total Notifications"
                                nameKey="xDisplay"
                                isAnimationActive={false}
                            >
                                {map(filteredData, (_entry, index) => (
                                    <Cell
                                        key={index}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                            <Tooltip formatter={tooltipFormatter} content={customContent}/>
                            {preview && <Legend formatter={(value) => getTranslatedText(value)} />}
                        </PieChart>
                    </ResponsiveContainer>
                </Spin>
            );
            } else if (displayView === widgetDisplayTypeValues.BAR_CHART || displayView === undefined){
                return (
                    <Spin
                        wrapperClassName="spinner-wh100"
                        spinning={widgetState.loading}
                    >
                        {populateChartLabel()}

                        <ResponsiveContainer width="99%" height="99%">
                            <BarChart
                                data={widgetState.dataSource}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 15,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="xDisplay" />
                                <YAxis />
                                <Tooltip formatter={tooltipFormatter} />
                                {preview && <Legend formatter={(value) => getTranslatedText(value)} />}
                                {populateBarItemsForChart()}
                            </BarChart>
                        </ResponsiveContainer>
                    </Spin>
            );
        } else {
            return (
                <Spin
                    wrapperClassName="spinner-wh100"
                    spinning={widgetState.loading}
                >
                    {populateChartLabel()}

                    <ResponsiveContainer width="99%" height="99%">
                        <LineChart
                            data={widgetState.dataSource}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 15,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="xDisplay" />
                            <YAxis />
                            <Tooltip formatter={tooltipFormatter} />
                            {preview && <Legend formatter={(value) => getTranslatedText(value)} />}
                            {populateLineItemsForChart()}
                        </LineChart>
                    </ResponsiveContainer>
                </Spin>
        );
        }
    };

    return (
        <div className="notificationss-widget-container h-100">
           {populateWidgetContent()}
        </div>
    );
};

export default withNumberFormatHandler(NotificationsWidget);
