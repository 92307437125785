import API, { graphqlOperation } from '@aws-amplify/api';
import Modal from 'antd/lib/modal/Modal';
import update from 'immutability-helper';
import { filter, find, get, isEmpty } from 'lodash';
import { Action } from 'redux';
import { channel } from 'redux-saga';
import {
    all,
    call,
    fork,
    put,
    select,
    take,
    takeLatest,
} from 'redux-saga/effects';
import { useSelector } from 'react-redux';
import { ApplicationState } from '..';
import {
    API_NAME,
    initialPrimaryColor,
    initialSecondaryColor,
} from '../../config/config';
import {
    CompanyIdAttribute,
    IsOrganisationViewAttribute,
    RoleIdAttribute,
} from '../../constants/authUserAttributes';
import { DEFAULT_NUMBER_OF_DAYS_FOR_RECENT_COMMENT } from '../../constants/common';
import queries from '../../graphql/queries.graphql';
import {
    refreshCognitoAttributes,
    updateLessVariables,
    getTranslatedText
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { changeEndpointsAction, loginUserAction } from '../auth/actions';
import { setLocaleAction } from '../common/actions';
import { clearCreditsStateAllTableFiltersAction } from '../credits/actions';
import { clearCustomersStateAllTableFiltersAction } from '../customers/actions';
import { clearInvoicesStateAllTableFiltersAction } from '../invoices/actions';
import { clearPaymentsStateAllTableFiltersAction } from '../payments/actions';
import {
    clearTaskHistoryStateAllTableFiltersAction,
    clearTasksStateAllTableFiltersAction,
} from '../tasks/actions';
import { clearTicketsStateAllTableFiltersAction } from '../tickets/actions';
import { clearUsersStateAllTableFiltersAction } from '../users/actions';
import { getCurrentUser } from '../users/sagas';
import { DEFAULT_LOCALE, DEFAULT_REGION_NAME } from './../../config/config';
import {
    getCompanyWorkflowOptionsSuccessAction,
    getSystemConfigurationSuccessAction,
    getUserCompaniesSuccessAction,
    resetAllCompanyDataTableFiltersAction,
    saveUserCompanyResponseAction,
    selectUserCompanyRequestAction,
    selectUserCompanySuccessAction
} from './actions';
import { CompaniesActionTypes, CompanyUserRole, WorkflowOption } from './types';
import { I18n } from 'aws-amplify';
import I18nWrapper from '../../utils/I18nWrapper'; // Adjust the import path accordingly

export const getUsingCustomerWorflow = (state: ApplicationState) =>
    get(state.companies.selectedUserCompany, 'Company.UsingCustomerWorkflow');

export const getSupportSendNewInvoice = (state: ApplicationState) =>
    get(state.companies.selectedUserCompany, 'Company.SupportSendNewInvoice');

export const getPaymentPlanEnabled = (state: ApplicationState) =>
    get(state.companies.selectedUserCompany, 'Company.CompanyPaymentPlan.IsEnabled');

export const getNumberOfDaysBeforeRecentCommentTurnedOff = (state: ApplicationState) =>
    get(state.companies.selectedUserCompany, 'Company.NumberOfDaysBeforeRecentCommentTurnedOff') ||
    DEFAULT_NUMBER_OF_DAYS_FOR_RECENT_COMMENT;

export const getWorkflowOptions = (state: ApplicationState) => state.companies.workflowOptions;
export const getFullWorkflowOptions = (state: ApplicationState) => state.companies.fullWorkflowOptions;

/**
 * Function responsible for getting the companies the user belongs to from API.
 */
function* handleGetUserCompaniesRequest() {
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_COMPANY_FOR_USER)
        );

        const systemConfigurationData = get(
            res.data,
            'GetCompanyForUser.SystemConfiguration'
        );

        yield put(getSystemConfigurationSuccessAction(systemConfigurationData));

        const data = get(res.data, 'GetCompanyForUser.CompanyUserRoles');
        const companiesData = filter(
            data,
            (companyUserRole: CompanyUserRole) => {
                return !isEmpty(companyUserRole.Company);
            }
        );
        yield put(getUserCompaniesSuccessAction(companiesData));
        const currentUser: DynamicObject = yield select(getCurrentUser);
        const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
        if (!isEmpty(companiesData) && !isOrgView) {
            const selectedCompanyId = get(currentUser, CompanyIdAttribute);

            if (!selectedCompanyId) {
                yield put(selectUserCompanyRequestAction(companiesData[0]));
            } else {
                const currentCompanyData = find(
                    companiesData,
                    (userCompanyData: CompanyUserRole) =>
                        selectedCompanyId === userCompanyData.Company.CompanyId
                );

                const selectedCompanyData =
                    currentCompanyData || companiesData[0];
                yield put(selectUserCompanyRequestAction(selectedCompanyData));
            }
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
        yield put(getUserCompaniesSuccessAction([]));
    }
}

/**
 * Function responsible for triggering the user company selected
 * and also handles company switching to sync the company selected here in UI
 * and the one stored in cognito.
 */
const userChannel = channel();
const selectCompanyChannel = channel();
function* handleSelectUserCompanyRequest({ payload: sagaPayload }: any) {
    const { companyUserRoleObject, callback } = sagaPayload;
    try {
        const companyRegion = get(companyUserRoleObject, 'Company.Region');
        let currentCompanyIdUsed: any;
        if (companyRegion) {
            const selectedUserCompany: DynamicObject = yield select(
                (state: ApplicationState) => state.companies.selectedUserCompany
            );

            if (
                (!selectedUserCompany &&
                    companyRegion !== DEFAULT_REGION_NAME) ||
                companyRegion !== get(selectedUserCompany, 'Company.Region')
            ) {
                const currentUser: DynamicObject = yield select(getCurrentUser);
                currentCompanyIdUsed = get(currentUser, CompanyIdAttribute);
                if (currentCompanyIdUsed) {
                    yield put(changeEndpointsAction(companyUserRoleObject));
                }
            }

            const companyLocale = get(
                companyUserRoleObject,
                'Company.LanguagePackage.Language',
                DEFAULT_LOCALE
            );
            if (companyLocale) {
                const languagePack: DynamicObject = yield select(
                    (state: ApplicationState) =>
                        get(
                            state.companies.selectedUserCompany,
                            'Company.LanguagePackage'
                        )
                );
                const currentLocale = get(languagePack, 'Language');
                if (currentLocale !== companyLocale) {
                    yield put(setLocaleAction(companyLocale));
                }
            }
        }
        const companyId = companyUserRoleObject.Company.CompanyId;
        const roleId = get(companyUserRoleObject, 'Role.RoleId');
        const currentUser: DynamicObject = yield select(getCurrentUser);
        const isOrgView = get(currentUser, IsOrganisationViewAttribute) === '1';
        const currentCompanyId = get(currentUser, CompanyIdAttribute);
        const currentRoleId = get(currentUser, RoleIdAttribute);
        if (
            isOrgView ||
            companyId !== currentCompanyId ||
            roleId !== currentRoleId
        ) {
            yield call([API, 'post'], API_NAME, '/company/switch', {
                body: {
                    CompanyId: companyId,
                },
            });

            yield call(
                refreshCognitoAttributes,
                async (_err: any, session: any) => {
                    const companyIdAttributeName = CompanyIdAttribute.replace(
                        'signInUserSession.',
                        ''
                    );

                    if (get(session, companyIdAttributeName)) {
                        const newUserData = update(currentUser, {
                            $merge: {
                                signInUserSession: session,
                            },
                        });

                        await userChannel.put(
                            loginUserAction({
                                currentUser: newUserData,
                                isAuth: true,
                            })
                        );

                        await selectCompanyChannel.put(
                            selectUserCompanySuccessAction(
                                companyUserRoleObject
                            )
                        );

                        if (!currentCompanyIdUsed) {
                            await userChannel.put(
                                changeEndpointsAction(companyUserRoleObject)
                            );
                        }
                    }
                }
            );

            yield put(resetAllCompanyDataTableFiltersAction());
        } else {
            yield put(selectUserCompanySuccessAction(companyUserRoleObject));
        }
        if (callback) callback();
        // calls for queries if needed        
    } catch (err) {
        Modal.error({
            title: getTranslatedText("Error"),
            content: getTranslatedText("Failed to switch companies"),
            onOk: () => {
                window.location.reload();
            },
            okText: getTranslatedText("OK")
        });
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called after all the functions called when switching the company has finished.
 * Responsible for syncing the Theme for the company.
 */
// eslint-disable-next-line
function* handleSelectUserCompanySuccess({
    payload: companyUserRoleObject,
}: any) {
    try {
        const companyObject = get(companyUserRoleObject, 'Company');
        const primaryColorTheme =
            get(companyObject, 'PrimaryColorTheme') || initialPrimaryColor;
        const secondaryColorTheme =
            get(companyObject, 'SecondaryColorTheme') || initialSecondaryColor;

        updateLessVariables({
            '@custom-primary-color': primaryColorTheme,
            '@custom-secondary-color': secondaryColorTheme,
        });

        return false;
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

/**
 * Function called for saving the company details - talking to the API.
 */
function* handleSaveUserCompanyRequest({ payload: sagaPayload }: any) {
    const { payload, callback } = sagaPayload;
    try {
        yield call([API, 'post'], API_NAME, '/company/save', {
            body: payload,
        });
        
        yield put(saveUserCompanyResponseAction());
        if (callback) {
            const response = {
                IsSuccess: true,
            };
            
            callback(response);
        }
        // calls for queries if needed
    } catch (err) {
        yield put(saveUserCompanyResponseAction());

        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
        //if (callback) {
        //    const returnData = get(err.response, 'data')
        //        ? err.response.data
        //        : { Messages: [err.message] };
        //    returnData.IsSuccess = false;
        //    callback(returnData);
        //}
        //if (err instanceof Error) {
        //    console.log('Error', err);
        //} else {
        //    console.error('An unknown error occured.');
        //}
    }
}

/**
 * Function called for saving the company logo.
 */
function* handleUploadCompanyLogoRequest({ payload: sagaPayload }: any) {
    const { fileToUpload, callback } = sagaPayload;
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/company/upload/logo',
            {
                body: fileToUpload.preview,
            }
        );

        res.IsSuccess = true;

        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
        //if (callback) {
        //    const returnData = get(err.response, 'data')
        //        ? err.response.data
        //        : { Messages: [err.message] };
        //    returnData.IsSuccess = false;
        //    callback(returnData);
        //}
        //if (err instanceof Error) {
        //    console.log('Error', err);
        //} else {
        //    console.error('An unknown error occured.');
        //}
    }
}

/**
 * Function responsible for getting the company workflow names.
 */
function* handleGetCompanyWorkflowOptionsRequest() {
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_WORKFLOW_NAMES_FOR_COMPANY, {
                IncludePaymentPlanWorkflows: true,
                IncludePaymentPlanApprovalWorkflows: true,
                IncludeNewInvoiceWorkflows: true
            })
        );

        const workflowOptions: WorkflowOption[] = get(
            res,
            'data.GetWorkflowNamesForCompany'
        );
        
        yield put(getCompanyWorkflowOptionsSuccessAction(workflowOptions));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }
        yield put(getCompanyWorkflowOptionsSuccessAction([])); // Empty if error
    }
}

const setLanguageAndVocabularies = async (language: string) => {
    const languageJson = await import(`../../languages/${language}.json`);
    I18nWrapper.putVocabulariesForLanguage(language, languageJson.default[language]);
    I18nWrapper.setLanguage(language);

    //I18n.putVocabulariesForLanguage(language, languageJson);
};

/**
 * Function responsible for selected company.
 */
function* handleSelectedUserCompany() {
    try {        
        const selectedUserCompany: CompanyUserRole | undefined = yield select(
            (state: ApplicationState) => state.companies.selectedUserCompany
        );

        let language: string = 'en-AU';

        if (selectedUserCompany && selectedUserCompany.Company.LanguageCode) {
            language = selectedUserCompany.Company.LanguageCode;
        }

        I18n.setLanguage(language);
        localStorage.setItem('language', language);
        //const languageJson: DynamicObject = yield import(`../../languages/${language}.json`);
        
        // Update the vocabularies for I18n with the loaded JSON
        //I18n.putVocabulariesForLanguage(language, languageJson);
        //I18n.putVocabularies(languageJson);
        setLanguageAndVocabularies(language);
    } catch (error) {
        console.error("Error loading language:", error);
    }
}

/**
 * Function called for resetting all company table data filters and sort values to initial state..
 */
function* handleResetAllCompanyDataTableFilters() {
    yield put(clearTasksStateAllTableFiltersAction());
    yield put(clearTicketsStateAllTableFiltersAction());
    yield put(clearTaskHistoryStateAllTableFiltersAction());
    yield put(clearCustomersStateAllTableFiltersAction());
    yield put(clearInvoicesStateAllTableFiltersAction());
    yield put(clearCreditsStateAllTableFiltersAction());
    yield put(clearPaymentsStateAllTableFiltersAction());
    yield put(clearUsersStateAllTableFiltersAction());
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchGetUserCompaniesRequest() {
    yield takeLatest(
        CompaniesActionTypes.GET_USER_COMPANIES_REQUEST,
        handleGetUserCompaniesRequest
    );
}

function* watchSelectUserCompanyRequest() {
    yield takeLatest(
        CompaniesActionTypes.SELECT_USER_COMPANY_REQUEST,
        handleSelectUserCompanyRequest
    );
}

function* watchSelectUserCompanySuccess() {
    yield takeLatest(
        CompaniesActionTypes.SELECT_USER_COMPANY_SUCCESS,
        handleSelectUserCompanySuccess
    );
}

function* watchSaveUserCompanyRequest() {
    yield takeLatest(
        CompaniesActionTypes.SAVE_USER_COMPANY_REQUEST,
        handleSaveUserCompanyRequest
    );
}

function* watchUserChannel() {
    while (true) {
        const action: Action<any> = yield take(userChannel);
        yield put(action);
    }
}

function* watchSelectCompanyChannel() {
    while (true) {
        const action: Action<any> = yield take(selectCompanyChannel);
        yield put(action);
    }
}

function* watchUploadCompanyLogoRequest() {
    yield takeLatest(
        CompaniesActionTypes.UPLOAD_COMPANY_LOGO_REQUEST,
        handleUploadCompanyLogoRequest
    );
}

function* watchResetAllCompanyDataTableFilters() {
    yield takeLatest(
        CompaniesActionTypes.RESET_ALL_COMPANY_DATA_TABLE_FILTERS,
        handleResetAllCompanyDataTableFilters
    );
}

function* watchGetCompanyWorkflowOptionsRequest() {
    yield takeLatest(
        CompaniesActionTypes.GET_COMPANY_WORKFLOW_OPTIONS_REQUEST,
        handleGetCompanyWorkflowOptionsRequest
    );
}

function* watchSelectUserCompanySuccessAction() {
    yield takeLatest(
        CompaniesActionTypes.SELECT_USER_COMPANY_SUCCESS,
        handleSelectedUserCompany
    );
}
// We can also use `fork()` here to split our saga into multiple watchers.
function* companiesSaga() {
    yield all([
        fork(watchGetUserCompaniesRequest),
        fork(watchSelectUserCompanyRequest),
        fork(watchSelectUserCompanySuccess),
        fork(watchUserChannel),
        fork(watchSelectCompanyChannel),
        fork(watchSaveUserCompanyRequest),
        fork(watchUploadCompanyLogoRequest),
        fork(watchResetAllCompanyDataTableFilters),
        fork(watchGetCompanyWorkflowOptionsRequest),
        fork(watchSelectUserCompanySuccessAction),
    ]);
}

export default companiesSaga;
