import {
    invoicesAmountOperatorOptions,
    invoicesCreatedInOptions,
    invoicesDueInOptions,
    invoicesAmountType,
    invoicesSortValues,
} from './invoicesSortAndFilters';
import moment from 'moment-timezone';
import {
    creditsCreatedSinceOptions,
    creditsAvailableSinceOptions,
    creditsAmountType,
    creditTypeStateOptions,
    creditCreatedTypeStateOptions,
    creditRecurringStateOptions,
} from './creditsSortAndFilters';
import {
    paymentResultOptions,
    paymentsAmountType,
    paymentsPaidDateOptions,
} from './paymentsSortAndFilters';
import { checkIfEmailIsValid } from '../utils/commonFunctions';
import { selectUserFilterMapping } from './common';

export const preConfiguredFilters = {
    ALL_CUSTOMERS: 'All customers',
};

export const customersSortByOptionsMap = {
    customerCode: { label: 'Customer code', value: 'Customer code' },
    customerName: { label: 'Customer name', value: 'Company name' }
};
export const customersSortByOptions = (isDueDateView: boolean, isCalendarView: boolean) => {
    const daysView: any[] = [
        { label: '30 days', value: 'Period1' },
        { label: '60 days', value: 'Period2' },
        { label: '90+ days', value: 'Period3Plus' }
    ];
    const monthsView: any[] = [
        { label: '1 month', value: 'Period1' },
        { label: '2 months', value: 'Period2' },
        { label: '3+ months', value: 'Period3Plus' }
    ];
    const finalView = isCalendarView ? [...monthsView] : [...daysView];

    return [
        customersSortByOptionsMap.customerCode,
        customersSortByOptionsMap.customerName,
        { label: 'Total overdue', value: 'Total overdue' },
        { label: 'Total owing', value: 'Total owing' },
        isDueDateView ? { label: 'Not due', value: 'Not owing' } : undefined,
        { label: 'Current', value: 'Current' },
        ...finalView
    ].filter(o => !!o);
};

export const customersATBCalculationOptions = [
    { label: 'from create date', value: 1 },
    { label: 'from due date', value: 2 },
];

export const customersAmountATBValues = (isDueDateView: boolean, isCalendarView: boolean) => {
    const daysView: any[] = [
        { label: '30 days', value: 'Period1' },
        { label: '60 days', value: 'Period2' },
        { label: '90+ days', value: 'Period3Plus' }
    ];
    const monthsView: any[] = [
        { label: '1 month', value: 'Period1' },
        { label: '2 months', value: 'Period2' },
        { label: '3+ months', value: 'Period3Plus' }
    ];
    const finalView = isCalendarView ? [...monthsView] : [...daysView];

    return [
        { label: 'Total owing', value: 'Total owing' },
        { label: 'Total overdue', value: 'Total overdue' },
        isDueDateView ? { label: 'Not due', value: 'Not owing' } : undefined,
        { label: 'Current', value: 'Current' },
        ...finalView
    ].filter(o => !!o);
};

export const contactSelectType = [
    { label: 'That have either', value: 'true' },
    { label: 'Are missing all', value: 'false' },
];

export const customersVipStateOptions = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
];

export const getCustomersFilterBarFilters = (atbType: number, customerValuesOption?: any, customerLabel?: string, isCalendarView?: boolean) => {
    const filters = [
        {
            filterName: 'Customer',
            filterStateName: 'Customer',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Company name',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'DisplayName',
        },
        {
            filterName: 'Contact',
            filterStateName: 'Contact',
            filterElement: 'select-checkbox-group',
            filterType: 'sum',
            filterOptions: {
                select: {
                    name: 'InclusiveContact',
                    options: contactSelectType,
                },
                checkboxGroup: {
                    name: 'Contact',
                    options: [
                        { label: 'Email', value: 1 },
                        { label: 'Mobile', value: 2 },
                        { label: 'Postal address', value: 4 },
                        // { label: 'Landline', value: 8 },
                    ],
                },
            },
        },
        {
            filterName: 'Amount',
            filterStateName: 'Amount',
            filterElement: 'select-multiple-and-input-amount',
            filterType: 'text',
            filterOptions: [
                {
                    AmountType: customersAmountATBValues(atbType === customersATBCalculationOptions[1].value, !!isCalendarView)
                },
                {
                    AmountOperator: invoicesAmountOperatorOptions,
                },
            ],
        },
        {
            filterName: 'VIP',
            filterStateName: 'IsVip',
            filterElement: 'radio-group',
            filterType: 'text',
            filterPlaceholder: 'Select VIP status',
            filterOptions: customersVipStateOptions,
        }
        ,
        {
            filterName: 'Country',
            filterStateName: 'CustomerCountry',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Country',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'Country',
        }
        ,
        {
            filterName: 'Postal State',
            filterStateName: 'CustomerState',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'State',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'State',
        }
        // {
        //     filterName: 'Customer status',
        //     filterStateName: 'CustomerStatus',
        //     filterElement: 'checkbox-group',
        //     filterType: 'array',
        //     filterOptions: [
        //         {
        //             label: 'VIP customer',
        //             value: 'VIP customer',
        //         },
        //         {
        //             label: 'Has queried invoices',
        //             value: 'Has queried invoices',
        //         },
        //     ],
        // },
        // {
        //     filterName: 'Credit status',
        //     filterStateName: 'CreditStatus',
        //     filterElement: 'input',
        //     filterType: 'text',
        // },
        // {
        //     filterName: 'Sales rep',
        //     filterStateName: 'SalesRep',
        //     filterElement: 'input',
        //     filterType: 'text',
        // },
    ];

    if (customerValuesOption && customerLabel) {
        filters.splice(1, 0, {
            filterName: customerLabel,
            filterStateName: 'CustomerFirstLetter',
            filterElement: 'checkbox-group',
            filterType: 'array',
            filterOptions: customerValuesOption
        });
    }

    return filters;
};

export const customerInvoicesFilterBarFilters = (isCalendarView: boolean) => [
    {
        filterName: 'Invoice number',
        filterStateName: 'InvoiceNumber',
        filterElement: 'input-autocomplete',
        filterQuery: 'GET_INVOICES_FOR_COMPANY_AUTOCOMPLETE_FILTER',
        filterSort: invoicesSortValues.INVOICE_NUMBER,
        filterResponse: 'GetInvoicesForCompany.Invoices',
        filterLabelField: 'Number',
    },
    {
        filterName: 'Created in',
        filterStateName: 'CreatedDate',
        filterElement: 'select',
        filterType: 'text',
        filterOptions: invoicesCreatedInOptions(isCalendarView),
        filterOptionsCustomTypes: {
            'Custom date range': 'date-range',
            'Custom days range': 'last-days-range',
        },
    },
    {
        filterName: 'Due in',
        filterStateName: 'DueDate',
        filterElement: 'select',
        filterType: 'text',
        filterOptions: invoicesDueInOptions(isCalendarView),
        filterOptionsCustomTypes: {
            'Custom date range': 'date-range',
            'Custom days range': 'all-days-range',
        },
    },
    {
        filterName: 'Amount',
        filterStateName: 'Amount',
        filterElement: 'select-multiple-and-input-amount',
        filterType: 'text',
        filterOptions: [
            {
                AmountType: invoicesAmountType,
            },
            {
                AmountOperator: invoicesAmountOperatorOptions,
            },
        ],
    },
];

export const customerCreditsFilterBarFilters = (isCalendarView: boolean) => [
    {
        filterName: 'Credit number',
        filterStateName: 'CreditNumber',
        filterElement: 'input-autocomplete',
        filterQuery: 'GET_CREDITS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
        filterSort: 'Credit number',
        filterResponse: 'GetCreditsForCompany.Credits',
        filterLabelField: 'Number',
    },
    {
        filterName: 'Created since',
        filterStateName: 'CreatedDate',
        filterElement: 'select',
        filterType: 'text',
        filterMaxDate: moment(),
        filterOptions: creditsCreatedSinceOptions(isCalendarView),
        filterOptionsCustomTypes: {
            'Custom date range': 'date-range',
            'Custom days range': 'all-days-range-from-to-last',
        },
        // offset: 1,
        // span: 5,
    },
    {
        filterName: 'Available since',
        filterStateName: 'AvailableDate',
        filterElement: 'select',
        filterType: 'text',
        filterOptions: creditsAvailableSinceOptions(isCalendarView),
        filterOptionsCustomTypes: {
            'Custom date range': 'date-range',
            'Custom days range': 'all-days-range-from-to',
        },
        // span: 5,
    },
    {
        filterName: 'Amount',
        filterStateName: 'Amount',
        filterElement: 'select-multiple-and-input-amount',
        filterType: 'text',
        filterOptions: [
            {
                AmountType: creditsAmountType,
            },
            {
                AmountOperator: invoicesAmountOperatorOptions,
            },
        ],
    },
    {
        filterName: 'Type',
        filterStateName: 'Type',
        filterElement: 'radio-group',
        filterType: 'text',
        filterPlaceholder: 'Select type status',
        filterOptions: creditTypeStateOptions,
    },
    {
        filterName: 'Created Type',
        filterStateName: 'CreatedType',
        filterElement: 'radio-group',
        filterType: 'text',
        filterPlaceholder: 'Select created type status',
        filterOptions: creditCreatedTypeStateOptions,
    },
    {
        filterName: 'Recurring',
        filterStateName: 'Recurring',
        filterElement: 'radio-group',
        filterType: 'text',
        filterPlaceholder: 'Select recurring status',
        filterOptions: creditRecurringStateOptions,
    }
];

export const customerRebatesFilterBarFilters = [
    {
        filterName: 'Rebate number',
        filterStateName: 'CreditNumber',
        filterElement: 'input-autocomplete',
        filterQuery: 'GET_CREDITS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
        filterSort: 'Credit number',
        filterResponse: 'GetCreditsForCompany.Credits',
        filterLabelField: 'Number',
    },
    {
        filterName: 'Created since',
        filterStateName: 'CreatedDate',
        filterElement: 'select',
        filterType: 'text',
        filterMaxDate: moment(),
        filterOptions: creditsCreatedSinceOptions,
        filterOptionsCustomTypes: {
            'Custom date range': 'date-range',
            'Custom days range': 'all-days-range-from-to-last',
        },
        // offset: 1,
        // span: 5,
    },
    {
        filterName: 'Available since',
        filterStateName: 'AvailableDate',
        filterElement: 'select',
        filterType: 'text',
        filterOptions: creditsAvailableSinceOptions,
        filterOptionsCustomTypes: {
            'Custom date range': 'date-range',
            'Custom days range': 'all-days-range-from-to',
        },
        // span: 5,
    },
    {
        filterName: 'Amount',
        filterStateName: 'Amount',
        filterElement: 'select-multiple-and-input-amount',
        filterType: 'text',
        filterOptions: [
            {
                AmountType: creditsAmountType,
            },
            {
                AmountOperator: invoicesAmountOperatorOptions,
            },
        ],
    },
    {
        filterName: 'Created Type',
        filterStateName: 'CreatedType',
        filterElement: 'radio-group',
        filterType: 'text',
        filterPlaceholder: 'Select created type status',
        filterOptions: creditCreatedTypeStateOptions,
    },
    {
        filterName: 'Recurring',
        filterStateName: 'Recurring',
        filterElement: 'radio-group',
        filterType: 'text',
        filterPlaceholder: 'Select recurring status',
        filterOptions: creditRecurringStateOptions,
    }
];

export const customerPaymentsFilterBarFilters = [
    {
        filterName: 'Result',
        filterStateName: 'Result',
        filterElement: 'checkbox-group',
        filterType: 'array',
        filterOptions: paymentResultOptions,
    },
    {
        filterName: 'Paid in',
        filterStateName: 'PaidDate',
        filterElement: 'select',
        filterType: 'text',
        filterMaxDate: moment(),
        filterOptions: paymentsPaidDateOptions,
        filterOptionsCustomTypes: {
            'Custom date range': 'date-range',
            'Custom days range': 'all-days-range-from-to-last',
        },
        // offset: 1,
        // span: 5,
    },
    {
        filterName: 'Amount',
        filterStateName: 'Amount',
        filterElement: 'select-multiple-and-input-amount',
        filterType: 'text',
        filterOptions: [
            {
                AmountType: paymentsAmountType,
            },
            {
                AmountOperator: invoicesAmountOperatorOptions,
            },
        ],
    },
];

export const ATBStateOptions = [
    {
        label: 'All open invoices',
        value: 'AllOpenInvoices',
    },
    {
        label: 'Overdue open invoices',
        value: 'OverdueOpenInvoices',
    },
];

export const amountTypeOptions = [
    {
        label: 'Amount Owing',
        value: 'AllOpenInvoices',
    },
    {
        label: 'Amount Overdue',
        value: 'OverdueOpenInvoices',
    },
];

export const CreditStateOptions = [
    {
        label: 'All open credits',
        value: 'AllOpenCredits',
    },
    {
        label: 'Available open credits',
        value: 'AvailableOpenCredits',
    },
];

export const getCustomersWidgetFilters = (atbType: number, isCalendarView: boolean) => {
    return [
        {
            filterName: 'Customer',
            filterStateName: 'Customer',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Company name',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'DisplayName',
        },
        {
            filterName: 'VIP',
            filterStateName: 'IsVip',
            filterElement: 'radio-group',
            filterType: 'text',
            filterPlaceholder: 'Select VIP Status',
            filterOptions: customersVipStateOptions,
        },
        {
            filterName: 'Amount',
            filterStateName: 'Amount',
            filterElement: 'select-multiple-and-input-amount',
            filterType: 'text',
            filterOptions: [
                {
                    amountType: customersAmountATBValues(atbType === customersATBCalculationOptions[1].value, !!isCalendarView),
                    filterOptionName: 'AmountType',
                    filterPlaceholder: 'Select Amount Type',
                },
                {
                    amountOperator: invoicesAmountOperatorOptions,
                    filterOptionName: 'AmountOperator',
                    filterPlaceholder: 'Select Operator',
                },
            ],
        },
        {
            filterName: 'Country',
            filterStateName: 'CustomerCountry',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Country',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'Country',
        },
        {
            filterName: 'Postal State',
            filterStateName: 'CustomerState',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'State',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'State',
        }
    ];
};
export const multipleCustomersFilterBarFilters = [
    {
        filterName: 'Customer',
        filterStateName: 'Customer',
        filterElement: 'input-autocomplete',
        filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
        filterSort: 'Company name',
        filterResponse: 'GetCustomersForCompany.Customers',
        filterLabelField: 'DisplayName',
    },
];

export const multipleCustomersSortByOptions = [
    { label: 'Customer code', value: 'Customer code' },
    { label: 'Company name', value: 'Company name' },

];